import React from "react";

export default function NumberInput(props) {
    return (
        <div className={props.containerClassName}>
            <label
                htmlFor={props.name}
                className="block text-sm leading-5 font-medium text-gray-700"
            >
                {props.label}
            </label>
            <div className="mt-1">
                <input
                    type="number"
                    min={props.min}
                    max={props.max}
                    name={props.name}
                    id={props.name}
                    defaultValue={props.defaultValue}
                    onChange={(event) =>
                        props.idx != null
                            ? props.handleChange(
                                props.name,
                                event.target.value,
                                props.idx
                            )
                            : props.handleChange(props.name, event.target.value)
                    }
                    className={
                        props.className
                            ? props.className
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    }
                    placeholder={props.placeholder}
                />
            </div>
        </div>
    );
}