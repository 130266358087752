import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import PasswordInput from "../../components/PasswordInput"
import api from "../../api";

export default function NewUserPage() {

    const roles = [
        { id: 0, title: 'Administrador SIT' },
        { id: 1, title: 'Doctor' },
        { id: 2, title: 'VIGIDIT' },
        { id: 3, title: 'Superadministrador' }
    ]
    const { idUser } = useParams();
    const pages = [
        { name: 'Usuarios', href: '/users', current: false },
        { name: `${idUser ? 'Editar usuario' : 'Nuevo usuario'}`, href: `#`, current: true },
    ]
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        isStaff: false,
        profile: {
            govId: "",
            role: 0,
            phone: "",
        },
    })
    const [prevGovId, setPrevGovId] = useState('');
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        govId: '',
        phone: '',
    })
    const [loading, setLoading] = useState(idUser ? true : false)
    const navigate = useNavigate();
    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));
    const handleProfileChange = (field, value) => setUser((prev) => ({ ...prev, profile: { ...prev.profile, [field]: value } }))
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        if (idUser) {
            console.log(prevGovId)
            if (prevGovId === user.profile.govId) {

                delete user.profile.govId
            }
            api.users.update(idUser, user).then((response) => {
                setLoading(false)
                navigate('/users');
            })
                .catch(response => {
                    for (var element in response.message) {
                        if (Object.prototype.hasOwnProperty.call(response.message, element)) {
                            if (response.message[element] != 'Este campo no puede estar en blanco.')
                                handleError(element, response.message[element])
                        }
                    }
                    setLoading(false);
                }
                )
        } else {
            api.users.create(user).then((response) => {
                setLoading(false)
                navigate('/users');
            })
                .catch(response => {
                    for (var element in response) {
                        if (Object.prototype.hasOwnProperty.call(response, element)) {
                            if (element === 'profile') {
                                for (var element2 in response[element]) {
                                    handleError(element2, response[element][element2])
                                }
                            }
                            if (response[element] != 'Este campo no puede estar en blanco.')
                                handleError(element, response[element])
                        }
                    }
                    setLoading(false)
                }
                )
        }
    }

    const deleteUser = async (event) => {
        var r = window.confirm("Este usuario será eliminado, confirma esta acción");
        if (r === true) {
            setLoading(true)
            api.users.delete(idUser).then((response) => {
                setLoading(false)
                alert("Usuario eliminado con éxito")
                navigate('/users');
            })
                .catch((e) => {
                    setLoading(false)
                    return Promise.reject(e.detail)
                });
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        if (idUser) {
            setLoading(true)
            api.users.getOne(idUser).then((response) => {
                setUser({
                    firstName: response.firstName,
                    lastName: response.lastName,
                    username: response.username,
                    email: response.email,
                    isStaff: response.isStaff,
                    profile: response.profile
                });
                setPrevGovId(response.profile.govId);
                setLoading(false);
            })
        }
    }, [])

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar current={'Usuarios'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ?
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <>
                        <form onSubmit={handleSubmit} className="flex-1 mt-4 bg-gray-100">
                            <div className="flex justify-between">
                                <Breadcrumbs pages={pages} />
                                <div>
                                    {
                                        idUser ?
                                            <button onClick={deleteUser} type="button" className="inline-flex mr-10 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-red-600 hover:bg-red-700">
                                                Eliminar usuario
                                            </button> : ""
                                    }
                                    <button type="submit" className="inline-flex mr-10 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                                        Guardar
                                    </button>
                                </div>
                            </div>
                            <div className="px-4 py-4 sm:px-6 md:px-8">
                                <h1 className="mt-4 text-xl leading-6 font-semibold text-gray-900">{idUser ? 'Editar Usuario' : 'Nuevo Usuario'}</h1>
                                <div className="flex mt-6 gap-x-6">
                                    <TextInput errorMessage={errors.firstName} defaultValue={user.firstName} handleChange={handleChange} containerClassName="w-1/5" name="firstName" label="Nombres" />
                                    <TextInput errorMessage={errors.lastName} defaultValue={user.lastName} handleChange={handleChange} containerClassName="w-1/5" name="lastName" label="Apellidos" />
                                </div>
                                <div className="flex mt-6 gap-x-6">
                                    <TextInput errorMessage={errors.govId} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} defaultValue={user.profile?.govId} handleChange={handleProfileChange} containerClassName="w-1/5" name="govId" label="C.I." />
                                    <TextInput errorMessage={errors.email} defaultValue={user.email} handleChange={handleChange} containerClassName="w-1/5" name="email" label="Correo Electrónico" />
                                </div>
                                <div className="flex mt-6 gap-x-6">
                                    <TextInput errorMessage={errors.phone} defaultValue={user.profile?.phone} handleChange={handleProfileChange} containerClassName="w-1/5" name="phone" label="Teléfono" />
                                    <SelectInput defaultValue={user.profile?.role} handleChange={handleProfileChange} containerClassName="w-1/5" name="role" label="Rol" options={
                                        roles.map(({ id, title }) => {
                                            return (
                                                <option key={id} value={id} selected={id == user.profile?.role}>{title}</option>
                                            )
                                        })
                                    } />
                                </div>
                            </div>
                        </form>
                    </>
                }
            </div>
        </div >
    );
}