import React, { useEffect } from "react";
import { useState } from 'react'
import DateInput from "./DateInput";
import SelectInput from "./SelectInput";
import RadioButtons from "./RadioButtons";
import FileInput from "./FileInput";
import TextInput from "./TextInput";
import Textarea from "./Textarea";
import api from "../api";

const sexOptions = [
    { id: 1, title: 'Masculino' },
    { id: 0, title: 'Femenino' },
]

const schoolingOptions = [
    { id: 0, name: 'No escolarizado' },
    { id: 1, name: 'Guardería' },
    { id: 2, name: 'Pre-jardín' },
    { id: 3, name: 'Jardín' },
    { id: 4, name: 'Preescolar' }
]

const weeksOptions = [
    24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44
]

export default function PersonalInformation(props) {

    const [profilePicture, setProfilePicture] = useState(props.patient.decodedprofilePicture)
    const hiddenFileInput = React.useRef(null);
    const uploadFile = (event) => {
        hiddenFileInput.current.click();
    };
    const handleFileChange = async (event, field) => {
        const fileUploaded = event.target.files[0];
        props.setPrevPictures(prev => ({ ...prev, profilePicture: null }));
        setProfilePicture(fileUploaded);
        const base64File = await convertBase64(fileUploaded)
        props.handleChange('decoded' + field, fileUploaded);
        props.handleChange(field, base64File);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handleDragDropFile = async (file, field) => {
        const fileUploaded = await convertBase64(file[0])
        if (field === 'govIdPictureFront') { // set prevPictures to null
            props.setPrevPictures(prevState => {
                let updated = prevState;
                updated.govIdPictureFront = null;
                return updated;
            });
        } else if (field === 'govIdPictureBack') { // set prevPictures to null
            props.setPrevPictures(prevState => {
                let updated = prevState;
                updated.govIdPictureBack = null;
                return updated;
            });
        }
        props.handleChange('decoded' + field, file[0]) // save decoded file for preview
        props.handleChange(field, fileUploaded) // save state to send the request
    };

    return (
        <div className="bg-white ml-8 mr-10 overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
                <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                    Información personal
                </h2>
                <div className="flex mt-4 gap-x-10">
                    <TextInput errorMessage={props.errors.fullName} defaultValue={props.patient.fullName} handleChange={props.handleChange} containerClassName="w-4/5" name="fullName" label="Nombre y Apellido *" />
                    <TextInput errorMessage={props.errors.govId} defaultValue={props.patient.govId} handleChange={props.handleChange} containerClassName="w-2/5" name="govId" label="C.I." />
                    <DateInput errorMessage={props.errors.birthdate} defaultValue={props.patient.birthdate} handleChange={props.handleChange} containerClassName="w-2/5" name="birthdate" label="Fecha de nacimiento" />
                </div>
                <div className="flex mt-6 gap-x-10">
                    <TextInput errorMessage={props.errors.birthPlace} defaultValue={props.patient.birthPlace} handleChange={props.handleChange} containerClassName="w-2/5" name="birthPlace" label="Lugar de nacimiento" />
                    <SelectInput
                        errorMessage={props.errors.departmentOfBirth}
                        handleChange={(field, value) => {
                            props.handleChange(field, value);
                            props.setCurrentBirthDepartment(value)
                        }}
                        containerClassName="w-2/5" name="departmentOfBirth" label="Departamento"
                        options={
                            props.departments.map((department) => {
                                return <option key={department.id} value={department.id} selected={department.id === props.patient.departmentOfBirth}>{department.name}</option>
                            })
                        }
                    />
                    <SelectInput errorMessage={props.errors.districtOfBirth} handleChange={props.handleChange} containerClassName="w-2/5" name="districtOfBirth" label="Distrito" options={
                        props.districts.map((district) => {
                            if (props.currentBirthDepartment == district.department) {
                                return <option
                                    key={district.id}
                                    value={district.id}
                                    selected={district.id === props.patient.districtOfBirth}
                                >
                                    {district.name}
                                </option>
                            }
                        })
                    } />
                </div>
                <div className="flex mt-4 gap-x-10">
                    <SelectInput errorMessage={props.errors.gestationWeeks} defaultValue={props.patient.gestationWeeks} handleChange={props.handleChange} containerClassName="w-1/5" name="gestationWeeks" label="Semanas de gestación al parto" options={
                        weeksOptions.map(week => {
                            return <option key={week} value={week} selected={week === props.patient.gestationWeeks}>{week}</option>
                        })
                    } />
                    <RadioButtons
                        defaultChecked={props.patient.gender}
                        handleChange={props.handleChange}
                        name="gender"
                        label="Sexo"
                        options={sexOptions}
                    />
                </div>
                <div className="flex mt-4 gap-x-10">
                    <SelectInput errorMessage={props.errors.schoolingLevel} defaultValue={props.patient.schoolingLevel} handleChange={props.handleChange} name="schoolingLevel" label="Nivel de escolaridad comprendidas en el nivel inicial" options={
                        schoolingOptions.map((option) => {
                            return <option key={option.id} value={option.id} selected={option.id === props.patient.schoolingLevel}>{option.name}</option>
                        })
                    } />
                </div>
                <div className="flex mt-4 gap-x-10">
                    <SelectInput
                        handleChange={(field, value) => {
                            props.handleChange(field, value);
                            props.setCurrentResidenceDepartment(value)
                        }}
                        containerClassName="w-2/5" name="residenceDepartment" label="Departamento de residencia"
                        options={
                            props.departments.map((department) => {
                                return <option key={department.id} value={department.id} selected={department.id === props.patient.residenceDepartment}>{department.name}</option>
                            })
                        }
                    />
                    <SelectInput
                        handleChange={props.handleChange}
                        containerClassName="w-2/5" name="residenceDistrict" label="Distrito de residencia"
                        options={
                            props.districts.map((district) => {
                                if (props.currentResidenceDepartment == district.department) {
                                    return <option
                                        key={district.id}
                                        value={district.id}
                                        selected={district.id === props.patient.residenceDistrict}
                                    >
                                        {district.name}
                                    </option>
                                }
                            })
                        }
                    />
                    <TextInput errorMessage={props.errors.address} defaultValue={props.patient.address} handleChange={props.handleChange} containerClassName="w-2/5" name="address" label="Dirección de residencia" />
                </div>
                <div className="flex mt-4 gap-x-10">
                    <SelectInput defaultValue={props.patient.initialEstablishment} handleChange={props.handleChange} containerClassName="w-1/2" name="initialEstablishment" label="Establecimiento de atención inicial" options={
                        props.establishments.map((option) => {
                            return <option key={option.id} value={option.id} selected={option.id === props.patient.initialEstablishment}>{option.name}</option>
                        })
                    } />
                    <SelectInput defaultValue={props.patient.referredEstablishment} handleChange={props.handleChange} containerClassName="w-1/2" name="referredEstablishment" label="Establecimiento al que fue referido" options={
                        props.establishments.map((option) => {
                            return <option key={option.id} value={option.id} selected={option.id === props.patient.referredEstablishment}>{option.name}</option>
                        })
                    } />
                </div>
                <div className="flex mt-4 gap-x-10">
                    <TextInput errorMessage={props.errors.diagnostic} defaultValue={props.patient.diagnostic} handleChange={props.handleChange} containerClassName="w-1/2" name="diagnostic" label="Diagnóstico" />
                    <TextInput errorMessage={props.errors.diagnosticHypothesis} defaultValue={props.patient.diagnosticHypothesis} handleChange={props.handleChange} containerClassName="w-1/2" name="diagnosticHypothesis" label="Hipótesis diagnóstica" />
                </div>
                <div className="mt-4">
                    <Textarea defaultValue={props.patient.obs} handleChange={props.handleChange} name="obs" label="Observaciones" />
                </div>
                <div className="flex mt-8 gap-x-10">
                    <div className="mt-1">
                        <label htmlFor="picture" className="block text-sm leading-5 font-medium text-gray-700">
                            Foto de perfil
                        </label>
                        <div className="flex mt-2 items-center">
                            {profilePicture || props.prevPictures.profilePicture ?
                                <>
                                    <img alt="not fount" className="h-12 w-12 rounded-full overflow-hidden bg-gray-100"
                                        src={props.prevPictures.profilePicture
                                            ? props.prevPictures.profilePicture
                                            : URL.createObjectURL(profilePicture)}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setProfilePicture(null);
                                            props.setPrevPictures(prevState => {
                                                let updated = prevState;
                                                updated.profilePicture = null;
                                                return updated;
                                            });
                                            props.handleChange('profilePicture', null);
                                            props.handleChange('decodedprofilePicture', null);
                                        }}
                                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50"
                                    >Eliminar</button>
                                </>
                                :
                                <>
                                    <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    </span>
                                    <button
                                        type="button"
                                        onClick={uploadFile}
                                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50"
                                    >
                                        Subir
                                    </button>
                                </>
                            }
                            <input
                                onChange={(event) => handleFileChange(event, 'profilePicture')}
                                type="file"
                                name="profile_picture"
                                ref={hiddenFileInput}
                                style={{ display: 'none' }} />
                        </div>
                        <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                            {props.errors.profilePicture}
                        </p>
                    </div>
                    <FileInput
                        defaultValue={props.patient.decodedgovIdPictureFront}
                        prevPicture={props.prevPictures.govIdPictureFront}
                        handleChange={handleDragDropFile}
                        errorMessage={props.errors.govIdPictureFront}
                        containerClassName="mt-1 w-1/3"
                        name="govIdPictureFront"
                        label="Foto de Cédula de Identidad (frente)"
                    />
                    <FileInput
                        defaultValue={props.patient.decodedgovIdPictureBack}
                        prevPicture={props.prevPictures.govIdPictureBack}
                        handleChange={handleDragDropFile}
                        errorMessage={props.errors.govIdPictureBack}
                        containerClassName="mt-1 w-1/3"
                        name="govIdPictureBack"
                        label="Foto de Cédula de Identidad (detrás)"
                    />
                </div>
            </div>
            <div className="flex bg-gray-100 justify-end px-4 py-4 sm:px-6">

                <button
                    type="button"
                    onClick={() => {
                        props.goForward()
                    }}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Siguiente
                </button>
            </div>
        </div>
    )
}