import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import api from "../../api";

export default function NewEstablishmentPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { idEstablishment } = useParams();
    const pages = [
        { name: 'Establecimientos', href: '/establishments', current: false },
        { name: `${idEstablishment ? 'Editar establecimiento' : 'Nuevo establecimiento'}`, href: `#`, current: true },
    ]
    const [departments, setDepartments] = useState([])
    const [currentDepartment, setCurrentDepartment] = useState(11)
    const [districts, setDistricts] = useState([])
    const [establishment, setEstablishment] = useState({
        code: "",
        name: "",
        departament: '11',
        district: '188',
    })
    const [loading, setLoading] = useState(idEstablishment ? true : false)
    const [error, setError] = useState({
        code: '',
        name: '',
    })
    const handleError = (field, value) => setError((prev) => ({ ...prev, [field]: value }));

    const navigate = useNavigate();
    const handleChange = (field, value) => setEstablishment((prev) => ({ ...prev, [field]: value }));
    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)

        if (idEstablishment) {
            api.establishments.update(idEstablishment, establishment, false).then((response) => {
                setLoading(false)
                navigate('/establishments');
            })
                .catch(response => {
                    for (var element in response) {
                        if (Object.prototype.hasOwnProperty.call(response, element)) {
                            if (response[element] != 'Este campo no puede estar en blanco.')
                                handleError(element, response[element])
                        }
                    }
                }
                )
        } else {
            api.establishments.create(establishment).then((response) => {
                setLoading(false)
                navigate('/establishments');
            })
                .catch(response => {
                    for (var element in response) {
                        if (Object.prototype.hasOwnProperty.call(response, element)) {
                            if (response[element] != 'Este campo no puede estar en blanco.')
                                handleError(element, response[element])
                        }
                    }
                }
                )
        }
    }

    const deleteEstablishment = async (event) => {
        let confirm = window.confirm("Este establecimiento será eliminado, confirma esta acción");
        if (confirm === true) {
            setLoading(true)
            api.establishments.delete(idEstablishment).then((response) => {
                setLoading(false)
                alert("Establecimiento eliminado con éxito")
                navigate('/establishments');
            })
                .catch((e) => {
                    if (e === 'forbidden') setError('Usted no tiene permisos para realizar esta acción')
                    setLoading(false)
                    return Promise.reject(e.detail)
                });
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        setLoading(true)

        // fill establishment data if this is a edition
        if (idEstablishment) {
            api.establishments.getOne(idEstablishment).then((response) => {
                setEstablishment({
                    code: response.code,
                    name: response.name,
                    departament: response.department.id,
                    district: response.district.id
                });
                setCurrentDepartment(response.department.id)
                setLoading(false);
            })
        }

        // fill departments into select departments options
        api.departments.getMulti().then((response) => {
            setDepartments(response.map(({ id, name }) => {
                return ({
                    id,
                    name
                })
            }));
            setLoading(false)
        })

        // fill districts into select districts options
        api.districts.getMulti().then((response) => {
            setDistricts(response.map(({ id, department, name }) => {
                return ({
                    id,
                    department,
                    name
                })
            }));
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        let filteredDistrict = districts.filter(district => district.department == currentDepartment)
        setEstablishment({
            ...establishment,
            district: filteredDistrict[0]?.id || ''
        })
    }, [currentDepartment])

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar current={'Establecimientos'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ?
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <>
                        <form onSubmit={handleSubmit} className="flex-1 mt-4 bg-gray-100">
                            <div className="flex justify-between">
                                <Breadcrumbs pages={pages} />
                                <div>
                                    {
                                        idEstablishment ?
                                            <button onClick={deleteEstablishment} type="button" className="inline-flex mr-10 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-red-600 hover:bg-red-700">
                                                Eliminar establecimiento
                                            </button> : ""
                                    }
                                    <button type="submit" className="inline-flex mr-10 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                                        Guardar
                                    </button>
                                </div>
                            </div>
                            <div className="px-4 py-4 sm:px-6 md:px-8">
                                {/* {error && <div className="mt-5 text-sm text-red-600">
                                    {error}
                                </div>} */}
                                <h1 className="mt-4 text-xl leading-6 font-semibold text-gray-900">{idEstablishment ? 'Editar establecimiento' : 'Nuevo establecimiento'}</h1>
                                <div className="flex mt-6 gap-x-6">
                                    <TextInput errorMessage={error.code} defaultValue={establishment.code} handleChange={handleChange} containerClassName="w-2/5" name="code" label="Código" />
                                    <TextInput errorMessage={error.name} defaultValue={establishment.name} handleChange={handleChange} containerClassName="w-2/5" name="name" label="Nombre" />
                                </div>
                                <div className="flex mt-6 gap-x-6">
                                    <SelectInput
                                        handleChange={(field, value) => {
                                            handleChange(field, value);
                                            setCurrentDepartment(value)
                                        }}
                                        containerClassName="w-2/5" name="departament" label="Departamento"
                                        options={
                                            (departments.length <= 0) ? '' : departments.map(({ id, name }) => {
                                                return (
                                                    <option
                                                        key={id}
                                                        value={id}
                                                        selected={id == establishment.departament}
                                                    >
                                                        {name}
                                                    </option>
                                                )
                                            })
                                        }
                                    />
                                    <SelectInput
                                        handleChange={handleChange}
                                        containerClassName="w-2/5"
                                        name="district" label="Distrito"
                                        options={
                                            (districts.length <= 0) ? '' : districts.map(({ id, department, name }) => {
                                                if (currentDepartment == department) {
                                                    return (
                                                        <option key={id} value={id} selected={id == establishment.district}>{name}</option>
                                                    )
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </form>
                    </>
                }
            </div>
        </div >
    );
}