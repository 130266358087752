import React, { useState } from "react"
import { PencilAltIcon } from '@heroicons/react/solid'
import ObservationModal from "./ObservationModal"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function BattelleItem(props) {

    const [score, setScore] = useState(props.defaultScore ? props.defaultScore : null);
    const [obs, setObs] = useState(props.defaultObservation ? props.defaultObservation : '');
    const [open, setOpen] = useState(false);

    return (
        <>
            <ObservationModal defaultValue={obs} setObs={setObs} item={props.item} handleChange={props.handleChange} open={open} setOpen={setOpen} />
            <li key={props.key} className="grid grid-cols-5 gap-24 font-medium leading-7 text-gray-700 py-4">
                <p>{props.age}</p>
                <p>{props.item}</p>
                <p>{props.behavior}</p>
                <div>
                    <span className="relative z-0 inline-flex shadow-sm rounded-md">
                        <button
                            type="button"
                            onClick={() => { setScore(2); props.handleChange(props.item, 'score', 2) }}
                            className={classNames(score === 2 ? "bg-indigo-500 text-white" : "text-black bg-white", "relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500")}
                        >
                            2
                        </button>
                        <button
                            type="button"
                            onClick={() => { setScore(1); props.handleChange(props.item, 'score', 1) }}
                            className={classNames(score === 1 ? "bg-indigo-500 text-white" : "text-black bg-white", "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500")}
                        >
                            1
                        </button>
                        <button
                            type="button"
                            onClick={() => { setScore(0); props.handleChange(props.item, 'score', 0) }}
                            className={classNames(score === 0 ? "bg-indigo-500 text-white" : "text-black bg-white", "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500")}
                        >
                            0
                        </button>
                    </span>
                </div>
                <button onClick={() => setOpen(true)} className="w-10 h-10 items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 leading-4 bg-white hover:bg-gray-50">
                    <PencilAltIcon className="h-4 w-4 text-gray-500" />
                </button>
            </li>
        </>
    )
}