import React, { useEffect } from "react";
import { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import {
    MenuAlt2Icon
} from '@heroicons/react/outline'
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import Steps from "../../components/Steps";
import api from "../../api";
import PersonalInformation from "../../components/PersonalInformation";
import Family from "../../components/Family";

const pages = [
    { name: 'Niños usuarios', href: '/patients', current: false },
    { name: 'Nuevo niño usuario', href: `#`, current: true },
]

export default function NewPatientPage() {

    const { idPatient } = useParams();
    const [prevBirthDistrict, setPrevBirthDistrict] = useState(null);
    const [prevResidenceDistrict, setPrevResidenceDistrict] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [steps, setStep] = useState([
        { id: 1, name: 'FICHA DEL NIÑO USUARIO', description: 'Información personal', status: 'current' },
        { id: 2, name: 'FICHA DEL ADULTO', description: 'Datos adicionales', status: 'upcoming' }
    ])
    const today = new Date().toISOString().substring(0, 10)
    const [patient, setPatient] = useState({
        fullName: '',
        birthdate: today,
        birthPlace: '',
        departmentOfBirth: '0',
        districtOfBirth: '5',
        gestationWeeks: "25",
        gender: "1",
        schoolingLevel: '0',
        residenceDepartment: '0',
        residenceDistrict: '5',
        address: '',
        diagnostic: '',
        diagnosticHypothesis: '',
        referredContactName: '',
        referredContactPhone: '',
        initialEstablishment: '1',
        referredEstablishment: '1',
        obs: '',
        responsibleAdults: []
    })
    const [adults, setAdults] = useState([
        {
            fullName: '',
            govId: '',
            phone: '',
            observations: '',
            prevGovIdPictureFront: null,
            prevGovIdPictureBack: null
        }
    ])
    const [errors, setErrors] = useState({
        fullName: '',
        govId: '',
        profilePicture: '',
        govIdPictureFront: '',
        govIdPictureBack: '',
        adults: [
            {
                fullName: '',
                govId: '',
                phone: '',
                profilePicture: '',
                govIdPictureFront: '',
                govIdPictureBack: '',
            }
        ],
    });
    const [departments, setDepartments] = useState([])
    const [currentBirthDepartment, setCurrentBirthDepartment] = useState(0)
    const [currentResidenceDepartment, setCurrentResidenceDepartment] = useState(0)
    const [districts, setDistricts] = useState([])
    const [establishments, setEstablishments] = useState([])
    const [errorFlag, setErrorFlag] = useState(false)

    const [prevPictures, setPrevPictures] = useState({
        profilePicture: null,
        govIdPictureFront: null,
        govIdPictureBack: null,
    })

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const handleChange = (field, value) => setPatient((prev) => ({ ...prev, [field]: value }));
    const handleError = (field, value) => setErrors((prev) => ({ ...prev, [field]: value }));

    const handleAdult = (field, value, index) => {
        const obj = adults.slice();
        obj[index][field] = value;
        setAdults(obj);
    }

    const handleAdultError = (field, value, index) => {
        const obj = errors.adults.slice();
        obj[index][field] = value;
        handleError('adults', obj);
    }

    const deleteAdult = (index) => {
        let obj = adults.filter((element, idx) => idx !== index);
        let errorsobj = errors.adults.filter((element, idx) => idx !== index);
        setAdults(obj);
        handleError('adults', errorsobj);
    }

    useEffect(async () => {
        if (idPatient) {
            setLoading(true)
            await api.districts.getMulti().then((response) => {
                setDistricts(response.map(({ id, department, name }) => {
                    return ({
                        id,
                        department,
                        name
                    })
                }));
            })
            await api.departments.getMulti().then((response) => {
                setDepartments(response.map(({ id, name }) => {
                    return ({
                        id,
                        name
                    })
                }));
            })
            api.patients.getOne(idPatient).then(({
                fullName,
                govId,
                birthdate,
                birthPlace,
                departmentOfBirth,
                districtOfBirth,
                gestationWeeks,
                gender,
                schoolingLevel,
                residenceDepartment,
                residenceDistrict,
                address,
                diagnostic,
                diagnosticHypothesis,
                referredContactName,
                referredContactPhone,
                initialEstablishment,
                referredEstablishment,
                profilePicture,
                govIdPictureFront,
                govIdPictureBack,
                responsibleAdults,
                obs
            }) => {
                setAdults(responsibleAdults.map(({ fullName, govId, phone, observations, govIdPictureFront, govIdPictureBack }) => {
                    return {
                        fullName,
                        govId,
                        phone,
                        observations,
                        prevGovIdPictureFront: govIdPictureFront,
                        prevGovIdPictureBack: govIdPictureBack
                    }
                }));
                setPrevPictures({
                    profilePicture,
                    govIdPictureFront,
                    govIdPictureBack,
                })
                setPatient({
                    fullName,
                    govId,
                    birthdate,
                    birthPlace,
                    departmentOfBirth: departmentOfBirth.id,
                    districtOfBirth: districtOfBirth.id,
                    gestationWeeks,
                    gender,
                    schoolingLevel,
                    residenceDepartment: residenceDepartment.id,
                    residenceDistrict: residenceDistrict.id,
                    address,
                    diagnostic,
                    diagnosticHypothesis,
                    referredContactName,
                    referredContactPhone,
                    obs,
                    initialEstablishment: initialEstablishment.id,
                    referredEstablishment: referredEstablishment.id,
                    responsibleAdults: responsibleAdults.map(({ fullName, govId, phone, observations, govIdPictureFront, govIdPictureBack }) => {
                        return {
                            fullName,
                            govId,
                            phone,
                            observations,
                            prevGovIdPictureFront: govIdPictureFront,
                            prevGovIdPictureBack: govIdPictureBack
                        }
                    })
                });
                setPrevBirthDistrict(districtOfBirth.id);
                setPrevResidenceDistrict(residenceDistrict.id);
                setCurrentBirthDepartment(departmentOfBirth.id);
                setCurrentResidenceDepartment(residenceDepartment.id);
                setLoading(false);
            }).catch((error) => {
                console.log(error)
                setLoading(false);
            })
        }

        await api.districts.getMulti().then((response) => {
            setDistricts(response.map(({ id, department, name }) => {
                return ({
                    id,
                    department,
                    name
                })
            }));
        })
        await api.departments.getMulti().then((response) => {
            setDepartments(response.map(({ id, name }) => {
                return ({
                    id,
                    name
                })
            }));
        })

        api.establishments.getMulti().then((response) => {
            setEstablishments(response.map(({ id, name }) => {
                return ({
                    id,
                    name
                })
            }));
        })
    }, [])

    useEffect(() => {
        handleChange('responsibleAdults', adults);
    }, [adults])

    useEffect(() => {
        let filteredBirthDistrict = districts.filter(district => district.department == currentBirthDepartment)
        if (prevBirthDistrict) {
            setPatient({
                ...patient,
                districtOfBirth: prevBirthDistrict,
            })
            setPrevBirthDistrict(null);
        } else {
            setPatient({
                ...patient,
                districtOfBirth: filteredBirthDistrict[0]?.id || '5',
            })
        }

    }, [currentBirthDepartment])

    useEffect(() => {
        let filteredResidenceDistrict = districts.filter(district => district.department == currentResidenceDepartment)
        if (prevResidenceDistrict) {
            setPatient({
                ...patient,
                residenceDistrict: prevResidenceDistrict
            })
            setPrevResidenceDistrict(null);
        } else {
            setPatient({
                ...patient,
                residenceDistrict: filteredResidenceDistrict[0]?.id || '5'
            })
        }

    }, [currentResidenceDepartment])

    const goForward = () => {
        let error = false
        if (!patient.fullName) {
            handleError('fullName', 'Este campo es obligatorio')
            error = true
        } else {
            handleError('fullName', '')
        }
        if (!error) {
            setStep((prev) => [{ ...prev[0], status: 'complete' }, { ...prev[1], status: 'current' }])
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        if (idPatient) {
            api.patients.update(idPatient, patient, false).then((response) => {
                setLoading(false)
                navigate('/patients/' + response.id);
            })
                .catch(response => {
                    for (var element in response) {
                        if (Object.prototype.hasOwnProperty.call(response, element)) {
                            if (response[element] != 'Este campo no puede estar en blanco.')
                                handleError(element, response[element])
                        }
                    }
                    setErrorFlag(true);
                    setLoading(false);
                }
                );
        } else {
            let error = false
            patient.responsibleAdults.forEach((adult, index) => {
                if (!adult.fullName) {
                    handleAdultError('fullName', 'Este campo es obligatorio', index)
                    error = true
                } else {
                    handleAdultError('fullName', '', index)
                }
                if (!adult.govId) {
                    handleAdultError('govId', 'Este campo es obligatorio', index)
                    error = true
                } else {
                    handleAdultError('govId', '', index)
                }
                if (!adult.phone) {
                    handleAdultError('phone', 'Este campo es obligatorio', index)
                    error = true
                } else {
                    handleAdultError('phone', '', index)
                }
            })
            if (!error) {
                api.patients.create(patient).then((response) => {
                    setLoading(false)
                    navigate('/patients/' + response.id);
                })
                    .catch(response => {
                        for (var element in response) {
                            if (Object.prototype.hasOwnProperty.call(response, element)) {
                                if (response[element] != 'Este campo no puede estar en blanco.')
                                    handleError(element, response[element])
                            }
                        }
                        setErrorFlag(true);
                        setLoading(false);
                    }
                    )
            } else {
                setLoading(false);
            }
        }
    }

    return (
        <div className="bg-gray-100 min-h-screen h-full">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                {
                    loading ?
                        <div className="flex h-full items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                        </div>
                        :
                        <>
                            <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                                <button
                                    type="button"
                                    className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    onClick={() => setSidebarOpen(true)}
                                >
                                    <span className="sr-only">Open sidebar</span>
                                    <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="flex flex-col flex-1 space-y-4 pb-4 ml-6 mt-6">
                                <Breadcrumbs pages={pages} />
                                <Steps steps={steps} />
                                {errorFlag ? <p className="mt-2 ml-8 text-sm text-red-600">
                                    Error en la carga de datos. Favor verificar todos los campos
                                </p> : null}
                                {steps[0].status === 'current' && <PersonalInformation
                                    patient={patient}
                                    establishments={establishments}
                                    departments={departments}
                                    districts={districts}
                                    currentBirthDepartment={currentBirthDepartment}
                                    setCurrentBirthDepartment={setCurrentBirthDepartment}
                                    currentResidenceDepartment={currentResidenceDepartment}
                                    setCurrentResidenceDepartment={setCurrentResidenceDepartment}
                                    prevPictures={prevPictures}
                                    setPrevPictures={setPrevPictures}
                                    goForward={goForward}
                                    handleChange={handleChange}
                                    errors={errors}
                                />}
                                {steps[1].status === 'current' && <Family
                                    setStep={setStep}
                                    setAdults={setAdults}
                                    adults={adults}
                                    handleSubmit={handleSubmit}
                                    handleAdult={handleAdult}
                                    deleteAdult={deleteAdult}
                                    errors={errors}
                                    setErrors={setErrors}
                                />}
                            </div>
                        </>
                }
            </div>
        </div >
    );
}