import React from "react";
import { useState, useEffect } from 'react'
import {
    MenuAlt2Icon,
} from '@heroicons/react/outline'
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import Steps from "../../components/Steps";
import TextInput from "../../components/TextInput";
import VerticalRadioButtons from "../../components/VerticalRadioButtons";
import RadioButtons from "../../components/RadioButtons";
import Toggle from "../../components/Toggle";
import Textarea from "../../components/Textarea";
import api from "../../api";

const status = [
    "Pendiente",
    "Admitido",
    "No admitido",
    "Alta médica"
]

const insuranceOptions = [
    { id: 0, title: 'No' },
    { id: 1, title: 'IPS' },
    { id: 2, title: 'Privado' },
]

const yesNoOptions = [
    { id: 1, title: 'Si' },
    { id: 2, title: 'No' }
]

const ageOptions = [
    { id: 0, title: 'Igual o mayor a 12 meses' },
    { id: 1, title: 'Igual o mayor a 24 meses' },
    { id: 2, title: 'De 3 a 5 años' }
]

const roofOptions = [
    { id: 0, title: 'Madera' },
    { id: 1, title: 'Chapa Zinc' },
    { id: 2, title: 'Paja' },
    { id: 3, title: 'Teja' },
    { id: 4, title: 'Eternit' },
    { id: 5, title: 'Hormigón o Loza' },
]

const wallOptions = [
    { id: 0, title: 'Cartón, hule' },
    { id: 1, title: 'Tronco de palma' },
    { id: 2, title: 'Bloque de cemento' },
    { id: 3, title: 'Adobe' },
    { id: 4, title: 'Estaqueo' },
    { id: 5, title: 'Madera' },
    { id: 6, title: 'Ladrillo' },
]

const floorOptions = [
    { id: 0, title: 'Tierra' },
    { id: 1, title: 'Ladrillo' },
    { id: 2, title: 'Cemento' },
    { id: 3, title: 'Madera' },
    { id: 4, title: 'Cerámica' },
    { id: 5, title: 'Parquet' },
]

const houseOwnershipOptions = [
    { id: 0, title: 'Propia' },
    { id: 1, title: 'Familiar' },
    { id: 2, title: 'Alquilada' },
    { id: 3, title: 'Prestada' },
    { id: 4, title: 'Ocupada de hecho' },
]

const cookingOptions = [
    { id: 0, title: 'Leña' },
    { id: 1, title: 'Carbón' },
    { id: 2, title: 'Gas' },
    { id: 3, title: 'Eléctrico' },
]

const accessOptions = [
    { id: 0, title: 'Difícil' },
    { id: 1, title: 'Medianamente accesible' },
    { id: 2, title: 'Fácil acceso' },
]

const locationOptions = [
    { id: 0, title: 'San Lorenzo' },
    { id: 1, title: 'Central' },
    { id: 2, title: 'Interior' }
]

const bathroomOptions = [
    { id: 0, title: 'No tiene baño' },
    { id: 1, title: 'Letrina' },
    { id: 2, title: 'Moderno' }
]

const powerOptions = [
    { id: 0, title: 'No tiene' },
    { id: 1, title: 'Comparte' },
    { id: 2, title: 'ANDE' }
]

const waterOptions = [
    { id: 0, title: 'No tiene' },
    { id: 1, title: 'Comparte' },
    { id: 2, title: 'ESSAP / Aguateria privada' }
]

const treatmentOptions = [
    { id: 0, title: 'No' },
    { id: 1, title: 'Público' },
    { id: 2, title: 'Privado' }
]

const earningsOptions = [
    { id: 0, title: 'Inferior al salario mínimo' },
    { id: 1, title: 'Salario mínimo' },
    { id: 2, title: 'Dos salarios mínimos' },
    { id: 3, title: 'Más de dos salarios mínimos' },
]

const mobilityOptions = [
    { id: 0, title: 'A pie' },
    { id: 1, title: 'Colectivo' },
    { id: 2, title: 'Moto' },
    { id: 3, title: 'Auto/Camioneta' },
]

const workingOptions = [
    { id: 0, title: 'Uno o ambos padres y/o tutores con condición laboral inestable' },
    { id: 1, title: 'Uno de los padres y/o tutores cuenta con trabajo estable' },
    { id: 2, title: 'Ambos padres y/o tutores cuentan con trabajo estable' },
]

const educationOptions = [
    { id: 0, title: 'Sin estudios o con estudios primarios' },
    { id: 1, title: 'Con estudios secundarios concluidos' },
    { id: 2, title: 'Con formación universitaria y/o terciaria' },
]

const admissionOptions = [
    { key: 1, id: 1, title: 'Sí' },
    { key: 0, id: 0, title: 'No' },
    { key: 3, id: 3, title: 'Interconsulta' },
]

const derivationOptions = [
    { id: 0, title: 'SENADIS' },
    { id: 1, title: 'Hospital de mayor complejidad' },
    { id: 2, title: 'USF' },
    { id: 3, title: 'Otros' },
]

const Analysis = function (props) {

    return (
        <div className="flex">
            <div className="bg-white ml-8 mr-10 overflow-hidden w-1/2 shadow divide-y rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Seguro
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Seguro Médico</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="healthInsurance" options={insuranceOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Documento
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Cédula de identidad</p>
                    <VerticalRadioButtons defaultChecked={1} handleChange={props.handleChange} name="hasGovId" options={yesNoOptions} />
                    <p className="mt-4 text-sm leading-5 font-normal text-indigo-600">Edad</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="age" options={ageOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Caracteristicas de la vivienda
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Techo</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="roof" options={roofOptions} />
                    <p className="mt-4 text-sm leading-5 font-normal text-indigo-600">Pared</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="wall" options={wallOptions} />
                    <p className="mt-4 text-sm leading-5 font-normal text-indigo-600">Piso</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="floor" options={floorOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Vivienda
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Tenencia</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="homeTenure" options={houseOwnershipOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Situación de
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Hacinamiento</p>
                    <VerticalRadioButtons defaultChecked={1} handleChange={props.handleChange} name="overcrowding" options={yesNoOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Para la cocina
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Utiliza</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="kitchen" options={cookingOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Territorio
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Para llegar a su casa el acceso es</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="access" options={accessOptions} />
                    <p className="mt-4 text-sm leading-5 font-normal text-indigo-600">Lugar de residencia</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="place" options={locationOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Servicios básicos
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Servicios sanitarios</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="bath" options={bathroomOptions} />
                    <p className="mt-4 text-sm leading-5 font-normal text-indigo-600">Servicio de energía eléctrica</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="electricity" options={powerOptions} />
                    <p className="mt-4 text-sm leading-5 font-normal text-indigo-600">Servicio de agua potable</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="water" options={waterOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Salud
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Enfermedad crónica</p>
                    <VerticalRadioButtons defaultChecked={1} handleChange={props.handleChange} name="chronicIllness" options={yesNoOptions} />
                    <p className="mt-4 text-sm leading-5 font-normal text-indigo-600">Familiar con discapacidades</p>
                    <VerticalRadioButtons defaultChecked={1} handleChange={props.handleChange} name="familyMemberWithDisability" options={yesNoOptions} />
                    <p className="mt-4 text-sm leading-5 font-normal text-indigo-600">El niño sigue tratamiento en</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="treatment" options={treatmentOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Por hogar
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Ingreso mensual</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="monthlyIncome" options={earningsOptions} />
                    <p className="mt-4 text-sm leading-5 font-normal text-indigo-600">Condición laboral</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="workingCondition" options={workingOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Educación
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Nivel educativo de los padres y/o tutores</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="educationLevel" options={educationOptions} />
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Medio de movilidad
                    </h2>
                    <p className="mt-2 text-sm leading-5 font-normal text-indigo-600">Movilidad</p>
                    <VerticalRadioButtons defaultChecked={0} handleChange={props.handleChange} name="mobility" options={mobilityOptions} />
                </div>
                <div className="flex bg-gray-100 justify-end px-4 py-4 sm:px-6">
                    <button
                        type="button"
                        onClick={() => {
                            props.setStep((prev) => [{ ...prev[0], status: 'complete' }, { ...prev[1], status: 'current' }, prev[2]])
                        }}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
            <ul className="lg:fixed lg:right-6 divide-y w-1/3 divide-gray-300">
                <li key={1} className="flex flex-col py-4">
                    <p className="text-lg leading-6 font-semibold text-indigo-600">Puntaje</p>
                    <p className="mt-4 text-3xl leading-6 font-bold text-gray-900">{props.score}</p>
                </li>
                <li key={2} className="flex flex-col py-4">
                    <p className="text-lg leading-6 font-semibold text-indigo-600">Priorización</p>
                    <p className="text-lg leading-6 font-medium text-gray-500">Nivel de prioridad</p>
                    <p className="mt-4 text-3xl leading-6 font-bold text-gray-900">{props.priorityLevel}</p>
                </li>
            </ul>
        </div>
    )
}

const Summary = function (props) {

    return (
        <>
            <ul className="divide-y divide-gray-200">
                <li key={1} className="grid grid-cols-6 gap-x-4 items-end font-semibold leading-7 text-gray-700 py-4">
                    <p>Areas</p>
                    <p>Edad (equivalente en meses)</p>
                    <p className="col-span-2">Puntos fuertes</p>
                    <p className="col-span-2">Puntos débiles</p>
                </li>
                <li key={2} className="grid grid-cols-6 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                    <p>Personal/social</p>
                    <p>{props.evaluationSynthesis.personalSocialAreaEquivalentAge}</p>
                    <Textarea defaultValue={props.evaluationSynthesis.personalSocialAreaStrengths} handleChange={props.handleChange} name="personalSocialAreaStrengths" rows={2} containerClassName="col-span-2 w-5/6" />
                    <Textarea defaultValue={props.evaluationSynthesis.personalSocialAreaWeaknesses} handleChange={props.handleChange} name="personalSocialAreaWeaknesses" rows={2} containerClassName="col-span-2 w-5/6" />
                </li>
                <li key={3} className="grid grid-cols-6 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                    <p>Adaptativa</p>
                    <p>{props.evaluationSynthesis.adaptiveAreaEquivalentAge}</p>
                    <Textarea defaultValue={props.evaluationSynthesis.adaptiveAreaStrengths} handleChange={props.handleChange} name="adaptiveAreaStrengths" rows={2} containerClassName="col-span-2 w-5/6" />
                    <Textarea defaultValue={props.evaluationSynthesis.adaptiveAreaWeaknesses} handleChange={props.handleChange} name="adaptiveAreaWeaknesses" rows={2} containerClassName="col-span-2 w-5/6" />
                </li>
                <li key={4} className="grid grid-cols-6 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                    <p>Motora</p>
                    <p>{props.evaluationSynthesis.motorAreaEquivalentAge}</p>
                    <Textarea defaultValue={props.evaluationSynthesis.motorAreaStrengths} handleChange={props.handleChange} name="motorAreaStrengths" rows={2} containerClassName="col-span-2 w-5/6" />
                    <Textarea defaultValue={props.evaluationSynthesis.motorAreaWeaknesses} handleChange={props.handleChange} name="motorAreaWeaknesses" rows={2} containerClassName="col-span-2 w-5/6" />
                </li>
                <li key={5} className="grid grid-cols-6 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                    <p>Comunicación</p>
                    <p>{props.evaluationSynthesis.communicationAreaEquivalentAge}</p>
                    <Textarea defaultValue={props.evaluationSynthesis.communicationAreaStrengths} handleChange={props.handleChange} name="communicationAreaStrengths" rows={2} containerClassName="col-span-2 w-5/6" />
                    <Textarea defaultValue={props.evaluationSynthesis.communicationAreaWeaknesses} handleChange={props.handleChange} name="communicationAreaWeaknesses" rows={2} containerClassName="col-span-2 w-5/6" />
                </li>
                <li key={6} className="grid grid-cols-6 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                    <p>Cognitiva</p>
                    <p>{props.evaluationSynthesis.cognitiveAreaEquivalentAge}</p>
                    <Textarea defaultValue={props.evaluationSynthesis.cognitiveAreaStrengths} handleChange={props.handleChange} name="cognitiveAreaStrengths" rows={2} containerClassName="col-span-2 w-5/6" />
                    <Textarea defaultValue={props.evaluationSynthesis.cognitiveAreaWeaknesses} handleChange={props.handleChange} name="cognitiveAreaWeaknesses" rows={2} containerClassName="col-span-2 w-5/6" />
                </li>
                <li key={7} className="grid grid-cols-6 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                    <p>Desarrollo global</p>
                    <p>{props.evaluationSynthesis.equivalentAge}</p>
                    <Textarea defaultValue={props.evaluationSynthesis.generalStrengths} handleChange={props.handleChange} name="generalStrengths" rows={2} containerClassName="col-span-2 w-5/6" />
                    <Textarea defaultValue={props.evaluationSynthesis.generalWeaknesses} handleChange={props.handleChange} name="generalWeaknesses" rows={2} containerClassName="col-span-2 w-5/6" />
                </li>
            </ul>
        </>
    )
}

const Admission = function (props) {

    return (
        <ul className="divide-y divide-gray-200">
            <li key={1} className="ml-8 font-semibold leading-7 text-gray-700 py-4">
                <h1>Ingresar al SIT</h1>
                <RadioButtons defaultChecked={1} name="enterTheSit" handleChange={props.handleChange} options={admissionOptions} />
            </li>
            {props.admission.enterTheSit === "0" && <li key={2} className="ml-8 font-semibold leading-7 text-gray-700 py-4">
                <h1>Observaciones</h1>
                <Textarea handleChange={props.handleChange} name="obs" rows={4} containerClassName="w-1/2" />
                <p className="mt-2">Derivaciones</p>
                <VerticalRadioButtons handleChange={props.handleChange} defaultChecked={0} name="derivation" options={derivationOptions} />
                {props.admission.derivation === '3' &&
                    <TextInput label="Especificar" containerClassName="mt-4 w-1/4" handleChange={props.handleChange} name="derivedEstablishment" />
                }
            </li>}
        </ul>
    )
}

export default function AdmissionPage() {

    const today = new Date().toISOString().substring(0, 10)
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [steps, setStep] = useState([
        { id: 1, name: 'ANÁLISIS SOCIO-ECONÓMICO', status: 'current' },
        { id: 2, name: 'SÍNTESIS DE LA EVALUACIÓN', status: 'upcoming' },
        { id: 3, name: 'ADMISIÓN', status: 'upcoming' }
    ]);
    const { idPatient } = useParams();
    const [score, setScore] = useState(8);
    const [priorityLevel, setPriorityLevel] = useState(1);
    const [patient, setPatient] = useState({});
    const [admission, setAdmission] = useState({
        socioeconomicSurvey: {},
        evaluationSynthesis: {},
        enterTheSit: 1,
        obs: '',
        derivation: 0,
        date: today,
        kid: null,
    });
    const [socioeconomicSurvey, setSocioeconomicSurvey] = useState({
        healthInsurance: 0,
        hasGovId: 1,
        age: 0,
        roof: 0,
        wall: 0,
        floor: 0,
        overcrowding: 1,
        homeTenure: 0,
        kitchen: 0,
        access: 0,
        place: 0,
        bath: 0,
        electricity: 0,
        water: 0,
        chronicIllness: 1,
        familyMemberWithDisability: 1,
        treatment: 0,
        monthlyIncome: 0,
        workingCondition: 0,
        educationLevel: 0,
        mobility: 0,
    });
    const [evaluationSynthesis, setEvaluationSynthesis] = useState({});
    const handleAdmission = (field, value) => setAdmission((prev) => ({ ...prev, [field]: value }));
    const handleSurvey = (field, value) => setSocioeconomicSurvey((prev) => ({ ...prev, [field]: value }));
    const handleSynthesis = (field, value) => setEvaluationSynthesis((prev) => ({ ...prev, [field]: value }));

    useEffect(() => {
        setLoading(true)
        api.patients.getOne(idPatient).then((response) => {
            setPatient(response);
            handleAdmission('kid', response.id);
            setLoading(false);
        }).catch((error) => console.log(error))
        api.inventory.getMulti(null, null, null, null, { kid: idPatient }).then((response) => {
            setEvaluationSynthesis({ ...response[response.length - 1], generalEquivalentAge: response[response.length - 1].equivalentAge })
            setLoading(false);
        }).catch((error) => console.log(error))
    }, [])

    useEffect(() => {
        let aux = 0;
        aux = aux + parseInt(socioeconomicSurvey.healthInsurance) + parseInt(socioeconomicSurvey.age) + parseInt(socioeconomicSurvey.access) + parseInt(socioeconomicSurvey.place) + parseInt(socioeconomicSurvey.chronicIllness) + parseInt(socioeconomicSurvey.familyMemberWithDisability)
            + parseInt(socioeconomicSurvey.hasGovId) + parseInt(socioeconomicSurvey.bath) + parseInt(socioeconomicSurvey.electricity) + parseInt(socioeconomicSurvey.water) + parseInt(socioeconomicSurvey.treatment) +
            + parseInt(socioeconomicSurvey.overcrowding) + parseInt(socioeconomicSurvey.monthlyIncome) + parseInt(socioeconomicSurvey.workingCondition) + parseInt(socioeconomicSurvey.educationLevel) + parseInt(socioeconomicSurvey.mobility);
        if ([0, 1, 2].indexOf(parseInt(socioeconomicSurvey.roof)) !== -1) {
            aux = aux + 1
        } else {
            aux = aux + 2
        }
        if ([1, 2, 3, 4].indexOf(parseInt(socioeconomicSurvey.wall)) !== -1) {
            aux = aux + 1
        } else if ([5, 6].indexOf(parseInt(socioeconomicSurvey.wall)) !== -1) {
            aux = aux + 2
        }
        if ([1, 2].indexOf(parseInt(socioeconomicSurvey.floor)) !== -1) {
            aux = aux + 1
        } else if ([3, 4, 5].indexOf(parseInt(socioeconomicSurvey.floor)) !== -1) {
            aux = aux + 2
        }
        if ([0, 1].indexOf(parseInt(socioeconomicSurvey.homeTenure)) !== -1) {
            aux = aux + 2
        } else {
            aux = aux + 1
        }
        if ([0, 1].indexOf(parseInt(socioeconomicSurvey.kitchen)) !== -1) {
            aux = aux + 1
        } else {
            aux = aux + 2
        }
        setScore(aux);
    }, [socioeconomicSurvey.access, socioeconomicSurvey.age, socioeconomicSurvey.bath, socioeconomicSurvey.chronicIllness, socioeconomicSurvey.educationLevel,
    socioeconomicSurvey.electricity, socioeconomicSurvey.familyMemberWithDisability, socioeconomicSurvey.floor, socioeconomicSurvey.hasGovId, socioeconomicSurvey.healthInsurance,
    socioeconomicSurvey.homeTenure, socioeconomicSurvey.kitchen, socioeconomicSurvey.mobility, socioeconomicSurvey.monthlyIncome, socioeconomicSurvey.overcrowding, socioeconomicSurvey.place,
    socioeconomicSurvey.roof, socioeconomicSurvey.treatment, socioeconomicSurvey.wall, socioeconomicSurvey.water, socioeconomicSurvey.workingCondition])

    useEffect(() => {
        if (score < 23) {
            setPriorityLevel(1);
        } else if (score < 40) {
            setPriorityLevel(2);
        } else {
            setPriorityLevel(3);
        }
    }, [score])

    useEffect(() => {
        handleAdmission('socioeconomicSurvey', socioeconomicSurvey)
    }, [socioeconomicSurvey]);

    useEffect(() => {
        handleAdmission('evaluationSynthesis', evaluationSynthesis)
    }, [evaluationSynthesis])

    const handleSubmit = () => {
        setButtonLoading(true);
        api.patients.sendAdmission(admission).then((response) => {
            setButtonLoading(false);
            navigate(`/patients/${idPatient}`);
        })
            .catch((e) => {
                setButtonLoading(false);
                return Promise.reject(e.detail);
            });
    }

    const pages = [
        { name: 'Niños usuarios', href: '/patients', current: false },
        { name: `${patient.fullName} | ${patient.age} meses | ${status[patient.status]}`, href: `/patients/${patient.id}`, current: false },
        { name: 'Proceso de admisión', href: '#', current: true }
    ]

    return (
        <div className="bg-gray-100 min-h-screen h-full">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 space-y-4 pb-4 ml-6 mt-6">
                    <div className="flex justify-between">
                        <Breadcrumbs pages={pages} />
                        {steps[0].status === 'current' &&
                            <button
                                type="button"
                                onClick={() => {
                                    setStep((prev) => [{ ...prev[0], status: 'complete' }, { ...prev[1], status: 'current' }, prev[2]])
                                }}
                                className="inline-flex mr-10 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Siguiente
                            </button>}
                        {steps[1].status === 'current' &&
                            <button
                                type="button"
                                onClick={() => {
                                    setStep((prev) => [{ ...prev[0], status: 'complete' }, { ...prev[1], status: 'complete' }, { ...prev[2], status: 'current' }])
                                }}
                                className="inline-flex mr-10 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Siguiente
                            </button>}
                        {steps[2].status === 'current' &&
                            <button
                                type="button"
                                onClick={() => handleSubmit()}
                                className="inline-flex mr-10 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Guardar
                            </button>}
                    </div>
                    <Steps steps={steps} setStep={setStep} />
                    {steps[0].status === 'current' && <Analysis setStep={setStep} handleChange={handleSurvey} socioeconomicSurvey={socioeconomicSurvey} score={score} priorityLevel={priorityLevel} />}
                    {steps[1].status === 'current' && <Summary setStep={setStep} evaluationSynthesis={evaluationSynthesis} handleChange={handleSynthesis} />}
                    {steps[2].status === 'current' && <Admission setStep={setStep} admission={admission} handleChange={handleAdmission} handleSubmit={handleSubmit} />}
                </div>
            </div>
        </div >
    );
}
