import React from "react";
import { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
    MenuAlt2Icon,
} from '@heroicons/react/outline'
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import Tabs from "../../components/Tabs";
import BattelleItem from "../../components/BattelleItem";
import BattelleArea from "../../components/BattelleArea";
import Divider from "../../components/Divider";
import api from "../../api";
import Textarea from "../../components/Textarea";
import { useWindowWidth } from "@react-hook/window-size";
import { HorizontalGridLines, AreaSeries, XYPlot, YAxis, XAxis, LineMarkSeries, Hint, VerticalGridLines } from "react-vis/dist";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const status = [
    "Pendiente",
    "Admitido",
    "No admitido",
    "Alta médica"
]

const tabs = [
    { name: 'Personal/social', current: true },
    { name: 'Adaptativa', current: false },
    { name: 'Motora', current: false },
    { name: 'Comunicación', current: false },
    { name: 'Cognitiva', current: false },
    { name: 'Resumen', current: false }
]

const InventoryAxis = [
    'Interacción con el adulto',
    'Expresión de sentimientos/afecto',
    'Autoconcepto',
    'Interacción con los compañeros',
    'Colaboración',
    'Rol social',
    'Total Personal/social',
    'Atención',
    'Comida',
    'Vestido',
    'Responsabilidad personal',
    'Aseo',
    'Total Adaptativa',
    'Control muscular',
    'Coordinación corporal',
    'Locomoción',
    'Puntuación Motora gruesa',
    'Motricidad fina',
    'Motricidad perceptiva',
    'Puntuación Motora fina',
    'Total Motora',
    'Receptiva',
    'Expresiva',
    'Total Comunicación',
    'Discriminación perceptiva',
    'Memoria',
    'Razonamiento y habilidades escolares',
    'Desarrollo conceptual',
    'Total Cognitiva',
    'Puntuación Total',

]

const AdultInteraction = [
    { age: '0-5', item: 'PS 1', behavior: 'Muestra conocimiento de la gente.' },
    { age: '0-5', item: 'PS 2', behavior: 'Mira la cara del adulto.' },
    { age: '0-5', item: 'PS 3', behavior: 'Sonríe o vocaliza en respuesta a la atención del adulto.' },
    { age: '0-5', item: 'PS 4', behavior: 'Explora las facciones del adulto.' },
    { age: '0-5', item: 'PS 5', behavior: 'Muestra deseos de ser cogido en brazos por una persona conocida ' },
    { age: '6-11', item: 'PS 6', behavior: 'Muestra deseos de recibir atención.' },
    { age: '6-11', item: 'PS 7', behavior: 'Participa en juegos como «cucú» o el «el escondite»' },
    { age: '6-11', item: 'PS 8', behavior: 'Distingue las personas conocidas de las no conocidas.' },
    { age: '12-17', item: 'PS 9', behavior: 'Sigue vocalizando cuando se le imita.' },
    { age: '12-17', item: 'PS 10', behavior: 'Reacciona cuando se nombra a un familiar.' },
    { age: '18-23', item: 'PS 11', behavior: 'Responde a las alabanzas, recompensas o promesas de recompensa del adulto.' },
    { age: '18-23', item: 'PS 12', behavior: 'Ayuda en tareas domésticas sencillas.' },
    { age: '24-35', item: 'PS 13', behavior: 'Saluda espóntaneamente a los adultos conocidos.' },
    { age: '36-47', item: 'PS 14', behavior: 'Responde al contacto social de adultos conocidos.' },
    { age: '36-47', item: 'PS 15', behavior: 'Se separa fácilmente de sus padres.' },
    { age: '60-71', item: 'PS 16', behavior: 'Utiliza a los adultos (además de los padres), como recurso.' },
    { age: '60-71', item: 'PS 17', behavior: 'Inicia contactos con adultos conocidos.' },
    { age: '72-83', item: 'PS 18', behavior: 'Pide ayuda al adulto cuando lo necesita.' },
]

const Expression = [
    { age: '0-5', item: 'PS 19', behavior: 'Reacciona con anticipación.' },
    { age: '0-5', item: 'PS 20', behavior: 'Muestra placer en juegos que implican movimientos bruscos.' },
    { age: '0-5', item: 'PS 21', behavior: 'Expresa emociones.' },
    { age: '12-17', item: 'PS 22', behavior: 'Muestra afecto por las personas, animales u objetos personales.' },
    { age: '12-17', item: 'PS 23', behavior: 'Le gusta jugar con otros niños.' },
    { age: '18-23', item: 'PS 24', behavior: 'Le gusta que le lean cuentos.' },
    { age: '24-35', item: 'PS 25', behavior: 'Expresa cariño o simpatía hacia un compañero.' },
    { age: '36-47', item: 'PS 26', behavior: 'Muestra entusiasmo en el trabajo o el juego.' },
    { age: '36-47', item: 'PS 27', behavior: 'Muestra simpatía hacia los demás.' },
    { age: '48-59', item: 'PS 28', behavior: 'Consuela a un compañero.' },
    { age: '48-59', item: 'PS 29', behavior: 'Describe sus sentimientos.' },
    { age: '60-71', item: 'PS 30', behavior: 'Muestra una actitud positiva hacia la escuela.' },
]

const Autoconcept = [
    { age: '0-5', item: 'PS 31', behavior: 'Muestra conocimiento de sus manos.' },
    { age: '6-11', item: 'PS 32', behavior: 'Responde a su nombre.' },
    { age: '18-23', item: 'PS 33', behavior: 'Expresa propiedad o posesión.' },
    { age: '18-23', item: 'PS 34', behavior: 'Se reconoce en el espejo.' },
    { age: '24-35', item: 'PS 35', behavior: 'Se enorgullece de sus éxitos.' },
    { age: '24-35', item: 'PS 36', behavior: 'Conoce su nombre.' },
    { age: '24-35', item: 'PS 37', behavior: 'Utiliza un pronombre o su nombre para referirse a sí mismo.' },
    { age: '24-35', item: 'PS 38', behavior: 'Habla positivamente de sí mismo.' },
    { age: '24-35', item: 'PS 39', behavior: 'Conoce su edad.' },
    { age: '36-47', item: 'PS 40', behavior: 'Atrae la atención de los demás sobre su actividad.' },
    { age: '36-47', item: 'PS 41', behavior: 'Conoce su nombre y apellidos.' },
    { age: '48-59', item: 'PS 42', behavior: 'Se «hace valer» socialmente.' },
    { age: '60-71', item: 'PS 43', behavior: 'Actúa para los demás.' },
    { age: '60-71', item: 'PS 44', behavior: 'Demuestra capacidad para explicar o contar alguna cosa sin demasiada vergüenza.' },
]

const PartnerInteraction = [
    { age: '12-17', item: 'PS 45', behavior: 'Inicia un contacto social con compañeros.' },
    { age: '12-17', item: 'PS 46', behavior: 'Imita a otro niño.' },
    { age: '18-23', item: 'PS 47', behavior: 'Juega solo junto a otros compañeros.' },
    { age: '18-23', item: 'PS 48', behavior: 'Juega al lado de otro niño.' },
    { age: '24-35', item: 'PS 49', behavior: 'Participa en juegos de grupo.' },
    { age: '24-35', item: 'PS 50', behavior: 'Comparte sus juguetes.' },
    { age: '36-47', item: 'PS 51', behavior: 'Se relaciona con los compañeros.' },
    { age: '48-59', item: 'PS 52', behavior: 'Tiene amigos.' },
    { age: '48-59', item: 'PS 53', behavior: 'Escoge a sus amigos.' },
    { age: '48-59', item: 'PS 54', behavior: 'Participa en el juego.' },
    { age: '48-59', item: 'PS 55', behavior: 'Participa en actividades de grupo.' },
    { age: '48-59', item: 'PS 56', behavior: 'Sabe compartir y esperar su turno.' },
    { age: '60-71', item: 'PS 57', behavior: 'Inicia contactos sociales e interacciones.' },
    { age: '60-71', item: 'PS 58', behavior: 'Participa en juegos competitivos.' },
    { age: '60-71', item: 'PS 59', behavior: 'Utiliza a los compañeros para obtener ayuda.' },
    { age: '60-71', item: 'PS 60', behavior: 'Da ideas a otros niños y aprueba las de los demás.' },
    { age: '72-83', item: 'PS 61', behavior: 'Actúa como líder en las relaciones con los compañeros.' },
]

const Collaboration = [
    { age: '18-23', item: 'PS 62', behavior: 'Sigue normas de la vida cotidiana.' },
    { age: '24-35', item: 'PS 63', behavior: 'Sigue las reglas dadas por un adulto.' },
    { age: '48-59', item: 'PS 64', behavior: 'Obedece las órdenes del adulto.' },
    { age: '60-71', item: 'PS 65', behavior: 'Obedece las normas y órdenes de la clase.' },
    { age: '60-71', item: 'PS 66', behavior: 'Espera su turno para conseguir la atención del adulto.' },
    { age: '60-71', item: 'PS 67', behavior: 'Busca alternativas para resolver un problema.' },
    { age: '60-71', item: 'PS 68', behavior: 'Hace frente a las burlas y riñas.' },
    { age: '72-83', item: 'PS 69', behavior: 'Participa en situaciones nuevas.' },
    { age: '84-95', item: 'PS 70', behavior: 'Utiliza al adulto para defenderse.' },
    { age: '84-95', item: 'PS 71', behavior: 'Se enfrenta a la agresión de un compañero.' },
]

const SocialRole = [
    { age: '24-35', item: 'PS 72', behavior: 'Juega representando papeles de adulto.' },
    { age: '24-35', item: 'PS 73', behavior: 'Representa un papel.' },
    { age: '36-47', item: 'PS 74', behavior: 'Sabe si es niño o niña.' },
    { age: '36-47', item: 'PS 75', behavior: 'Reconoce las diferencias entre hombre y mujer.' },
    { age: '48-59', item: 'PS 76', behavior: 'Reconoce expresiones faciales de sentimientos.' },
    { age: '48-59', item: 'PS 77', behavior: 'Juega representando el papel de adulto.' },
    { age: '48-59', item: 'PS 78', behavior: 'Ayuda cuando es necesario.' },
    { age: '48-59', item: 'PS 79', behavior: 'Respeta las cosas de los demás.' },
    { age: '48-59', item: 'PS 80', behavior: 'Pide permiso para utilizar las cosas de otro.' },
    { age: '60-71', item: 'PS 81', behavior: 'Reconoce los sentimientos de los demás.' },
    { age: '60-71', item: 'PS 82', behavior: 'Distingue las conductas aceptables de las no aceptables.' },
    { age: '72-83', item: 'PS 83', behavior: 'Distingue roles presentes y futuros.' },
    { age: '84-95', item: 'PS 84', behavior: 'Demuestra responsabilidad.' },
    { age: '84-95', item: 'PS 85', behavior: 'Reconoce la responsabilidad de sus errores.' },
]

const Attention = [
    { age: '0-5', item: 'A 1', behavior: 'Dirige su mirada hacia un foco de luz.' },
    { age: '0-5', item: 'A 2', behavior: 'Mira un objeto durante cinco segundos.' },
    { age: '0-5', item: 'A 3', behavior: 'Presta atención a un sonido contínuo.' },
    { age: '6-11', item: 'A 4', behavior: 'Sigue con la mirada una luz en un arco de 180°.' },
    { age: '6-11', item: 'A 5', behavior: 'Sigue con la mirada una luz en recorrido vertical.' },
    { age: '6-11', item: 'A 6', behavior: 'Se entretiene sin solicitar atención.' },
    { age: '12-17', item: 'A 7', behavior: 'Mira o señala un dibujo.' },
    { age: '18-23', item: 'A 8', behavior: 'Presta atención.' },
    { age: '36-47', item: 'A 9', behavior: 'Presta atención estando en grupo.' },
    { age: '36-47', item: 'A 10', behavior: 'Se concentra en su propia tarea.' },
]

const Food = [
    { age: '0-5', item: 'A 11', behavior: 'Reacciona anticipadamente a la comida.' },
    { age: '0-5', item: 'A 12', behavior: 'Come papilla con cuchara.' },
    { age: '6-11', item: 'A 13', behavior: 'Come semisólidos.' },
    { age: '6-11', item: 'A 14', behavior: 'Sostiene su biberón.' },
    { age: '6-11', item: 'A 15', behavior: 'Bebe en una taza con ayuda.' },
    { age: '6-11', item: 'A 16', behavior: 'Come trocitos de comida.' },
    { age: '12-17', item: 'A 17', behavior: 'Comienza a usar la cuchara o el tenedor para comer.' },
    { age: '12-17', item: 'A 18', behavior: 'Pide comida o bebida con palabras o gestos.' },
    { age: '18-23', item: 'A 19', behavior: 'Bebe en taza o vaso, sin ayuda.' },
    { age: '18-23', item: 'A 20', behavior: 'Utiliza la cuchara o el tenedor.' },
    { age: '18-23', item: 'A 21', behavior: 'Distingue lo comestible de lo no comestible.' },
    { age: '24-35', item: 'A 22', behavior: 'Obtiene agua del grifo.' },
    { age: '36-47', item: 'A 23', behavior: 'Se sirve comida.' },
    { age: '72-83', item: 'A 24', behavior: 'Utiliza el cuchillo.' },
]

const Attire = [
    { age: '12-17', item: 'A 25', behavior: 'Se quita prendas de ropa pequeñas.' },
    { age: '12-17', item: 'A 26', behavior: 'Ayuda a vestirse.' },
    { age: '18-23', item: 'A 27', behavior: 'Se quita una prenda de ropa.' },
    { age: '24-35', item: 'A 28', behavior: 'Se pone ropa.' },
    { age: '24-35', item: 'A 29', behavior: 'Se pone el abrigo.' },
    { age: '36-47', item: 'A 30', behavior: 'Se desabrocha la ropa.' },
    { age: '36-47', item: 'A 31', behavior: 'Se pone los zapatos.' },
    { age: '36-47', item: 'A 32', behavior: 'Se abrocha uno o dos botones.' },
    { age: '48-59', item: 'A 33', behavior: 'Se viste y se desnuda.' },
    { age: '84-95', item: 'A 34', behavior: 'Elige su ropa adecuadamente.' },
]

const PersonalResponsability = [
    { age: '18-23', item: 'A 35', behavior: 'Se mueve independientemente.' },
    { age: '36-47', item: 'A 36', behavior: 'Evita peligros comunes.' },
    { age: '48-59', item: 'A 37', behavior: 'Sigue instrucciones dadas al grupo.' },
    { age: '48-59', item: 'A 38', behavior: 'Completa tareas de dos acciones.' },
    { age: '48-59', item: 'A 39', behavior: 'Se mueve por su entorno inmediato.' },
    { age: '60-71', item: 'A 40', behavior: 'Continúa una tarea con supervisión mínima.' },
    { age: '60-71', item: 'A 41', behavior: 'Contesta preguntas del tipo: «¿Qué harías si...?»' },
    { age: '60-71', item: 'A 42', behavior: 'Cruza la calle con precaución.' },
    { age: '60-71', item: 'A 43', behavior: 'Organiza sus propias actividades.' },
    { age: '60-71', item: 'A 44', behavior: 'Se acuesta sin ayuda.' },
    { age: '60-71', item: 'A 45', behavior: 'Contesta preguntas del tipo: «¿Qué harías si...?»' },
    { age: '60-71', item: 'A 46', behavior: 'Va al colegio solo.' },
    { age: '72-83', item: 'A 47', behavior: 'Compra en una tienda.' },
    { age: '72-83', item: 'A 48', behavior: 'Maneja pequeñas cantidades de dinero.' },
    { age: '72-83', item: 'A 49', behavior: 'Conoce su dirección. ' },
    { age: '72-83', item: 'A 50', behavior: 'Utiliza el teléfono.' },
    { age: '84-95', item: 'A 51', behavior: 'Realiza tareas domésticas.' },
    { age: '84-95', item: 'A 52', behavior: 'Utiliza herramientas.' },
    { age: '84-95', item: 'A 53', behavior: 'Administra su dinero.' },
]

const Hygiene = [
    { age: '24-35', item: 'A 54', behavior: 'Indica la necesidad de ir al lavado.' },
    { age: '24-35', item: 'A 55', behavior: 'Controla esfínteres.' },
    { age: '36-47', item: 'A 56', behavior: 'Se lava y seca las manos.' },
    { age: '36-47', item: 'A 57', behavior: 'Duerme sin mojar la cama.' },
    { age: '36-47', item: 'A 58', behavior: 'Va al lavado con autonomía.' },
    { age: '72-83', item: 'A 59', behavior: 'Se baña o ducha.' },
]

const MuscularControl = [
    { age: '0-5', item: 'M 1', behavior: 'Mantiene erguida la cabeza.' },
    { age: '0-5', item: 'M 2', behavior: 'Levanta la cabeza.' },
    { age: '0-5', item: 'M 3', behavior: 'Sentado con apoyo gira la cabeza a ambos lados.' },
    { age: '6-11', item: 'M 4', behavior: 'Permanece sentado momentáneamente, sin ayuda.' },
    { age: '6-11', item: 'M 5', behavior: 'Permanece en pie 10 segundos, apoyándose en algo estable.' },
    { age: '12-17', item: 'M 6', behavior: 'Permanece en pie sin ayuda.' },
]

const CorporealCoordination = [
    { age: '0-5', item: 'M 7', behavior: 'Junta las manos en la línea media.' },
    { age: '0-5', item: 'M 8', behavior: 'Se lleva un objeto a la boca.' },
    { age: '6-11', item: 'M 9', behavior: 'Se pone de pie apoyándose en un mueble.' },
    { age: '6-11', item: 'M 10', behavior: 'Se incorpora hasta la posición sentado.' },
    { age: '12-17', item: 'M 11', behavior: 'Camina llevando un objeto.' },
    { age: '12-17', item: 'M 12', behavior: 'Se agacha para coger un objeto.' },
    { age: '18-23', item: 'M 13', behavior: 'Lanza la pelota.' },
    { age: '18-23', item: 'M 14', behavior: 'Chuta la pelota.' },
    { age: '24-35', item: 'M 15', behavior: 'Avanza 2 ó 3 pasos siguiendo una línea.' },
    { age: '24-35', item: 'M 16', behavior: 'Se mantiene sobre un pie.' },
    { age: '24-35', item: 'M 17', behavior: 'Lanza la pelota para que la coja otra persona.' },
    { age: '36-47', item: 'M 18', behavior: 'Da una voltereta.' },
    { age: '48-59', item: 'M 19', behavior: 'Imita posturas con los brazos.' },
    { age: '48-59', item: 'M 20', behavior: 'Salta sobre un pie.' },
    { age: '48-59', item: 'M 21', behavior: 'Anda «punta-tacón».' },
    { age: '48-59', item: 'M 22', behavior: 'Recorre tres metros saltando sobre un pie.' },
    { age: '60-71', item: 'M 23', behavior: 'Coge una pelota.' },
    { age: '60-71', item: 'M 24', behavior: 'Se mantiene sobre un solo pie, alternativamente, con los ojos cerrados.' },
    { age: '60-71', item: 'M 25', behavior: 'Salta hacia adelante con los pies juntos.' },
    { age: '72-83', item: 'M 26', behavior: 'Se inclina y toca el suelo con las manos.' },
    { age: '72-83', item: 'M 27', behavior: 'Andar por una línea «punta-tacón».' },
    { age: '72-83', item: 'M 28', behavior: 'Lanza la pelota a una diana.' },
    { age: '84-95', item: 'M 29', behavior: 'Salta a la cuerda.' },
    { age: '84-95', item: 'M 30', behavior: 'Mantiene el equilibrio en cuclillas con los ojos cerrados.' },
    { age: '84-95', item: 'M 31', behavior: 'Coge la pelota con una mano.' },
]

const Locomotion = [
    { age: '6-11', item: 'M 32', behavior: 'Comienza a dar pasos.' },
    { age: '6-11', item: 'M 33', behavior: 'Gatea.' },
    { age: '6-11', item: 'M 34', behavior: 'Camina con ayuda.' },
    { age: '12-17', item: 'M 35', behavior: 'Sube escaleras gateando.' },
    { age: '12-17', item: 'M 36', behavior: 'Camina sin ayuda.' },
    { age: '12-17', item: 'M 37', behavior: 'Se levanta sin ayuda.' },
    { age: '12-17', item: 'M 38', behavior: 'Sube escaleras con ayuda.' },
    { age: '18-23', item: 'M 39', behavior: 'Baja escaleras con ayuda.' },
    { age: '18-23', item: 'M 40', behavior: 'Corre tres metros sin caerse.' },
    { age: '18-23', item: 'M 41', behavior: 'Sube y baja escaleras sin ayuda, colocando ambos pies en cada escalón.' },
    { age: '24-35', item: 'M 42', behavior: 'Salta con los pies juntos.' },
    { age: '36-47', item: 'M 43', behavior: 'Baja escaleras alternando los pies.' },
    { age: '72-83', item: 'M 44', behavior: 'Brinca alternando los pies.' },
]

const FineMotor = [
    { age: '0-5', item: 'M 45', behavior: 'Mantiene las manos predominantemente abiertas.' },
    { age: '0-5', item: 'M 46', behavior: 'Sostiene un objeto con los dedos y la palma de la mano (presión cúbito-palmar).' },
    { age: '6-11', item: 'M 47', behavior: 'Coge un caramelo con varios dedos en oposición al pulgar (presión digital parcial).' },
    { age: '6-11', item: 'M 48', behavior: 'Se pasa un objeto de una mano a otra.' },
    { age: '6-11', item: 'M 49', behavior: 'Abre cajones o armarios.' },
    { age: '12-17', item: 'M 50', behavior: 'Entrega un juguete.' },
    { age: '12-17', item: 'M 51', behavior: 'Coge un caramelo con los dedos índice y plugar (pinza superior).' },
    { age: '24-35', item: 'M 52', behavior: 'Abre una puerta.' },
    { age: '24-35', item: 'M 53', behavior: 'Ensarta 4 cuentas grandes.' },
    { age: '36-47', item: 'M 54', behavior: 'Pasa páginas de un libro.' },
    { age: '36-47', item: 'M 55', behavior: 'Sujeta el papel mientras dibuja.' },
    { age: '36-47', item: 'M 56', behavior: 'Dobla una hoja de papel por la mitad.' },
    { age: '36-47', item: 'M 57', behavior: 'Corta con tijeras.' },
    { age: '36-47', item: 'M 58', behavior: 'Dobla dos veces un papel.' },
    { age: '48-59', item: 'M 59', behavior: 'Abre un candado con llave.' },
    { age: '60-71', item: 'M 60', behavior: 'Hace una pelota arrugando papel.' },
    { age: '60-71', item: 'M 61', behavior: 'Hace un nudo.' },
    { age: '84-95', item: 'M 62', behavior: 'Se toca con el pulgar las yemas de los dedos de la mano.' },
]

const PerceptiveMotor = [
    { age: '0-5', item: 'M 63', behavior: 'Toca un objeto.' },
    { age: '12-17', item: 'M 64', behavior: 'Mete la pastilla en la botella.' },
    { age: '12-17', item: 'M 65', behavior: 'Construye una torre de 2 bloques.' },
    { age: '18-23', item: 'M 66', behavior: 'Mete anillas en un soporte.' },
    { age: '18-23', item: 'M 67', behavior: 'Saca la pastilla de la botella.' },
    { age: '36-47', item: 'M 68', behavior: 'Copia una linea vertical.' },
    { age: '36-47', item: 'M 69', behavior: 'Copia un círculo.' },
    { age: '48-59', item: 'M 70', behavior: 'Copia una cruz.' },
    { age: '48-59', item: 'M 71', behavior: 'Corta con tijeras siguiendo una línea.' },
    { age: '48-59', item: 'M 72', behavior: 'Copia las letras V, H y T.' },
    { age: '48-59', item: 'M 73', behavior: 'Copia un triángulo.' },
    { age: '60-71', item: 'M 74', behavior: 'Dibuja una persona (incluyendo seis elementos).' },
    { age: '60-71', item: 'M 75', behavior: 'Copia un cuadrado.' },
    { age: '60-71', item: 'M 76', behavior: 'Copia palabras sencillas.' },
    { age: '60-71', item: 'M 77', behavior: 'Copia los números del 1 al 15.' },
    { age: '72-83', item: 'M 78', behavior: 'Copia palabras con letras mayúsculas y minúsculas.' },
    { age: '72-83', item: 'M 79', behavior: 'Copia flechas.' },
    { age: '72-83', item: 'M 80', behavior: 'Copia un rombo.' },
    { age: '84-95', item: 'M 81', behavior: 'Copia un triángulo inscrito en otro triángulo.' },
    { age: '84-95', item: 'M 82', behavior: 'Escribe una frase sencilla en letra cursiva.' },
]

const Receptive = [
    { age: '0-5', item: 'CM 1', behavior: 'Reacciona a un sonido que está fuera de su campo visual.' },
    { age: '0-5', item: 'CM 2', behavior: 'Reacciona a la voz.' },
    { age: '0-5', item: 'CM 3', behavior: 'Vuelve la cabeza hacia un sonido.' },
    { age: '6-11', item: 'CM 4', behavior: 'Reacciona a distintos tonos de voz.' },
    { age: '6-11', item: 'CM 5', behavior: 'Asocia palabras con acciones y objetos.' },
    { age: '12-23', item: 'CM 6', behavior: 'Sigue tres o más órdenes sencillas.' },
    { age: '12-23', item: 'CM 7', behavior: 'Sigue órdenes acompañadas de gestos.' },
    { age: '24-35', item: 'CM 8', behavior: 'Comprende los conceptos «dentro, fuera, encima, delante, detrás, hacia».' },
    { age: '24-35', item: 'CM 9', behavior: 'Comprende formas posesivas sencillas.' },
    { age: '36-47', item: 'CM 10', behavior: 'Comprende los adverbios flojo y fuerte.' },
    { age: '36-47', item: 'CM 11', behavior: 'Sigue órdenes verbales que implican dos acciones.' },
    { age: '36-47', item: 'CM 12', behavior: 'Comprende las expresiones «el más grande», y «el más largo».' },
    { age: '36-47', item: 'CM 13', behavior: 'Responde a preguntas que incluyen las palabras:«qué, quién, dónde y cuándo».' },
    { age: '48-59', item: 'CM 14', behavior: 'Distingue entre palabras reales y otras, fonéticamente similares, que no tienen sentido.' },
    { age: '48-59', item: 'CM 15', behavior: 'Comprende negaciones sencillas.' },
    { age: '48-59', item: 'CM 16', behavior: 'Comprende el plural.' },
    { age: '60-71', item: 'CM 17', behavior: 'Comprende el pasado de los verbos ser y estar.' },
    { age: '60-71', item: 'CM 18', behavior: 'Identifica palabras que riman.' },
    { age: '60-71', item: 'CM 19', behavior: 'Relaciona palabras con imágenes.' },
    { age: '60-71', item: 'CM 20', behavior: 'Recuerda hechos de una historia contada.' },
    { age: '60-71', item: 'CM 21', behavior: 'Comprende el futuro de los verbos ser y estar.' },
    { age: '72-83', item: 'CM 22', behavior: 'Reconoce palabras que no pertenecen a una categoría.' },
    { age: '72-83', item: 'CM 23', behavior: 'Sigue órdenes que implican tres acciones.' },
    { age: '72-83', item: 'CM 24', behavior: 'Identifica el sonido inicial de las palabras.' },
    { age: '84-95', item: 'CM 25', behavior: 'Recuerda hechos de una historia contada.' },
    { age: '84-95', item: 'CM 26', behavior: 'Identifica el sonido inicial de las palabras.' },
    { age: '84-95', item: 'CM 27', behavior: 'Comprende los conceptos: dulce, duro y brillante.' },

]

const Expressive = [
    { age: '0-5', item: 'CM 28', behavior: 'Emite sonidos vocálicos.' },
    { age: '0-5', item: 'CM 29', behavior: 'Emite sonidos para expresar su estado de ánimo.' },
    { age: '6-11', item: 'CM 30', behavior: 'Emite sonidos consonante-vocal.' },
    { age: '6-11', item: 'CM 31', behavior: 'Emite cadenas silábicas.' },
    { age: '12-23', item: 'CM 32', behavior: 'Utiliza gestos indicar sus necesidades.' },
    { age: '12-23', item: 'CM 33', behavior: 'Imita sonidos de palabras.' },
    { age: '12-23', item: 'CM 34', behavior: 'Utiliza diez o más palabras.' },
    { age: '12-23', item: 'CM 35', behavior: 'Utiliza diferentes patrones de entonación.' },
    { age: '12-23', item: 'CM 36', behavior: 'Emite sonidos, palabras o gestos asociados a objetos de su entorno.' },
    { age: '24-35', item: 'CM 37', behavior: 'Utiliza los pronombres «yo», «tú» y «mí».' },
    { age: '24-35', item: 'CM 38', behavior: 'Utiliza expresiones de dos palabras.' },
    { age: '24-35', item: 'CM 39', behavior: 'Utiliza frases de tres palabras.' },
    { age: '36-47', item: 'CM 40', behavior: 'Responde «sí» o «no» adecuadamente.' },
    { age: '36-47', item: 'CM 41', behavior: 'Da nombre a su trabajo creativo.' },
    { age: '36-47', item: 'CM 42', behavior: 'Formula preguntas utilizando las palabras: «qué, quién, dónde, por qué y cómo».' },
    { age: '36-47', item: 'CM 43', behavior: 'Utiliza el plural terminado en «s».' },
    { age: '36-47', item: 'CM 44', behavior: 'Habla sobre sus experiencias.' },
    { age: '36-47', item: 'CM 45', behavior: 'Utiliza los artículos determinados e indeterminados (el, la, un, una).' },
    { age: '48-59', item: 'CM 46', behavior: 'Utiliza el pado en verbos regulares.' },
    { age: '48-59', item: 'CM 47', behavior: 'Repite palabras articulándolas correctamente.' },
    { age: '48-59', item: 'CM 48', behavior: 'Utiliza frases de cinco o seis palabras.' },
    { age: '48-59', item: 'CM 49', behavior: 'Se comunica adecuadamente.' },
    { age: '60-71', item: 'CM 50', behavior: 'Interviene en una conversación.' },
    { age: '60-71', item: 'CM 51', behavior: 'Utiliza el plural terminado en «es».' },
    { age: '60-71', item: 'CM 52', behavior: 'Utilzia el pasado en verbos irregualres.' },
    { age: '60-71', item: 'CM 53', behavior: 'Expresa sus sentimientos.' },
    { age: '60-71', item: 'CM 54', behavior: 'Utiliza el comparativo' },
    { age: '72-83', item: 'CM 55', behavior: 'Asocia una palabra con su definición.' },
    { age: '72-83', item: 'CM 56', behavior: 'Utiliza el superlativo.' },
    { age: '72-83', item: 'CM 57', behavior: 'Habla sobre cosas que pueden suceder.' },
    { age: '84-95', item: 'CM 58', behavior: 'Define palabras.' },
    { age: '84-95', item: 'CM 59', behavior: 'Nombra palabras de diversas categorías.' },
]

const PerceptiveDiscrimination = [
    { age: '0-5', item: 'CG 1', behavior: 'Explora su entorno visualmente.' },
    { age: '0-5', item: 'CG 2', behavior: 'Reacciona ante situaciones nuevas.' },
    { age: '0-5', item: 'CG 3', behavior: 'Explora objetos.' },
    { age: '6-11', item: 'CG 4', behavior: 'Explora o investiga el entorno.' },
    { age: '12-23', item: 'CG 5', behavior: 'Coloca las piezas círculo y cuadrado en el tablero de encajes.' },
    { age: '24-35', item: 'CG 6', behavior: 'Empareja formas geométricas sencillas.' },
    { age: '24-35', item: 'CG 7', behavior: 'Empareja un círculo, un cuadrado y un triángulo.' },
    { age: '36-47', item: 'CG 8', behavior: 'Identifica objetos sencillos por el tacto.' },
    { age: '60-71', item: 'CG 9', behavior: 'Empareja palabras sencillas.' },
    { age: '72-83', item: 'CG 10', behavior: 'Reconoce diferencias visuales entre números, formas geométricas y letras similares.' },
]

const Memory = [
    { age: '0-5', item: 'CG 11', behavior: 'Sigue un estímulo auditivo.' },
    { age: '0-5', item: 'CG 12', behavior: 'Sigue un estímulo visual.' },
    { age: '6-11', item: 'CG 13', behavior: 'Levanta una taza para conseguir un juguete.' },
    { age: '6-11', item: 'CG 14', behavior: 'Busca un objeto desaparecido.' },
    { age: '24-35', item: 'CG 15', behavior: 'Repite secuencias de dos dígitos.' },
    { age: '24-35', item: 'CG 16', behavior: 'Elige la mano que esconde el juguete.' },
    { age: '36-47', item: 'CG 17', behavior: 'Recuerda objetos familiares.' },
    { age: '72-83', item: 'CG 18', behavior: 'Repite secuencias de cuatro dígitos.' },
    { age: '72-83', item: 'CG 19', behavior: 'Recuerda hechos de una historia contada.' },
    { age: '84-95', item: 'CG 20', behavior: 'Repite secuencias de seis dígitos.' },
]

const Reasoning = [
    { age: '6-11', item: 'CG 21', behavior: 'Tira de una cuerda para obtener un juguete.' },
    { age: '12-23', item: 'CG 22', behavior: 'Extiende los brazos para obtener un juguete colocado detrás de una barrera.' },
    { age: '36-47', item: 'CG 23', behavior: 'Responde a una orden dos veces consecutivas.' },
    { age: '48-59', item: 'CG 24', behavior: 'Identifica quien realiza algunas actividades conocidas.' },
    { age: '48-59', item: 'CG 25', behavior: 'Entrega tres objetos por indicación.' },
    { age: '48-59', item: 'CG 26', behavior: 'Responde a preguntas lógicas sencillas.' },
    { age: '48-59', item: 'CG 27', behavior: 'Completa analogías opuestas.' },
    { age: '48-59', item: 'CG 28', behavior: 'Identifica el mayor de dos números.' },
    { age: '60-71', item: 'CG 29', behavior: 'Selecciona palabras sencillas presentadas visualmente.' },
    { age: '60-71', item: 'CG 30', behavior: 'Identifica las partes incompletas de un dibujo.' },
    { age: '60-71', item: 'CG 31', behavior: 'Reconoce errores en dibujos absurdos.' },
    { age: '72-83', item: 'CG 32', behavior: 'Escribe letras que representan sonidos.' },
    { age: '72-83', item: 'CG 33', behavior: 'Ordena en secuencia historias corrientes.' },
    { age: '72-83', item: 'CG 34', behavior: 'Resueve sumas y restas sencillas (números del 0 al 5).' },
    { age: '84-95', item: 'CG 35', behavior: 'Resuelve problemas sencillos, presentados oralmente, que incluyen la sustracción.' },
    { age: '84-95', item: 'CG 36', behavior: 'Resuelve multiplicaciones sencillas.' },
]

const ConceptualDevelopment = [
    { age: '12-23', item: 'CG 37', behavior: 'Se reconoce a sí mismo como causa de acontecimientos.' },
    { age: '24-35', item: 'CG 38', behavior: 'Identifica objetos familiares por su uso.' },
    { age: '36-47', item: 'CG 39', behavior: 'Identifica los tamaños grande y pequeño.' },
    { age: '48-59', item: 'CG 40', behavior: 'Identifica el más largo de dos palos.' },
    { age: '48-59', item: 'CG 41', behavior: 'Clasifica objetos por su forma.' },
    { age: '48-59', item: 'CG 42', behavior: 'Compara tamaños.' },
    { age: '60-71', item: 'CG 43', behavior: 'Identifica texturas (suabe, lisa, rugosa).' },
    { age: '60-71', item: 'CG 44', behavior: 'Identifica actividades presentes y pasadas.' },
    { age: '60-71', item: 'CG 45', behavior: 'Identifica colores.' },
    { age: '60-71', item: 'CG 46', behavior: 'Forma un círculo con cuatro piezas.' },
    { age: '60-71', item: 'CG 47', behavior: 'Clasifica objetos por su función.' },
    { age: '60-71', item: 'CG 48', behavior: 'Ordena cuadrados de menor a mayor.' },
    { age: '60-71', item: 'CG 49', behavior: 'Identifica los objetos primero y último de una fila.' },
    { age: '60-71', item: 'CG 50', behavior: 'Completa un puzzle de seis piezas que representa una persona.' },
    { age: '72-83', item: 'CG 51', behavior: 'Conoce los lados derecho e izquierdo de su cuerpo.' },
    { age: '72-83', item: 'CG 52', behavior: 'Identifica el objeto central de una fila.' },
    { age: '84-95', item: 'CG 53', behavior: 'Dice las horas y las medias horas.' },
    { age: '84-95', item: 'CG 54', behavior: 'Clasifica objetos por su forma y color.' },
    { age: '84-95', item: 'CG 55', behavior: 'Conserva el espacio bidimensional.' },
    { age: '84-95', item: 'CG 56', behavior: 'Conserva la longitud.' },
]


const PersonalTab = function (props) {

    return (
        <>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Interacción con el adulto" />
            <ul className="mt-4 divide-y divide-gray-200">
                {
                    AdultInteraction.map((item, idx) => {
                        return (
                            <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                        )
                    })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Expresión de sentimientos/afecto" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Expression.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Autoconcepto" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Autoconcept.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Interacción con los compañeros" />
            <ul className="mt-4 divide-y divide-gray-200">
                {PartnerInteraction.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Colaboración " />
            <ul className="mt-4 divide-y divide-gray-200">
                {Collaboration.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Rol Social" />
            <ul className="mt-4 divide-y divide-gray-200">
                {SocialRole.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
        </>
    )
}

const AdaptiveTab = function (props) {

    return (
        <>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Atención" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Attention.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Comida" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Food.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Vestido" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Attire.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Responsabilidad Personal" />
            <ul className="mt-4 divide-y divide-gray-200">
                {PersonalResponsability.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Aseo" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Hygiene.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
        </>
    )
}

const MotorTab = function (props) {

    return (
        <>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Control muscular" />
            <ul className="mt-4 divide-y divide-gray-200">
                {MuscularControl.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Coordinación corporal" />
            <ul className="mt-4 divide-y divide-gray-200">
                {CorporealCoordination.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Locomoción" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Locomotion.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Motricidad fina" />
            <ul className="mt-4 divide-y divide-gray-200">
                {FineMotor.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Motricidad perceptiva" />
            <ul className="mt-4 divide-y divide-gray-200">
                {PerceptiveMotor.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
        </>
    )
}

const CommunicationTab = function (props) {

    return (
        <>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Receptiva" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Receptive.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Expresiva" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Expressive.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
        </>
    )
}

const CognitiveTab = function (props) {

    return (
        <>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Discriminación perceptiva" />
            <ul className="mt-4 divide-y divide-gray-200">
                {PerceptiveDiscrimination.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Memoria" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Memory.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Razonamiento y habilidades escolares" />
            <ul className="mt-4 divide-y divide-gray-200">
                {Reasoning.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
            <Divider className="pr-3 bg-white text-lg font-semibold text-gray-900" title="Desarrollo conceptual" />
            <ul className="mt-4 divide-y divide-gray-200">
                {ConceptualDevelopment.map((item, idx) => {
                    return (
                        <BattelleItem key={idx} defaultObservation={props.answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={props.answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={props.handleChange} age={item.age} item={item.item} behavior={item.behavior} />
                    )
                })}
            </ul>
        </>
    )
}

const InventoryGraphic = function (props) {

    const [inventoryHoveredValue, setInventoryHoveredValue] = useState(null)
    const [data, setData] = useState(
        [{
            x: props.results.interactionWithPeersTypicalTScore,
            y: 30
        },
        {
            x: props.results.feelingsExpressionTypicalTScore,
            y: 29
        },
        {
            x: props.results.selfconceptTypicalTScore,
            y: 28
        },
        {
            x: props.results.interactionWithPeersTypicalTScore,
            y: 27
        },
        {
            x: props.results.collaborationTypicalTScore,
            y: 26
        },
        {
            x: props.results.socialRoleTypicalTScore,
            y: 25
        },
        {
            x: props.results.personalSocialAreaTotalTypicalTScore,
            y: 24
        },
        {
            x: props.results.attentionTypicalTScore,
            y: 23
        },
        {
            x: props.results.mealTypicalTScore,
            y: 22
        },
        {
            x: props.results.clothesTypicalTScore,
            y: 21
        },
        {
            x: props.results.personalResponsibilityTypicalTScore,
            y: 20
        },
        {
            x: props.results.cleanlinessTypicalTScore,
            y: 19
        },
        {
            x: props.results.adaptiveAreaTotalTypicalTScore,
            y: 18
        },
        {
            x: props.results.muscleControlTypicalTScore,
            y: 17
        },
        {
            x: props.results.bodyCoordinationTypicalTScore,
            y: 16
        },
        {
            x: props.results.locomotionTypicalTScore,
            y: 15
        },
        {
            x: props.results.thickMotorSubtotalTypicalTScore,
            y: 14
        },
        {
            x: props.results.fineMotorSkillsTypicalTScore,
            y: 13
        },
        {
            x: props.results.perceptualMotorSkillsTypicalTScore,
            y: 12
        },
        {
            x: props.results.thinMotorSubtotalTypicalTScore,
            y: 11
        },
        {
            x: props.results.motorAreaTotalTypicalTScore,
            y: 10
        },
        {
            x: props.results.receptiveTypicalTScore,
            y: 9
        },
        {
            x: props.results.expressiveTypicalTScore,
            y: 8
        },
        {
            x: props.results.communicationAreaTotalTypicalTScore,
            y: 7
        },
        {
            x: props.results.perceptualDiscriminationTypicalTScore,
            y: 6
        },
        {
            x: props.results.memoryTypicalTScore,
            y: 5
        },
        {
            x: props.results.reasoningTypicalTScore,
            y: 4
        },
        {
            x: props.results.conceptDevelopmentTypicalTScore,
            y: 3
        },
        {
            x: props.results.cognitiveAreaTotalTypicalTScore,
            y: 2
        },
        {
            x: props.results.totalTypicalTScore,
            y: 1
        },
        ]
    )

    return <XYPlot
        margin={{ top: 20 }}
        width={props.width}
        height={props.height}
        yDomain={[1, 30]}
        xDomain={[0, 100]}
        onMouseLeave={() => setInventoryHoveredValue(null)}
    >
        <VerticalGridLines tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
        <HorizontalGridLines tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]} />
        <AreaSeries
            color="red"
            opacity={0.1}
            data={[
                {
                    x: 35,
                    y: 1,
                    y0: 30
                },
                {
                    x: 65,
                    y: 1,
                    y0: 30
                }
            ]}
        />
        <XAxis top={-10} tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
        <LineMarkSeries
            onValueMouseOut={() => setInventoryHoveredValue(null)}
            onValueMouseOver={(value) => setInventoryHoveredValue(value)}
            data={data}
        />
        {inventoryHoveredValue && <Hint value={inventoryHoveredValue}>
            <div className='px-4 py-3 text-xs text-white bg-gray-700 rounded-md shadow-md space-y-1 whitespace-nowrap'>
                <div>{InventoryAxis[30 - inventoryHoveredValue.y]}</div>
                <div>Puntaje: {inventoryHoveredValue.x}</div>
            </div>
        </Hint>}
    </XYPlot>
}

export default function BattelleInventoryPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const today = new Date().toISOString().substring(0, 10)
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [flag, setFlag] = useState(false);
    const { idPatient } = useParams()
    const navigate = useNavigate();
    const [patient, setPatient] = useState({});
    const [inventory, setInventory] = useState({
        kid: idPatient,
        answers: [],
        date: today,
    })
    const [results, setResults] = useState([]);
    const [tab, setTab] = useState('Personal/social')
    const [answers, setAnswers] = useState([])
    const handleAnswer = (item, field, value) => {
        let obj = answers;
        if (obj.filter((answer) => answer.question === item).length !== 0) {
            obj[obj.findIndex((answer) => answer.question === item)][field] = value;
            setAnswers(obj);
        } else {
            let index = obj.push({
                question: item,
                score: 0,
                obs: ''
            });
            obj[index - 1][field] = value;
            setAnswers(obj);
        }
    };
    const handleChange = (field, value) => { setResults((prev) => ({ ...prev, [field]: value })) };

    const [tabs, setTabs] = useState([{ name: 'Personal/social', current: true },
    { name: 'Adaptativa', current: false },
    { name: 'Motora', current: false },
    { name: 'Comunicación', current: false },
    { name: 'Cognitiva', current: false }
    ])

    useEffect(() => {
        setLoading(true)
        api.patients.getOne(idPatient).then((response) => {
            setPatient(response);
        }).catch((error) => console.log(error))
        api.inventory.getMulti(null, null, null, null, { kid: idPatient }).then((response) => {
            if (response.length !== 0) {
                setResults(response[response.length - 1])
                setFlag(true);
            } else {
                setTabs([{ name: 'Personal/social', current: true },
                { name: 'Adaptativa', current: false },
                { name: 'Motora', current: false },
                { name: 'Comunicación', current: false },
                { name: 'Cognitiva', current: false }
                ])
                setTab('Personal/social')
            }
            setLoading(false);
        }).catch((error) => console.log(error))
    }, [])

    useEffect(() => {
        setTabs([
            { name: 'Resumen', current: true },
            { name: 'Observaciones', current: false },
            { name: 'Recomendaciones', current: false },
        ])
        setTab('Resumen')
    }, [flag])

    useEffect(() => {
        setInventory((prev) => ({ ...prev, answers: answers }))
    }, [answers])

    const handleSubmit = () => {
        setButtonLoading(true)
        api.inventory.create(inventory).then((response) => {
            setResults(response);
            setFlag(!flag);
            setButtonLoading(false);
        })
            .catch((e) => {
                setButtonLoading(false);
                return Promise.reject(e.detail);
            });
    }

    const handleUpdate = () => {
        setButtonLoading(true);
        api.inventory.update(results.id, results).then((response) => {
            setResults(response);
            setButtonLoading(false);
            navigate(`/patients/${idPatient}`)
        }).catch((e) => {
            return Promise.reject(e.detail);
        })
    }

    const resetTest = () => {
        setTabs([{ name: 'Personal/social', current: true },
        { name: 'Adaptativa', current: false },
        { name: 'Motora', current: false },
        { name: 'Comunicación', current: false },
        { name: 'Cognitiva', current: false }
        ])
        setTab('Personal/social')
        setAnswers([]);
        setResults([]);
    }

    const pages = [
        { name: 'Niños usuarios', href: '/patients', current: false },
        { name: `${patient.fullName} | ${patient.age} meses | ${status[patient.status]}`, href: `/patients/${idPatient}`, current: false },
        { name: 'Registro de Battelle Inventario', href: '#', current: true }
    ]

    return (
        <div className="bg-gray-100 min-h-screen h-full">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="flex min-h-screen h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                </div> :
                <div className="md:pl-64 flex flex-col flex-1">
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="flex flex-col flex-1 ml-6 mt-6">
                        <div className="flex justify-between">

                            <Breadcrumbs pages={pages} />
                            {results.length !== 0 ?
                                <div>
                                    <button onClick={() => resetTest()} className="mr-4 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm">
                                        Volver a tomar test
                                    </button>
                                    <button onClick={() => handleUpdate()} className="inline-flex mr-10 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                                        Guardar
                                    </button>
                                </div>
                                : <button onClick={() => handleSubmit()} className="inline-flex mr-10 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                                    {buttonLoading ?
                                        <div className="px-10">
                                            <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                                        </div>
                                        :
                                        <>Finalizar</>
                                    }
                                </button>}
                        </div>
                        <div className="mt-4 ml-4">
                            <Tabs tabs={tabs} setTab={setTab} />
                        </div>
                    </div>
                    <div className="flex">
                        <main className={classNames(tab === "Observaciones" ? "w-full" : "", "bg-white max-w-5xl px-4 ml-10 w-full")}>
                            <h1 className="mt-10 text-lg font-semibold leading-5 text-gray-900">Registro de Battelle Inventario</h1>
                            <ul className="mt-4 divide-y divide-gray-200">
                                {tab === 'Observaciones' &&
                                    <>
                                        <h1 className="mt-10 text-lg font-semibold leading-5 text-gray-900">Observaciones clínicas</h1>
                                        <li key={1} className="flex flex-col font-bold leading-7 text-gray-700 py-4">
                                            <p>Área personal/social</p>
                                            <Textarea defaultValue={results.clinicalObservationsPersonalSocialArea} handleChange={handleChange} name="clinicalObservationsPersonalSocialArea" containerClassName="w-2/3" />
                                        </li>
                                        <li key={2} className="flex flex-col font-bold leading-7 text-gray-700 py-4">
                                            <p>Área adaptativa</p>
                                            <Textarea defaultValue={results.clinicalObservationsAdaptiveArea} handleChange={handleChange} name="clinicalObservationsAdaptiveArea" containerClassName="w-2/3" />
                                        </li>
                                        <li key={3} className="flex flex-col font-bold leading-7 text-gray-700 py-4">
                                            <p>Área motora</p>
                                            <Textarea defaultValue={results.clinicalObservationsMotorArea} handleChange={handleChange} name="clinicalObservationsMotorArea" containerClassName="w-2/3" />
                                        </li>
                                        <li key={4} className="flex flex-col font-bold leading-7 text-gray-700 py-4">
                                            <p>Área comunicación</p>
                                            <Textarea defaultValue={results.clinicalObservationsCommunicationArea} handleChange={handleChange} name="clinicalObservationsCommunicationArea" containerClassName="w-2/3" />
                                        </li>
                                        <li key={5} className="flex flex-col font-bold leading-7 text-gray-700 py-4">
                                            <p>Área cognitiva</p>
                                            <Textarea defaultValue={results.clinicalObservationsCognitiveArea} handleChange={handleChange} name="clinicalObservationsCognitiveArea" containerClassName="w-2/3" />
                                        </li>
                                        <li key={6} className="flex flex-col font-bold leading-7 text-gray-700 py-4">
                                            <p>Comentarios generales</p>
                                            <Textarea defaultValue={results.clinicalObservationsGeneral} handleChange={handleChange} name="clinicalObservationsGeneral" containerClassName="w-2/3" />
                                        </li>
                                    </>
                                }
                                {tab === 'Recomendaciones' &&
                                    <>
                                        <h1 className="mt-10 text-lg font-semibold leading-5 text-gray-900">Resumen y recomendaciones</h1>
                                        <li key={1} className="grid grid-cols-7 gap-24 font-bold leading-7 text-gray-700 py-4">
                                            <p>Áreas</p>
                                            <p className="col-span-2">Puntos fuertes</p>
                                            <p className="col-span-2">Puntos débiles</p>
                                            <p className="col-span-2">Recomendaciones</p>
                                        </li>
                                        <li key={2} className="grid grid-cols-7 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                                            <p>Personal social</p>
                                            <Textarea defaultValue={results.personalSocialAreaStrengths} handleChange={handleChange} name="personalSocialAreaStrengths" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.personalSocialAreaWeaknesses} handleChange={handleChange} name="personalSocialAreaWeaknesses" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.personalSocialAreaRecommendations} handleChange={handleChange} name="personalSocialAreaRecommendations" containerClassName="col-span-2" />
                                        </li>
                                        <li key={3} className="grid grid-cols-7 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                                            <p>Área adaptativa</p>
                                            <Textarea defaultValue={results.adaptiveAreaStrengths} handleChange={handleChange} name="adaptiveAreaStrengths" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.adaptiveAreaWeaknesses} handleChange={handleChange} name="adaptiveAreaWeaknesses" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.adaptiveAreaRecommendations} handleChange={handleChange} name="adaptiveAreaRecommendations" containerClassName="col-span-2" />
                                        </li>
                                        <li key={4} className="grid grid-cols-7 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                                            <p>Área motora</p>
                                            <Textarea defaultValue={results.motorAreaStrengths} handleChange={handleChange} name="motorAreaStrengths" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.motorAreaWeaknesses} handleChange={handleChange} name="motorAreaWeaknesses" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.motorAreaRecommendations} handleChange={handleChange} name="motorAreaRecommendations" containerClassName="col-span-2" />
                                        </li>
                                        <li key={5} className="grid grid-cols-7 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                                            <p>Área comunicación</p>
                                            <Textarea defaultValue={results.communicationAreaStrengths} handleChange={handleChange} name="communicationAreaStrengths" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.communicationAreaWeaknesses} handleChange={handleChange} name="communicationAreaWeaknesses" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.communicationAreaRecommendations} handleChange={handleChange} name="communicationAreaRecommendations" containerClassName="col-span-2" />
                                        </li>
                                        <li key={6} className="grid grid-cols-7 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                                            <p>Área cognitiva</p>
                                            <Textarea defaultValue={results.cognitiveAreaStrengths} handleChange={handleChange} name="cognitiveAreaStrengths" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.cognitiveAreaWeaknesses} handleChange={handleChange} name="cognitiveAreaWeaknesses" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.cognitiveAreaRecommendations} handleChange={handleChange} name="cognitiveAreaRecommendations" containerClassName="col-span-2" />
                                        </li>
                                        <li key={7} className="grid grid-cols-7 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
                                            <p>Desarrollo global</p>
                                            <Textarea defaultValue={results.generalStrengths} handleChange={handleChange} name="generalStrengths" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.generalWeaknesses} handleChange={handleChange} name="generalWeaknesses" containerClassName="col-span-2" />
                                            <Textarea defaultValue={results.generalRecommendations} handleChange={handleChange} name="generalRecommendations" containerClassName="col-span-2" />
                                        </li>
                                    </>
                                }
                                {tab.match(/^(Personal\/social | Adaptativa | Motora | Comunicación | Cognitiva)$/) &&
                                    <li key={1} className="grid grid-cols-5 gap-24 font-bold leading-7 text-gray-700 py-4">
                                        <p>Edad</p>
                                        <p>Item</p>
                                        <p>Conducta</p>
                                        <p>Puntuación</p>
                                        <p>Obs.</p>
                                    </li>}
                                {tab === 'Resumen' &&
                                    <>
                                        <li key={1} className="grid grid-cols-5 gap-x-20 font-bold leading-7 text-gray-700 py-4">
                                            <p>Sub-área</p>
                                            <p>Puntuación directa</p>
                                            <p>Puntuación centil</p>
                                            <p>Puntuación típica</p>
                                            <p>Edad equivalente (meses)</p>
                                        </li>
                                        <BattelleArea key={2} name="Interacción con el adulto" directScore={results.interactionWithTheAdultDirectScore} percentilScore={results.interactionWithTheAdultPercentilScore} typicalTScore={results.interactionWithTheAdultTypicalTScore} />
                                        <BattelleArea key={3} name="Expresión de sentimientos/afecto" directScore={results.feelingsExpressionDirectScore} percentilScore={results.feelingsExpressionPercentilScore} typicalTScore={results.feelingsExpressionTypicalTScore} />
                                        <BattelleArea key={4} name="Autoconcepto" directScore={results.selfconceptDirectScore} percentilScore={results.selfconceptPercentilScore} typicalTScore={results.selfconceptTypicalTScore} />
                                        <BattelleArea key={5} name="Interacción con los compañeros" directScore={results.interactionWithPeersDirectScore} percentilScore={results.interactionWithPeersPercentilScore} typicalTScore={results.interactionWithPeersTypicalTScore} />
                                        <BattelleArea key={6} name="Colaboración" directScore={results.collaborationDirectScore} percentilScore={results.collaborationPercentilScore} typicalTScore={results.collaborationTypicalTScore} />
                                        <BattelleArea key={7} name="Rol social" directScore={results.socialRoleDirectScore} percentilScore={results.socialRolePercentilScore} typicalTScore={results.socialRoleTypicalTScore} />
                                        <BattelleArea highlight={true} key={8} name="TOTAL PERSONAL/SOCIAL" directScore={results.personalSocialAreaTotalDirectScore} percentilScore={results.personalSocialAreaTotalPercentileScore} typicalTScore={results.personalSocialAreaTotalTypicalTScore} equivalentAge={results.personalSocialAreaEquivalentAge} />
                                        <BattelleArea key={9} name="Atención" directScore={results.attentionDirectScore} percentilScore={results.attentionPercentilScore} typicalTScore={results.attentionTypicalTScore} />
                                        <BattelleArea key={10} name="Comida" directScore={results.mealDirectScore} percentilScore={results.mealPercentilScore} typicalTScore={results.mealTypicalTScore} />
                                        <BattelleArea key={11} name="Vestido" directScore={results.clothesDirectScore} percentilScore={results.clothesPercentilScore} typicalTScore={results.clothesTypicalTScore} />
                                        <BattelleArea key={12} name="Responsabilidad personal" directScore={results.personalResponsibilityDirectScore} percentilScore={results.personalResponsibilityPercentilScore} typicalTScore={results.personalResponsibilityTypicalTScore} />
                                        <BattelleArea key={13} name="Aseo" directScore={results.cleanlinessDirectScore} percentilScore={results.cleanlinessPercentilScore} typicalTScore={results.cleanlinessTypicalTScore} />
                                        <BattelleArea highlight={true} key={14} name="TOTAL ADAPTATIVA" directScore={results.adaptiveAreaTotalDirectScore} percentilScore={results.adaptiveAreaTotalPercentileScore} typicalTScore={results.adaptiveAreaTotalTypicalTScore} equivalentAge={results.adaptiveAreaEquivalentAge} />
                                        <BattelleArea key={15} name="Control muscular" directScore={results.muscleControlDirectScore} percentilScore={results.muscleControlPercentilScore} typicalTScore={results.muscleControlTypicalTScore} />
                                        <BattelleArea key={16} name="Coordinación corporal" directScore={results.bodyCoordinationDirectScore} percentilScore={results.bodyCoordinationPercentilScore} typicalTScore={results.bodyCoordinationTypicalTScore} />
                                        <BattelleArea key={17} name="Locomoción" directScore={results.locomotionDirectScore} percentilScore={results.locomotionPercentilScore} typicalTScore={results.locomotionTypicalTScore} />
                                        <BattelleArea key={18} name="Puntuación Motora gruesa" directScore={results.thickMotorSubtotalDirectScore} percentilScore={results.thickMotorSubtotalPercentilScore} typicalTScore={results.thickMotorSubtotalTypicalTScore} />
                                        <BattelleArea key={19} name="Motricidad fina" directScore={results.fineMotorSkillsDirectScore} percentilScore={results.fineMotorSkillsPercentilScore} typicalTScore={results.fineMotorSkillsTypicalTScore} />
                                        <BattelleArea key={20} name="Motricidad perceptiva" directScore={results.perceptualMotorSkillsDirectScore} percentilScore={results.perceptualMotorSkillsPercentilScore} typicalTScore={results.perceptualMotorSkillsTypicalTScore} />
                                        <BattelleArea key={21} name="Puntuación Motora fina" directScore={results.thinMotorSubtotalDirectScore} percentilScore={results.thinMotorSubtotalPercentilScore} typicalTScore={results.thinMotorSubtotalTypicalTScore} />
                                        <BattelleArea highlight={true} key={22} name="TOTAL MOTORA" directScore={results.motorAreaTotalDirectScore} percentilScore={results.motorAreaTotalPercentileScore} typicalTScore={results.motorAreaTotalTypicalTScore} equivalentAge={results.motorAreaEquivalentAge} />
                                        <BattelleArea key={23} name="Receptiva" directScore={results.receptiveDirectScore} percentilScore={results.receptivePercentilScore} typicalTScore={results.receptiveTypicalTScore} />
                                        <BattelleArea key={24} name="Expresiva" directScore={results.expressiveDirectScore} percentilScore={results.expressivePercentilScore} typicalTScore={results.expressiveTypicalTScore} />
                                        <BattelleArea highlight={true} key={25} name="TOTAL COMUNICACIÓN" directScore={results.communicationAreaTotalDirectScore} percentilScore={results.communicationAreaTotalPercentileScore} typicalTScore={results.communicationAreaTotalTypicalTScore} equivalentAge={results.communicationAreaEquivalentAge} />
                                        <BattelleArea key={26} name="Discriminación perceptiva" directScore={results.perceptualDiscriminationDirectScore} percentilScore={results.perceptualDiscriminationPercentilScore} typicalTScore={results.perceptualDiscriminationTypicalTScore} />
                                        <BattelleArea key={27} name="Memoria" directScore={results.memoryDirectScore} percentilScore={results.memoryPercentilScore} typicalTScore={results.memoryTypicalTScore} />
                                        <BattelleArea key={28} name="Razonamiento y habilidades escolares" directScore={results.reasoningDirectScore} percentilScore={results.reasoningPercentilScore} typicalTScore={results.reasoningTypicalTScore} />
                                        <BattelleArea key={29} name="Desarrollo conceptual" directScore={results.conceptDevelopmentDirectScore} percentilScore={results.conceptDevelopmentPercentilScore} typicalTScore={results.conceptDevelopmentTypicalTScore} />
                                        <BattelleArea highlight={true} key={30} name="TOTAL COGNITIVA" directScore={results.cognitiveAreaTotalDirectScore} percentilScore={results.cognitiveAreaTotalPercentileScore} typicalTScore={results.cognitiveAreaTotalTypicalTScore} equivalentAge={results.cognitiveAreaEquivalentAge} />
                                        <BattelleArea highlight={true} key={31} name="PUNTUACIÓN TOTAL" directScore={results.totalDirectScore} percentilScore={results.totalPercentileScore} typicalTScore={results.totalTypicalTScore} equivalentAge={results.equivalentAge} />
                                    </>
                                }
                            </ul>
                            {
                                <>
                                    {tab === 'Personal/social' && <PersonalTab answers={answers} handleChange={handleAnswer} />}
                                    {tab === 'Adaptativa' && <AdaptiveTab answers={answers} handleChange={handleAnswer} />}
                                    {tab === 'Motora' && <MotorTab answers={answers} handleChange={handleAnswer} />}
                                    {tab === 'Comunicación' && <CommunicationTab answers={answers} handleChange={handleAnswer} />}
                                    {tab === 'Cognitiva' && <CognitiveTab answers={answers} handleChange={handleAnswer} />}
                                </>
                            }
                        </main>
                        {tab === 'Resumen' ? <div className="bg-white pt-56 w-1/2">
                            <InventoryGraphic results={results} width={240} height={2550} />
                        </div> : null}
                    </div>
                </div>
            }
        </div >
    );
}
