import React from "react";
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import {
    MenuAlt2Icon,
} from '@heroicons/react/outline';
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import SelectInput from "../../components/SelectInput";
import api from "../../api";
import Textarea from "../../components/Textarea";
import TextInput from "../../components/TextInput";
import InlineCheckbox from "../../components/InlineCheckbox";
import FileThumbnail from "../../components/FileThumbnail";

const status = [
    "Pendiente",
    "Admitido",
    "No admitido",
    "Alta médica"
]

export default function DischargePage() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false);
    const navigate = useNavigate();
    const { idPatient } = useParams();
    const [patient, setPatient] = useState([]);
    const [discharge, setDischarge] = useState({
        resume: '',
        reason: '',
        ageInMonths: 6,
        completedProgram: false,
        derivationObs: '',
        kid: idPatient
    });
    const [documents, setDocuments] = useState([]);
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = (field, value) => setDischarge((prev) => ({ ...prev, [field]: value }));
    const handleDocument = event => {
        const fileUploaded = event.target.files[0];
        setDocuments((prev) => [...prev, fileUploaded]);
    };
    const deleteDocument = (index) => {
        let obj = documents.filter((element, idx) => idx !== index);
        setDocuments(obj);
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setButtonLoading(false);
        api.discharge.create(discharge).then((response) => {
            return response
        }).then((resp) => {
            documents.map((document) => {
                let data = new FormData;
                data.append('title', document.name)
                data.append('medicalDischarge', parseInt(resp.id))
                data.append('document', document)
                api.dischargeDocs.createData(data).then(response => {
                }).catch((e) => {
                    setButtonLoading(false)
                    return Promise.reject(e.detail)
                });
            })
        }).then(() => {
            setButtonLoading(false);
            navigate(`/patients/${idPatient}`)
        })
            .catch((e) => {
                setButtonLoading(false)
                return Promise.reject(e.detail)
            });
    }

    useEffect(() => {
        api.patients.getOne(idPatient).then((response) => {
            setPatient(response);
            if (response.status === 3) {
                api.discharge.getOne(idPatient).then((response) => {
                    setDischarge(response);
                })
            }
        }).catch((error) => console.log(error))
    }, [])

    const pages = [
        { name: 'Niños usuarios', href: '/patients', current: false },
        { name: `${patient.fullName} | ${patient.age} meses | ${status[patient.status]}`, href: `/patients/${idPatient}`, current: false },
        { name: 'Registro de actividades', href: '#', current: true }
    ]

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ?
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <>
                        <form onSubmit={handleSubmit} className="flex-1 mt-4 bg-gray-100">
                            <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                                <button
                                    type="button"
                                    className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                    onClick={() => setSidebarOpen(true)}
                                >
                                    <span className="sr-only">Open sidebar</span>
                                    <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="flex justify-between">
                                <Breadcrumbs pages={pages} />
                                <button type="submit" className="inline-flex mr-10 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                                    Finalizar
                                </button>
                            </div>
                            <div className="px-4 py-4 sm:px-6 md:px-8">
                                <h1 className="mt-4 text-xl leading-6 font-semibold text-gray-900">Ficha de alta</h1>
                                <div className="flex mt-6 gap-x-6">
                                    <div className="w-1/2">
                                        <p className="text-md leading-6 font-semibold text-indigo-700">Resumen del alta</p>
                                        <Textarea disabled={patient.status === 3 ? true : false} defaultValue={patient.status === 3 ? discharge.resume : ''} handleChange={handleChange} rows={4} name="resume" placeholder="Resumen" />
                                    </div>
                                    <div className="w-1/2">
                                        <p className="text-md leading-6 font-semibold text-indigo-700">Motivo del alta</p>
                                        <Textarea disabled={patient.status === 3 ? true : false} defaultValue={patient.status === 3 ? discharge.reason : ''} handleChange={handleChange} rows={4} name="reason" placeholder="Observaciones acerca del alta" />
                                    </div>
                                </div>
                                <div className="flex mt-6 gap-x-6">
                                    <SelectInput disabled={patient.status === 3 ? true : false} handleChange={handleChange} name="ageInMonths" label="Edad (meses)" options={
                                        <>
                                            {Array.from(Array(55), (e, i) => {
                                                return (<option selected={patient.status === 3 ? (i + 6 === discharge.ageInMonths) : null} value={i + 6}>{i + 6}</option>)
                                            })}
                                        </>
                                    } />
                                    <div className="flex justify-center">
                                        <InlineCheckbox disabled={patient.status === 3 ? true : false} value={patient.status === 3 ? discharge.completedProgram : null} handleChange={handleChange} containerClassName="w-full mt-3" id={1} name="completedProgram" label="Programa concluido" />
                                        {!discharge.completedProgram ? <TextInput disabled={patient.status === 3 ? true : false} defaultValue={patient.status === 3 ? discharge.derivationObs : ''} handleChange={handleChange} containerClassName="w-full ml-4" name="derivationObs" label="Derivar a" /> : null}
                                    </div>
                                </div>
                                <p className="text-md mt-6 leading-6 font-semibold text-indigo-700">Documentos adicionales</p>
                                <div className="flex gap-x-6">
                                    <div className="mt-4">
                                        <button onClick={handleClick} type="button" className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                                            Examinar
                                        </button>
                                        <input
                                            type="file"
                                            name="document"
                                            accept="application/pdf"
                                            ref={hiddenFileInput}
                                            onChange={handleDocument}
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                    <div className="w-2/5 mt-4 space-y-4">
                                        {documents.map((document, idx) => {
                                            return (
                                                <FileThumbnail idx={idx} delete={deleteDocument} key={idx} filename={document.name} />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </div>
        </div >
    )
}