import React, { useEffect } from "react";
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import {
    MenuAlt2Icon,
    CheckCircleIcon,
    ClockIcon,
    ClipboardCheckIcon,
    ArchiveIcon,
    UserAddIcon,
    CalendarIcon,
    PlusCircleIcon,
    PaperClipIcon,
    ClipboardListIcon,
    ThumbUpIcon,

} from '@heroicons/react/outline'
import { UserIcon, LocationMarkerIcon, PencilAltIcon, CalendarIcon as CalendarIconSolid } from '@heroicons/react/solid'
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import Divider from "../../components/Divider";
import api from "../../api";
import DisabledModal from "../../components/DisabledModal";

const status = [
    "Pendiente",
    "Admitido",
    "No admitido",
    "Alta médica"
]

const battelle = [
    {
        name: 'Battelle Screening',
        background: "bg-green-100",
        href: "/screening",
        enable: true,
        states: [0, 1, 2, 3],
        icon: <CheckCircleIcon className="h-6 w-6 text-green-700" />,
    },
    {
        name: 'Battelle Inventario',
        background: "bg-purple-100",
        href: "/inventory",
        enable: true,
        states: [0, 1, 2, 3],
        icon: <ClipboardCheckIcon className="h-6 w-6 text-purple-700" />
    },
    {
        name: 'Documentación Adicional',
        background: "bg-orange-100",
        href: "/documents",
        enable: true,
        states: [0, 2],
        icon: <ArchiveIcon className="h-6 w-6 text-orange-700" />
    },
]

const admission = [
    {
        name: 'Empezar proceso de admisión',
        background: "bg-blue-50",
        href: "/admission",
        enable: true,
        icon: <UserAddIcon className="h-6 w-6 text-teal-700" />,
    },
]

const activities = [
    {
        name: 'Plan de intervención',
        background: "bg-purple-100",
        href: "/plan",
        enable: true,
        states: [1],
        icon: <CalendarIcon className="h-6 w-6 text-purple-700" />,
    },
    {
        name: 'Registro de actividades',
        background: "bg-green-100",
        href: "/activities",
        enable: true,
        states: [1],
        icon: <PlusCircleIcon className="h-6 w-6 text-green-700" />,
    },
    {
        name: 'Documentos',
        background: "bg-orange-100",
        href: "/documents",
        enable: true,
        states: [1, 3],
        icon: <PaperClipIcon className="h-6 w-6 text-orange-700" />,
    },
]

const patientStatus = [
    {
        name: 'Historial del niño usuario',
        background: "bg-orange-100",
        href: "/history",
        enable: true,
        states: [0, 1, 2, 3],
        icon: <ClockIcon className="h-6 w-6 text-orange-700" />,
    },
    {
        name: 'Alta/Cierre',
        background: "bg-blue-50",
        href: "/discharge",
        enable: true,
        states: [1, 3],
        icon: <ThumbUpIcon className="h-6 w-6 text-blue-700" />,
    },
]

const pages = [
    { name: 'Niños usuarios', href: '/patients', current: false },
    { name: 'Detalle', href: `#`, current: true },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PatientProfilePage() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [patient, setPatient] = useState()
    const [loading, setLoading] = useState(true)
    const [ImageLoaded, setImageLoaded] = useState(false)
    const { idPatient } = useParams()
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setLoading(true)
        api.patients.getOne(idPatient).then((response) => {
            setPatient(response);
            setLoading(false);
        }).catch((error) => console.log(error))
    }, [])

    return (
        <div className="bg-gray-100 h-screen">
            <DisabledModal setOpen={setOpenModal} open={openModal} />
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                </div> :
                <div className="md:pl-64 flex flex-col flex-1">
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="flex flex-col flex-1 pb-4 ml-6 mt-6">
                        <Breadcrumbs pages={pages} />
                        <div className="flex items-center gap-x-4 ml-4">
                            {!ImageLoaded && <UserIcon className="mt-5 h-20 w-20 text-gray-500" aria-hidden="true" />}
                            <img style={ImageLoaded ? {} : { display: 'none' }} onLoad={() => setImageLoaded(true)} className="mt-5 h-20 w-20 object-cover rounded-full overflow-hidden" src={patient?.profilePicture} alt="new" />
                            <div>
                                <h1 className="mt-6 text-3xl font-bold leading-9 text-gray-900">{patient?.fullName}</h1>
                                <div className="flex items-center mt-2">
                                    <span className="text-sm leading-5 font-medium text-gray-500">C.I. {patient?.govId}</span>
                                    <LocationMarkerIcon className="ml-6 inline-block h-5 w-5 text-gray-400" />
                                    <span className="text-sm leading-5 ml-2 font-medium text-gray-500">
                                        {patient?.address}
                                    </span>
                                    <CalendarIconSolid className="ml-6 inline-block h-5 w-5 text-gray-400" />
                                    <span className="text-sm leading-5 ml-2 font-medium text-gray-500">
                                        {patient?.birthdate}
                                    </span>
                                    <span className="text-sm leading-5 ml-4 font-medium text-gray-500">Codigo: {patient?.provisionalCode} </span>
                                    <span className="text-sm leading-5 ml-4 font-bold text-gray-500">Estado: {status[patient?.status]} </span>
                                    <Link
                                        to={"/patients/" + idPatient + "/edit"}
                                        type="button"
                                        className="ml-5 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 leading-4 bg-white hover:bg-gray-50"
                                    >
                                        Editar
                                        <PencilAltIcon className="ml-2 inline-block h-4 w-4 text-gray-500" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <main className=" bg-gray-100 max-w-7xl space-y-4 mt-2 px-4 sm:px-6 md:px-8">
                        {patient.status !== 3 &&
                            <>
                                <Divider title="Battelle" />
                                <div className="grid grid-cols-4 w-auto items-center gap-4">
                                    {battelle.map((action) => (
                                        <>
                                            {!action.states.includes(patient.status) ? null :
                                                <div
                                                    key={action.name}
                                                    onClick={() => action.enable ? '' : setOpenModal(true)}
                                                    className="relative cursor-pointer rounded-lg border border-gray-300 bg-white break-words px-6 py-5 shadow-sm flex flex-col space-y-2 hover:border-gray-400"
                                                >
                                                    <div className={`w-10 rounded p-2 ${action.background}`}>
                                                        {action.icon}
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        {
                                                            action.enable ?
                                                                <Link to={`/patients/${idPatient}${action.href}`} className="focus:outline-none">
                                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                                </Link> : ''
                                                        }
                                                    </div>
                                                    <p className="text-md font-semibold text-gray-900">{action.name}</p>
                                                </div>
                                            }
                                        </>
                                    ))}
                                </div>
                            </>
                        }
                        {patient.status === 0 &&
                            <>
                                <Divider title="Admisión" />
                                <div className="grid grid-cols-4 w-auto items-center gap-4">
                                    {admission.map((action) => (
                                        <div
                                            key={action.name}
                                            onClick={() => action.enable ? '' : setOpenModal(true)}
                                            className="relative cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex flex-col space-y-2 hover:border-gray-400"
                                        >
                                            <div className={`w-10 rounded p-2 ${action.background}`}>
                                                {action.icon}
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                {
                                                    action.enable ?
                                                        <Link to={`/patients/${idPatient}${action.href}`} className="focus:outline-none">
                                                            <span className="absolute inset-0" aria-hidden="true" />
                                                        </Link> : ''
                                                }
                                            </div>
                                            <p className="text-md font-semibold text-gray-900">{action.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </>}
                        {[1, 3].includes(patient.status) &&

                            <>
                                <Divider title="Actividades" />
                                <div className="grid grid-cols-4 w-auto items-center gap-4">
                                    {activities.map((action) => (
                                        <>
                                            {!action.states.includes(patient.status) ? null :
                                                <div
                                                    key={action.name}
                                                    onClick={() => action.enable ? '' : setOpenModal(true)}
                                                    className="relative cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex flex-col space-y-2 hover:border-gray-400"
                                                >
                                                    <div className={`w-10 rounded p-2 ${action.background}`}>
                                                        {action.icon}
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        {
                                                            action.enable ?
                                                                <Link to={`/patients/${idPatient}${action.href}`} className="focus:outline-none">
                                                                    <span className="absolute inset-0" aria-hidden="true" />
                                                                </Link> : ''
                                                        }
                                                    </div>
                                                    <p className="text-md font-semibold text-gray-900">{action.name}</p>
                                                </div>
                                            }
                                        </>
                                    ))}
                                </div>
                            </>
                        }
                        <Divider title="Estado del paciente" />
                        <div className="grid grid-cols-4 w-auto items-center gap-4 pb-10">
                            {patientStatus.map((action) => (
                                <>
                                    {!action.states.includes(patient.status) ? null :
                                        <div
                                            key={action.name}
                                            onClick={() => action.enable ? '' : setOpenModal(true)}
                                            className="relative cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex flex-col space-y-2 hover:border-gray-400"
                                        >
                                            <div className={`w-10 rounded p-2 ${action.background}`}>
                                                {action.icon}
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                {
                                                    action.enable ?
                                                        <Link to={`/patients/${idPatient}${action.href}`} className="focus:outline-none">
                                                            <span className="absolute inset-0" aria-hidden="true" />
                                                        </Link> : ''
                                                }
                                            </div>
                                            <p className="text-md font-semibold text-gray-900">{action.name}</p>
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    </main>
                </div>
            }
        </div >
    );
}
