import React from "react";
import {
    XIcon
} from '@heroicons/react/outline'
import TextInput from "./TextInput";
import FileInput from "./FileInput";

export default function Family(props) {
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handleDragDropFile = async (file, field, index) => {
        const fileUploaded = await convertBase64(file[0])
        if (field === 'govIdPictureFront') { // set prevPictures to null
            props.handleAdult('prevGovIdPictureFront', null, index)
        } else if (field === 'govIdPictureBack') { // set prevPictures to null
            props.handleAdult('prevGovIdPictureBack', null, index)
        }
        props.handleAdult('decoded' + field, file[0], index)
        props.handleAdult(field, fileUploaded, index)
    };

    return (
        <>
            <div className="bg-white ml-8 mr-10 overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                        Familia
                    </h2>
                    <div className="flex mt-4 gap-x-10">
                        <TextInput errorMessage={props.errors.adults[0].fullName} defaultValue={props.adults[0]?.fullName} idx={0} handleChange={props.handleAdult} containerClassName="w-3/5" name="fullName" label="Nombre y apellido del adulto responsable *" />
                        <TextInput errorMessage={props.errors.adults[0].govId} defaultValue={props.adults[0]?.govId} idx={0} handleChange={props.handleAdult} containerClassName="w-2/5" name="govId" label="C.I. *" />
                    </div>
                    <div className="flex mt-6 gap-x-10">
                        <TextInput errorMessage={props.errors.adults[0].phone} defaultValue={props.adults[0]?.phone} idx={0} handleChange={props.handleAdult} containerClassName="w-1/4" name="phone" label="Teléfono del responsable *" />
                        <TextInput defaultValue={props.adults[0]?.observations} idx={0} handleChange={props.handleAdult} containerClassName="w-3/4" name="observations" label="Observaciones" />
                    </div>

                    <div className="flex mt-6 gap-x-10">
                        <FileInput
                            defaultValue={props.adults[0]?.decodedgovIdPictureFront}
                            prevPicture={props.adults[0]?.prevGovIdPictureFront}
                            handleChange={(file, field, index = 0) => handleDragDropFile(file, field, index)}
                            errorMessage={props.errors.adults[0].govIdPictureFront}
                            containerClassName="mt-1 w-1/3"
                            name="govIdPictureFront"
                            label="Foto de Cédula de Identidad (frente)"
                        />
                        <FileInput
                            defaultValue={props.adults[0]?.decodedgovIdPictureBack}
                            prevPicture={props.adults[0]?.prevGovIdPictureBack}
                            handleChange={(file, field, index = 0) => handleDragDropFile(file, field, index)}
                            errorMessage={props.errors.adults[0].govIdPictureBack}
                            containerClassName="mt-1 w-1/3"
                            name="govIdPictureBack"
                            label="Foto de Cédula de Identidad (detrás)"
                        />
                    </div>
                    {props.adults.length === 1 && <div className="flex mt-4 gap-x-10">
                        <button
                            type="button"
                            onClick={() => {
                                props.setAdults((prev) => ([...prev, { fullName: '', govId: '', phone: '', observations: '' }]))
                                props.setErrors((prev) => ({ ...prev, adults: [...prev.adults, { fullName: '', govId: '', phone: '', govIdPictureFront: '', govIdPictureBack: '' }] }))
                            }}
                            className="inline-flex items-center py-2 border border-transparent text-sm font-medium text-indigo-700 bg-white"
                        >
                            Agregar otro
                        </button>
                    </div>}
                </div>
                {props.adults.length === 1 && <div className="flex bg-gray-100 justify-between px-4 py-4 sm:px-6">
                    <button
                        type="button"
                        onClick={() => {
                            props.setStep((prev) => [{ ...prev[0], status: 'current' }, { ...prev[1], status: 'upcoming' }])
                        }}
                        className="inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Anterior
                    </button>
                    <button
                        type="button"
                        onClick={props.handleSubmit}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Guardar datos
                    </button>
                </div>}

            </div>
            {props.adults.length > 1 ?
                props.adults.slice(1).map((element, index) => {
                    return (
                        <div className="bg-white ml-8 mr-10 overflow-hidden shadow rounded-lg">
                            <div className="px-4 py-5 sm:p-6">
                                <div className="flex justify-between mt-4">
                                    <h2 className="text-lg leading-6 font-semibold text-gray-900 ">
                                        Familia
                                    </h2>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            props.deleteAdult(index + 1);
                                        }}
                                        className="text-indigo-500 hover:text-indigo-900"
                                    >
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="flex mt-4 gap-x-10">
                                    <TextInput errorMessage={props.errors.adults[index + 1].fullName} defaultValue={props.adults[index + 1]?.fullName} idx={index + 1} handleChange={props.handleAdult} containerClassName="w-3/5" name="fullName" label="Nombre y apellido del adulto responsable *" />
                                    <TextInput errorMessage={props.errors.adults[index + 1].govId} defaultValue={props.adults[index + 1]?.govId} idx={index + 1} handleChange={props.handleAdult} containerClassName="w-2/5" name="govId" label="C.I. *" />
                                </div>
                                <div className="flex mt-6 gap-x-10">
                                    <TextInput errorMessage={props.errors.adults[index + 1].phone} defaultValue={props.adults[index + 1]?.phone} idx={index + 1} handleChange={props.handleAdult} containerClassName="w-1/4" name="phone" label="Teléfono del responsable *" />
                                    <TextInput defaultValue={props.adults[index + 1]?.observations} idx={index + 1} handleChange={props.handleAdult} containerClassName="w-full" name="observations" label="Observaciones" />
                                </div>
                                <div className="flex mt-6 gap-x-10">
                                    <FileInput
                                        defaultValue={props.adults[index + 1]?.decodedgovIdPictureFront}
                                        prevPicture={props.adults[index + 1]?.prevGovIdPictureFront}
                                        handleChange={(file, field, idx = index + 1) => handleDragDropFile(file, field, idx)}
                                        errorMessage={props.errors.adults[index + 1].govIdPictureFront}
                                        containerClassName="mt-1 w-1/3"
                                        name="govIdPictureFront"
                                        label="Foto de Cédula de Identidad (frente)"
                                    />
                                    <FileInput
                                        defaultValue={props.adults[index + 1]?.decodedgovIdPictureBack}
                                        prevPicture={props.adults[index + 1]?.prevGovIdPictureBack}
                                        handleChange={(file, field, idx = index + 1) => handleDragDropFile(file, field, idx)}
                                        errorMessage={props.errors.adults[index + 1].govIdPictureBack}
                                        containerClassName="mt-1 w-1/3"
                                        name="govIdPictureBack"
                                        label="Foto de Cédula de Identidad (detrás)"
                                    />
                                </div>
                                {index === props.adults.length - 2 && <div className="flex mt-4 gap-x-10">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            props.setAdults((prev) => ([...prev, { fullName: '', govId: '', phone: '', observations: '' }]))
                                        }}
                                        className="inline-flex items-center py-2 border border-transparent text-sm font-medium text-indigo-700 bg-white"
                                    >
                                        Agregar otro
                                    </button>
                                </div>}
                            </div>
                            {index === props.adults.length - 2 && <div className="flex bg-gray-100 justify-between px-4 py-4 sm:px-6">
                                <button
                                    type="button"
                                    onClick={() => {
                                        props.setStep((prev) => [{ ...prev[0], status: 'current' }, { ...prev[1], status: 'upcoming' }])
                                    }}
                                    className="inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Anterior
                                </button>
                                <button
                                    type="button"
                                    onClick={props.handleSubmit}
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Guardar datos
                                </button>
                            </div>
                            }
                        </div>
                    )
                })
                : null}
        </>
    )
}