import { useEffect, useState } from "react";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import Textarea from "./Textarea";

const options = [
    <option key={0} value={0} label='Si'>Si</option>,
    <option key={1} value={1} label='No'>No</option>,
    <option key={2} value={2} label='En proceso'>En proceso</option>
]

const Areas = [
    "Personal/social",
    "Adaptativa",
    "Motora",
    "Comunicación",
    "Cognitiva"
]

const personalSocialSubareas = [
    <option key={0} value={0} label='Interacción con el adulto'>Interacción con el adulto</option>,
    <option key={1} value={1} label='Expresión de sentimientos/afecto'>Expresión de sentimientos/afecto</option>,
    <option key={2} value={2} label='Autoconcepto'>Autoconcepto</option>,
    <option key={3} value={3} label='Interacción con los compañeros'>Interacción con los compañeros</option>,
    <option key={4} value={4} label='Colaboración'>Colaboración</option>,
    <option key={5} value={5} label='Rol social'>Rol social</option>,
]

const adaptiveSubareas = [
    <option key={6} value={6} label="Atención">Atención</option>,
    <option key={7} value={7} label="Comida">Comida</option>,
    <option key={8} value={8} label="Vestido">Vestido</option>,
    <option key={9} value={9} label="Responsabilidad personal">Responsabilidad personal</option>,
    <option key={10} value={10} label="Aseo">Aseo</option>,
]

const motorSubareas = [
    <option key={11} value={11} label="Control muscular">Control muscular</option>,
    <option key={12} value={12} label="Coordinación coporal">Coordinación corporal</option>,
    <option key={13} value={13} label="Locomoción">Locomoción</option>,
    <option key={14} value={14} label="Motricidad fina">Motricidad fina</option>,
    <option key={15} value={15} label="Motricidad perceptiva">Motricidad perceptiva</option>,
]

const communicationSubareas = [
    <option key={16} value={16} label="Receptiva">Receptiva</option>,
    <option key={17} value={17} label="Expresiva">Expresiva</option>,
]

const cognitiveSubareas = [
    <option key={18} value={18} label="Discriminación perceptiva">Discriminación perceptiva</option>,
    <option key={19} value={19} label="Memoria">Memoria</option>,
    <option key={20} value={20} label="Razonamiento y habilidades escolares">Razonamiento y habilidades escolares</option>,
    <option key={21} value={21} label="Desarrollo conceptual">Desarrollo conceptual</option>,
]

export default function Objective(props) {

    const [subAreas, setSubareas] = useState(personalSocialSubareas);

    useEffect(() => {
        if (parseInt(props.objective.area) === 0) {
            setSubareas(personalSocialSubareas)
        } else if (parseInt(props.objective.area) === 1) {
            setSubareas(adaptiveSubareas)
        } else if (parseInt(props.objective.area) === 2) {
            setSubareas(motorSubareas)
        } else if (parseInt(props.objective.area) === 3) {
            setSubareas(communicationSubareas)
        } else {
            setSubareas(cognitiveSubareas)
        }
    }, [props.objective.area])

    return (
        <li key={props.index + 1} className="grid grid-cols-7 gap-x-4 font-semibold leading-7 text-gray-700 py-4">
            <div className="flex flex-col col-span-2">
                <p>Área</p>
                <SelectInput defaultValue={props.objective.area} idx={props.index} name="area" handleChange={props.handleChange} options={Areas.map((element, index) => <option key={index} value={index} label={element}>{element}</option>)} />
                <p className="mt-2 text-gray-500">Sub-área</p>
                <SelectInput defaultValue={props.objective.subarea} idx={props.index} name="subarea" handleChange={props.handleChange} options={subAreas} />
            </div>
            <Textarea defaultValue={props.objective.objective} idx={props.index} handleChange={props.handleChange} name="objective" containerClassName="mt-7 col-span-2" rows={4} />
            <SelectInput defaultValue={props.objective.achievement} idx={props.index} containerClassName="mt-7" handleChange={props.handleChange} name="achievement" options={options} />
            <Textarea defaultValue={props.objective.obs} idx={props.index} handleChange={props.handleChange} name="obs" containerClassName="mt-7 col-span-2 mr-2" rows={4} />
        </li>
    )
}