import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PlusIcon, TrashIcon, DownloadIcon } from "@heroicons/react/outline";
import Sidebar from "../../components/Sidebar";
import api from "../../api";
import Tabs from "../../components/Tabs";
import Breadcrumbs from "../../components/Breadcrumbs";

const status = [
    "Pendiente",
    "Admitido",
    "No admitido",
    "Alta médica"
]

export default function DocumentsPage() {
    const { idPatient } = useParams();
    const [battelleId, setBattelleId] = useState(null);
    const [plan, setPlan] = useState(false);
    const [sessions, setSessions] = useState(false);
    const [inventoryLoading, setInventoryLoading] = useState(false);
    const [admissionLoading, setAdmissionLoading] = useState(false);
    const [surveyLoading, setSurveyLoading] = useState(false);
    const [planLoading, setPlanLoading] = useState(false);
    const [activitiesLoading, setActivitiesLoading] = useState(false);
    const [sessionsLoading, setSessionsLoading] = useState(false);
    const [patient, setPatient] = useState({});
    const [documentsError, setDocumentsError] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [patientDocuments, setPatientDocuments] = useState({});
    const [adultsDocuments, setAdultsDocuments] = useState([]);
    const [othersDocuments, setOthersDocuments] = useState([]);
    const [downloads, setDownloads] = useState([]);
    const [tab, setTab] = useState("Documentos de identidad");
    const [tabs, setTabs] = useState([
        { name: "Documentos de identidad", current: true },
        { name: "Otros documentos", current: false },
    ]);
    const pages = [
        { name: "Niños usuarios", href: "/patients", current: false },
        { name: `${patient.fullName} | ${patient.age} meses | ${status[patient.status]}`, href: `/patients/${patient.id}`, current: false },
        { name: "Documentación adicional", href: `#`, current: true },
    ];

    useEffect(async () => {
        await api.patients.getOne(idPatient).then((response) => {
            setPatient(response);
        }).catch((error) => console.log(error))
        await api.documents
            .getMulti(null, null, null, null, { kid: idPatient })
            .then((response) => {
                setPatientDocuments(response.kidGovId);
                setAdultsDocuments(response.responsibleAdultsGovId);
                setOthersDocuments(response.documents);
                setLoading(false);
            });
        api.inventory.getMulti(null, null, null, null, { kid: idPatient }).then((response) => {
            if (response.length !== 0) {
                setBattelleId(response[response.length - 1].id)
            }
        })
        api.plan.getOne(idPatient).then((response) => {
            if (response.length !== 0) {
                setPlan(true);
            }
        })
        api.sessions.getMulti(null, null, null, null, { kid: idPatient }).then((response) => {
            if (response.length !== 0) {
                setSessions(true);
            }
        })
    }, []);

    const deleteDocument = (id) => {
        let confirm = window.confirm(
            "Este documento será eliminado, confirma esta acción"
        );
        if (confirm === true) {
            setLoading(true);
            api.documents
                .delete(id)
                .then((response) => {
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        } else {
            return false;
        }
    };

    const downloadInventory = function () {
        setInventoryLoading(true)
        api.downloads.inventory({ battelle_id: battelleId }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${patient.fullName}_Inventario.pdf`); //or any other extension
            link.click();
            setInventoryLoading(false);
        }, err => {
            console.log('Error al descargar: ', err.response?.data)
            setInventoryLoading(false)
        })
    }

    const downloadAdmission = function () {
        setAdmissionLoading(true)
        api.downloads.admission({ kid_id: idPatient }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${patient.fullName}_Admision.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setAdmissionLoading(false);
        }, err => {
            console.log('Error al descargar: ', err.response?.data)
            setAdmissionLoading(false)
        })
    }

    const downloadSessions = function () {
        setSessionsLoading(true)
        api.downloads.sessions({ kid_id: idPatient }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${patient.fullName}_Sesiones.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setSessionsLoading(false);
        }, err => {
            console.log('Error al descargar: ', err.response?.data)
            setSessionsLoading(false)
        })
    }

    const downloadSurvey = function () {
        setSurveyLoading(true)
        api.downloads.socioeconomicSurvey({ kid_id: idPatient }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${patient.fullName}_Encuesta.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setSurveyLoading(false);
        }, err => {
            console.log('Error al descargar: ', err.response?.data)
            setSurveyLoading(false)
        })
    }

    const downloadPlan = function () {
        setPlanLoading(true)
        api.downloads.interventionPlan({ kid_id: idPatient }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${patient.fullName}_Plan.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setPlanLoading(false);
        }, err => {
            console.log('Error al descargar: ', err.response?.data)
            setPlanLoading(false)
        })
    }

    const downloadActivities = function () {
        setActivitiesLoading(true)
        api.downloads.activities({ kid_id: idPatient }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${patient.fullName}_Actividades.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setActivitiesLoading(false);
        }, err => {
            console.log('Error al descargar: ', err.response?.data)
            setActivitiesLoading(false)
        })
    }

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar
                current={"Pacientes"}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ? (
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                ) : (
                    <>
                        {documentsError && (
                            <div className="mt-5 mx-10 text-sm text-red-600">
                                {documentsError}
                            </div>
                        )}
                        <main className="flex-1 bg-gray-100 pl-5">
                            <div className="py-5">
                                <Breadcrumbs pages={pages} />
                            </div>
                            <Tabs
                                tabs={tabs}
                                setTab={setTab}
                                fullWidth={true}
                                containerClassName="w-full"
                            />
                            {tab == "Documentos de identidad" && (
                                <div className="py-8">
                                    <h2 className="font-bold text-lg">
                                        CI del niño
                                    </h2>
                                    <div className="flex py-8">
                                        <div className="w-1/3 bg-white mr-4 p-4 rounded-2xl">
                                            <img
                                                src={patientDocuments.front}
                                                alt={patientDocuments.fullName}
                                            />
                                            <h3 className="text-base font-medium py-2">
                                                {patientDocuments.fullName}
                                            </h3>
                                            <p className="text-sm text-gray-900">
                                                CI {patientDocuments.govId}
                                            </p>
                                        </div>
                                        <div className="w-1/3 bg-white mr-4 p-4 rounded-2xl">
                                            <img
                                                src={patientDocuments.back}
                                                alt={patientDocuments.fullName}
                                            />
                                            <h3 className="text-base font-medium py-2">
                                                {patientDocuments.fullName}
                                            </h3>
                                            <p className="text-sm text-gray-900">
                                                CI {patientDocuments.govId}
                                            </p>
                                        </div>
                                    </div>

                                    <h2 className="font-bold text-lg pt-4 mb-4">
                                        CI de los responsables
                                    </h2>
                                    {adultsDocuments.length === 0 ? (
                                        <p className="text-medium text-gray-400 pt-4">
                                            No hay documentos
                                        </p>
                                    ) : (
                                        adultsDocuments.map((adult) => {
                                            return (
                                                <div
                                                    key={adult.govId}
                                                    className="flex py-2"
                                                >
                                                    <div className="w-1/3 bg-white mr-4 p-4 rounded-2xl">
                                                        <img
                                                            src={adult.front}
                                                            alt={adult.fullName}
                                                        />
                                                        <h3 className="text-base font-medium py-2">
                                                            {adult.fullName}
                                                        </h3>
                                                        <p className="text-sm text-gray-900">
                                                            CI {adult.govId}
                                                        </p>
                                                    </div>
                                                    <div className="w-1/3 bg-white mr-4 p-4 rounded-2xl">
                                                        <img
                                                            src={adult.back}
                                                            alt={adult.fullName}
                                                        />
                                                        <h3 className="text-base font-medium py-2">
                                                            {adult.fullName}
                                                        </h3>
                                                        <p className="text-sm text-gray-900">
                                                            CI {adult.govId}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            )}
                            {tab == "Otros documentos" && (
                                <div className="p-4">
                                    <div className="flex justify-between mb-4">
                                        <div className="grid grid-cols-3 gap-4">
                                            {battelleId ? <button
                                                onClick={() => downloadInventory()}
                                                type="button"
                                                className="inline-flex justify-between items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                {inventoryLoading ?
                                                    <div className="px-10 items-center">
                                                        <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                                                    </div> :
                                                    <>
                                                        Batelle Inventario
                                                        <DownloadIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                }
                                            </button> : null}
                                            {patient.status !== 0 ? <button
                                                onClick={() => downloadAdmission()}
                                                type="button"
                                                className="inline-flex justify-between items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                {admissionLoading ?
                                                    <div className="px-10">
                                                        <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                                                    </div> :
                                                    <>
                                                        Admisión
                                                        <DownloadIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                }
                                            </button> : null}
                                            {patient.status !== 0 ? <button
                                                onClick={() => downloadSurvey()}
                                                type="button"
                                                className="inline-flex justify-between items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                {surveyLoading ?
                                                    <div className="flex px-10 justify-center">
                                                        <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                                                    </div> :
                                                    <>
                                                        Encuesta socioeconómica
                                                        <DownloadIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                }
                                            </button> : null}
                                            {plan ? <button
                                                onClick={() => downloadPlan()}
                                                type="button"
                                                className="inline-flex justify-between items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                {planLoading ?
                                                    <div className="px-10">
                                                        <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                                                    </div> :
                                                    <>
                                                        Plan de intervención
                                                        <DownloadIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                }
                                            </button> : null}
                                            {sessions ? <button
                                                onClick={() => downloadSessions()}
                                                type="button"
                                                className="inline-flex justify-between items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                {sessionsLoading ?
                                                    <div className="px-10">
                                                        <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                                                    </div> :
                                                    <>
                                                        Sesiones
                                                        <DownloadIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                }
                                            </button> : null}
                                            {sessions ? <button
                                                onClick={() => downloadActivities()}
                                                type="button"
                                                className="inline-flex justify-between items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                {activitiesLoading ?
                                                    <div className="px-10">
                                                        <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                                                    </div> :
                                                    <>
                                                        Actividades
                                                        <DownloadIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </>
                                                }
                                            </button> : null}
                                        </div>
                                        <Link
                                            to={
                                                "/patients/" +
                                                idPatient +
                                                "/new-document"
                                            }
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
                                        >
                                            <PlusIcon
                                                className="-ml-1 mr-2 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                            Subir nuevo documento
                                        </Link>
                                    </div>
                                    {othersDocuments.length === 0 ? (
                                        <p className="text-medium text-gray-400 pt-4">
                                            No hay documentos adicionales
                                        </p>
                                    ) : (
                                        <div className="bg-white shadow overflow-hidden sm:rounded-md">
                                            <ul
                                                role="list"
                                                className="divide-y divide-gray-200"
                                            >
                                                {othersDocuments.map(
                                                    ({
                                                        id,
                                                        title,
                                                        document,
                                                    }) => (
                                                        <li
                                                            key={id}
                                                            className="hover:bg-indigo-100 cursor-pointer"
                                                        >
                                                            <a
                                                                href={document}
                                                                target="_blank"
                                                                className="flex justify-between items-center p-4"
                                                            >
                                                                <h3 className="text-base font-medium">
                                                                    {title}
                                                                </h3>
                                                                <button
                                                                    onClick={() =>
                                                                        deleteDocument(
                                                                            id
                                                                        )
                                                                    }
                                                                    className="text-gray-400 inline-flex items-center px-4 py-2 text-sm font-semibold rounded-md bg-transparent hover:bg-gray-50"
                                                                >
                                                                    Eliminar
                                                                    <TrashIcon
                                                                        className="ml-2 h-4 w-4 text-gray-400"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </a>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            )}
                        </main>
                    </>
                )}
            </div>
        </div>
    );
}
