import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import api from "../api";

export default function WYSIWIG(props) {
    const editorRef = useRef(null);

    const handleEditorChange = (content, editor) => {
        props.setContent(content);
    }

    return (
        <>
            <Editor
                apiKey="mycsknb75tnkdmtp6cl6zao24fuv4e8n0rtwuj8mcpjz70mg"
                onInit={(evt, editor) => (editorRef.current = editor)}
                onEditorChange={handleEditorChange}
                initialValue={props.oldContent}
                value={props.content}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | image",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    file_picker_types: "file image media",
                    images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => {
                        let imageFile = new FormData();
                            imageFile.append("picture", blobInfo.blob());

                            api.images.create(imageFile).then(response => {
                                resolve(response.picture);
                            }).catch(error => reject(error))
                    })
                    }
                }
            />
        </>
    );
}
