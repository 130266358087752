function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function BattelleItem(props) {

    return (
        <li key={props.key} className={classNames(props.highlight ? 'bg-gray-100' : '', "grid grid-cols-5 gap-24 font-medium leading-7 text-gray-700 py-4")}>
            <p className="text-indigo-600">{props.name}</p>
            <p>{props.directScore ? props.directScore : '0'}</p>
            <p>{props.percentilScore ? props.percentilScore : '--'}</p>
            <p>{props.typicalTScore ? props.typicalTScore : '--'}</p>
            <p>{props.equivalentAge ? props.equivalentAge : '--'}</p>
        </li>)
}