import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import Tabs from "../../../components/Tabs";
import api from "../../../api";
import { PlusIcon } from "@heroicons/react/outline";
import { CogIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import Table from "../../../components/Table";

const categoryColumns = [
    { name: 'name', displayText: 'Categoría' },
    { name: 'ageRangeName', displayText: 'Rango de Edad' },
    { name: 'color', displayText: 'Color' }
]

const rangeColumns = [
    { name: 'ageRange', displayText: 'Nombre'},
    { name: 'ageInMonthsMin', displayText: 'Mes inicial'},
    { name: 'ageInMonthsMax', displayText: 'Mes final'},
]

export default function AdminVigiditPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState("Categorías");
    const [tabs, setTabs] = useState([
        { name: "Categorías", current: true },
        { name: "Rangos de edad", current: false },
    ]);

    const [categories, setCategories] = useState([]);
    const [ageRanges, setAgeRanges] = useState([]);

    const getCategories = () => {
        api.categories
            .getMulti()
            .then((response) => {
                setCategories(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    const getAgeRanges = () => {
        api.ageRanges
            .getMulti()
            .then((response) => {
                setAgeRanges(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        getCategories();
        getAgeRanges();
    }, []);

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar
                current={"Vigidit"}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ? (
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                ) : (
                    <main className="px-8">
                        <div className="flex justify-end py-3">
                            <Link 
                                to="/configurations"
                                className="inline-flex h-min items-center py-2 px-4 bg-indigo-200 hover:bg-indigo-300 rounded-md shadow-sm text-sm font-semibold text-gray-900"
                            >
                                <CogIcon className="h-5 w-5 mr-3" />
                                Ir a configuraciones
                            </Link>
                        </div>
                        <Tabs
                            tabs={tabs}
                            setTab={setTab}
                            fullWidth={true}
                            containerClassName="w-full"
                        />
                        {tab == "Categorías" && (
                            <section>
                                <div className="flex justify-between py-4">
                                    <h2 className="text-2xl font-semibold leading-9 text-gray-900">
                                        Categorías
                                    </h2>
                                    <Link
                                        to="/admin/vigidit/categories/new"
                                        type="button"
                                        className="inline-flex h-min px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                    >
                                        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                        Crear nueva categoría
                                    </Link>
                                </div>
                                {
                                    categories.length > 0
                                    ? <Table module="admin/vigidit/categories" actionText="Editar" columns={categoryColumns} rows={categories} />
                                    : <p className="text-medium text-gray-400 pt-4">No hay categorías</p>
                                }
                            </section>
                        )}
                        {tab == "Rangos de edad" && (
                            <section>
                            <div className="flex justify-between py-4">
                                <h2 className="text-2xl font-semibold leading-9 text-gray-900">
                                    Rangos de edad
                                </h2>
                                <Link
                                    to="/admin/vigidit/age-ranges/new"
                                    type="button"
                                    className="inline-flex h-min px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                    Crear nuevo rango de edad
                                </Link>
                            </div>
                            {
                                ageRanges.length > 0 
                                ? <Table module="admin/vigidit/age-range" actionText="Editar" columns={rangeColumns} rows={ageRanges} />
                                : <p className="text-medium text-gray-400 pt-4">No hay rangos de edad</p>
                            }
                        </section>
                        )}
                    </main>
                )}
            </div>
        </div>
    );
}
