import React, { useState } from "react";
import { Fragment } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import {
    XIcon,
    NewspaperIcon,
    UserGroupIcon,
    UserCircleIcon,
    OfficeBuildingIcon,
} from '@heroicons/react/outline'
import { ExclamationIcon } from "@heroicons/react/outline";
import { EyeIcon } from "@heroicons/react/solid";
import { useAuth } from "../context/authContext";
import Modal from './Modal';
import api from "../api";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar(props) {

    const userData = JSON.parse(localStorage.getItem('userData'))
    const [openLogout, setOpenLogout] = useState(false)
    const [visible, setVisible] = useState(false)
    const [openChangePassword, setOpenChangePassword] = useState(false)
    const [password, setPassword] = useState("")
    const navigate = useNavigate();
    const [role, setRole] = useState(userData?.profile.role);
    const [username, setUsername] = useState(userData?.firstName + ' ' + userData?.lastName);
    const auth = useAuth();

    const navigation = [
        { availableTo: [0, 1, 2, 3], name: 'Niños usuarios', href: '/patients', icon: UserGroupIcon, current: props.current === 'Pacientes' },
        { availableTo: [0, 3], name: 'Establecimientos', href: '/establishments', icon: OfficeBuildingIcon, current: props.current === 'Establecimientos' },
        { availableTo: [0, 3], name: 'Usuarios del sistema', href: '/users', icon: UserCircleIcon, current: props.current === 'Usuarios' },
        { availableTo: [2, 3], name: 'VIGIDIT', href: '/admin/vigidit', icon: NewspaperIcon, current: props.current === 'Vigidit' },
    ]

    const submitChangePassword = async () => {
        await api.users.changePassword(password)
            .then((response) => {
                console.log(response)
                setOpenChangePassword(false)
            })
            .catch((error) => console.log(error))
    }

    const changepassword = (
        <>
            < div >
                <div className="text-center">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                        Cambiar contraseña
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                        <label htmlFor="password" className="text-sm font-medium text-gray-700">
                            Nueva contraseña
                        </label>
                        <div className="relative w-2/3 rounded-md mx-auto shadow-sm">
                            <input
                                type={visible ? "text" : "password"}
                                name="password"
                                onChange={(event) => setPassword(event.target.value)}
                                id="password"
                                className="block mt-1 w-full h-8 pr-10 sm:text-sm border-gray-300 rounded-md"
                            />
                            <button onClick={() => setVisible(!visible)} className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                <EyeIcon className="h-5 w-5 text-gray-400 hover:text-blue-400" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:col-start-2 sm:text-sm"
                    onClick={() =>
                        submitChangePassword()
                    }
                >
                    Guardar
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenChangePassword(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    const logout = (
        <>
            < div >
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                    <ExclamationIcon className="h-6 w-6 text-yellow-800" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Cerrar sesión
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            ¿Estás seguro de que quieres salir?
                        </p>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    onClick={async () => {
                        await auth.signOut();
                        navigate('/login')
                    }}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-800 sm:col-start-2 sm:text-sm"
                >
                    Cerrar Sesión
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenLogout(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    return (
        <div>
            <Modal content={changepassword} open={openChangePassword} setOpen={setOpenChangePassword} />
            <Modal content={logout} open={openLogout} setOpen={setOpenLogout} />
            <Transition.Root show={props.sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={props.setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => props.setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink flex items-center h-15 pr-4 pl-8">
                                <img
                                    className="h-full w-auto"
                                    src='https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg'
                                    alt="Workflow"
                                />
                            </div>
                            <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                <nav className="px-2 space-y-1">
                                    {navigation.map((item) => {
                                        if (role in item.availableTo) {
                                            return (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className={classNames(
                                                        item.current
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                            'mr-4 flex-shrink-0 h-6 w-6'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            )
                                        }
                                    })}
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
                    <div className="flex items-center flex-shrink-0 pr-4 pl-8">
                        <img
                            className="h-auto ml-4 w-32"
                            src="/logo_sit.png"
                            alt="Workflow"
                        />
                    </div>
                    <div className="mt-5 flex-grow flex flex-col">
                        <nav className="flex-1 px-2 pb-4 space-y-1">
                            {navigation.map((item) => {
                                if (item.availableTo.indexOf(role) !== -1) {
                                    return (<Link
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                'mr-3 flex-shrink-0 h-6 w-6'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </Link>
                                    )
                                }
                            }
                            )}
                        </nav>
                    </div>
                    <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                        <div className="flex-col space-y-3 ml-3 items-center justify-start">
                            <p className="text-sm font-medium text-gray-700">{username}</p>
                            <a className="text-left flex-shrink-0 w-full text-xs font-medium text-indigo-600 group-hover:text-indigo-800 group block justify-start" href="https://www.mspbs.gov.py/donde-consulto.php" target="_blank" rel="noreferrer">Ver listado de Establecimientos</a>
                            <button onClick={() => setOpenChangePassword(true)} className="text-left flex-shrink-0 w-full text-xs font-medium text-indigo-600 group-hover:text-indigo-800 group block justify-start">
                                Cambiar contraseña
                            </button>
                            <button onClick={() => setOpenLogout(true)} className="text-left flex-shrink-0 w-full text-xs font-medium text-indigo-600 group-hover:text-indigo-800 group block justify-start">
                                Cerrar sesión
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
