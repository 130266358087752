/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InlineCheckbox from "./InlineCheckbox";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import VerticalRadioButtons from "./VerticalRadioButtons";

export default function EventModal(props) {

    const colors = [
        "#2886d2",
        "#F0763D",
        "#84C557",
        "#6D28D9",
        "#808080"
    ]
    const [session, setSession] = useState([])
    const [doctors, setDoctors] = useState([])
    const [option, setOption] = useState()
    const options = [
        { id: 0, title: 'Ninguno' },
        { id: 1, title: 'Hacer seguimiento' },
        { id: 2, title: 'Elaborar informe' },
        { id: 3, title: 'Reunión con padres' },
    ]

    useEffect(() => {
        if (props.info.edit) {
            setSession(props.info)
            if (props.info.followUp) {
                setOption(1);
            } else if (props.info.prepareReport) {
                setOption(2);
            } else if (props.info.meetingWithParents) {
                setOption(3);
            } else {
                setOption(0);
            }
        } else {
            setSession({
                date: props.info?.startStr ? new Date(props.info.startStr).toISOString().substring(0, 10) : '',
                startTime: props.info.endStr ? props.info.startStr.substring(11, 16) : null,
                endTime: props.info.endStr ? props.info.endStr.substring(11, 16) : null,
                followUp: false,
                prepareReport: false,
                meetingWithParents: false,
                absent: false,
                absenceReason: '',
                description: '',
                obs: '',
                doctor: doctors[0]?.id,
                plan: props.plan
            });
            setOption(0);
        }
    }, [props.info])

    useEffect(() => {
        setDoctors(props.professionals)
    }, [props.professionals])

    useEffect(() => {
        if (parseInt(option) === 1) {
            session.followUp = true;
            session.prepareReport = false;
            session.meetingWithParents = false;
        } else if (parseInt(option) === 2) {
            session.followUp = false;
            session.prepareReport = true;
            session.meetingWithParents = false;
        } else if (parseInt(option) === 3) {
            session.followUp = false;
            session.prepareReport = false;
            session.meetingWithParents = true;
        } else {
            session.followUp = false;
            session.prepareReport = false;
            session.meetingWithParents = false;
        }
    }, [option])

    const handleChange = (field, value) => setSession((prev) => ({ ...prev, [field]: value }));
    const handleOptions = (field, value) => setOption(value);

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={props.setOpen}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                            <div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-medium text-gray-900"
                                    >
                                        {session.edit ? 'Editar sesión' : 'Agregar sesión'}
                                    </Dialog.Title>
                                    <div className="mt-2 flex flex-col text-left">
                                        Fecha: {session.date}<br />
                                        Hora de inicio: {session.startTime}<br />
                                        Hora de fin: {session.endTime}<br />
                                        Seleccionar profesional <br />
                                        <SelectInput defaultValue={props.doctor ? props.doctor : null} name="doctor" handleChange={handleChange} options={doctors?.map((doctor) => <option key={doctor.id} value={doctor.id} label={doctor.name} selected={doctor.id === session.doctor}>{doctor.name}</option>)} />
                                        <div className="flex gap-x-12">
                                            <VerticalRadioButtons handleChange={handleOptions} defaultChecked={option} name="option" options={options} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:col-start-2 sm:text-sm"
                                    onClick={() => {
                                        if (session.edit) {
                                            props.handleEditSession(session);
                                            props.handleEditEvent(session);
                                        } else {
                                            props.setSessions((prev) => [...prev, session])
                                            props.setEvents((prev) => [...prev, {
                                                title: doctors.filter((doctor) => doctor.id === parseInt(session.doctor))[0]?.name, start: props.info.startStr.slice(0, 16) + '-04:00', end: props.info.endStr.slice(0, 16) + '-04:00',
                                                color: session.followUp ? colors[1] : session.prepareReport ? colors[2] : session.meetingWithParents ? colors[3] : colors[0]
                                                , editable: true
                                            }])
                                        }
                                        props.setOpen(false);
                                    }}
                                >
                                    Aceptar
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 bg-white text-base border-gray-300 font-medium text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                    onClick={() => props.setOpen(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
