import React, { useEffect } from "react";
import { useState } from 'react'
import {
    MenuAlt2Icon,
} from '@heroicons/react/outline'
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import Tabs from "../../components/Tabs";
import api from "../../api";
import { useParams } from "react-router-dom";
import { HorizontalGridLines, AreaSeries, XYPlot, YAxis, XAxis, LineMarkSeries, Hint, VerticalGridLines } from "react-vis/dist";

const status = [
    "Pendiente",
    "Admitido",
    "No admitido",
    "Alta médica"
]

const InventoryAxis = [
    'Interacción con el adulto',
    'Expresión de sentimientos/afecto',
    'Autoconcepto',
    'Interacción con los compañeros',
    'Colaboración',
    'Rol social',
    'Total Personal/social',
    'Atención',
    'Comida',
    'Vestido',
    'Responsabilidad personal',
    'Aseo',
    'Total Adaptativa',
    'Control muscular',
    'Coordinación corporal',
    'Locomoción',
    'Puntuación Motora gruesa',
    'Motricidad fina',
    'Motricidad perceptiva',
    'Puntuación Motora fina',
    'Total Motora',
    'Receptiva',
    'Expresiva',
    'Total Comunicación',
    'Discriminación perceptiva',
    'Memoria',
    'Razonamiento y habilidades escolares',
    'Desarrollo conceptual',
    'Total Cognitiva',
    'Puntuación Total',

]

const InventoryGraphic = function (props) {

    const [inventoryHoveredValue, setInventoryHoveredValue] = useState(null)
    const [data, setData] = useState(
        [{
            x: props.results.interactionWithPeersTypicalTScore,
            y: 30
        },
        {
            x: props.results.feelingsExpressionTypicalTScore,
            y: 29
        },
        {
            x: props.results.selfconceptTypicalTScore,
            y: 28
        },
        {
            x: props.results.interactionWithPeersTypicalTScore,
            y: 27
        },
        {
            x: props.results.collaborationTypicalTScore,
            y: 26
        },
        {
            x: props.results.socialRoleTypicalTScore,
            y: 25
        },
        {
            x: props.results.personalSocialAreaTotalTypicalTScore,
            y: 24
        },
        {
            x: props.results.attentionTypicalTScore,
            y: 23
        },
        {
            x: props.results.mealTypicalTScore,
            y: 22
        },
        {
            x: props.results.clothesTypicalTScore,
            y: 21
        },
        {
            x: props.results.personalResponsibilityTypicalTScore,
            y: 20
        },
        {
            x: props.results.cleanlinessTypicalTScore,
            y: 19
        },
        {
            x: props.results.adaptiveAreaTotalTypicalTScore,
            y: 18
        },
        {
            x: props.results.muscleControlTypicalTScore,
            y: 17
        },
        {
            x: props.results.bodyCoordinationTypicalTScore,
            y: 16
        },
        {
            x: props.results.locomotionTypicalTScore,
            y: 15
        },
        {
            x: props.results.thickMotorSubtotalTypicalTScore,
            y: 14
        },
        {
            x: props.results.fineMotorSkillsTypicalTScore,
            y: 13
        },
        {
            x: props.results.perceptualMotorSkillsTypicalTScore,
            y: 12
        },
        {
            x: props.results.thinMotorSubtotalTypicalTScore,
            y: 11
        },
        {
            x: props.results.motorAreaTotalTypicalTScore,
            y: 10
        },
        {
            x: props.results.receptiveTypicalTScore,
            y: 9
        },
        {
            x: props.results.expressiveTypicalTScore,
            y: 8
        },
        {
            x: props.results.communicationAreaTotalTypicalTScore,
            y: 7
        },
        {
            x: props.results.perceptualDiscriminationTypicalTScore,
            y: 6
        },
        {
            x: props.results.memoryTypicalTScore,
            y: 5
        },
        {
            x: props.results.reasoningTypicalTScore,
            y: 4
        },
        {
            x: props.results.conceptDevelopmentTypicalTScore,
            y: 3
        },
        {
            x: props.results.cognitiveAreaTotalTypicalTScore,
            y: 2
        },
        {
            x: props.results.totalTypicalTScore,
            y: 1
        },
        ]
    )

    return <XYPlot
        margin={{ top: 20 }}
        width={props.width}
        height={props.height}
        yDomain={[1, 30]}
        xDomain={[0, 100]}
        onMouseLeave={() => setInventoryHoveredValue(null)}
    >
        <VerticalGridLines tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
        <HorizontalGridLines tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]} />
        <AreaSeries
            color="red"
            opacity={0.1}
            data={[
                {
                    x: 35,
                    y: 1,
                    y0: 30
                },
                {
                    x: 65,
                    y: 1,
                    y0: 30
                }
            ]}
        />
        <XAxis top={-10} tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
        <LineMarkSeries
            onValueMouseOut={() => setInventoryHoveredValue(null)}
            onValueMouseOver={(value) => setInventoryHoveredValue(value)}
            data={data}
        />
        {inventoryHoveredValue && <Hint value={inventoryHoveredValue}>
            <div className='px-4 py-3 text-xs text-white bg-gray-700 rounded-md shadow-md space-y-1 whitespace-nowrap'>
                <div>{InventoryAxis[30 - inventoryHoveredValue.y]}</div>
                <div>Puntaje: {inventoryHoveredValue.x}</div>
            </div>
        </Hint>}
    </XYPlot>
}

const History = function (props) {
    return (
        <main className=" bg-white max-w-5xl px-4">
            <h1 className="mt-10 text-lg font-semibold leading-5 text-gray-900">Historial de actividades</h1>
            <ul role="list" className="mt-4 divide-y divide-gray-200">
                <li key={0} className="grid grid-cols-6 gap-x-20 font-bold leading-7 text-gray-700 py-4">
                    <p className="col-span-1">Fecha</p>
                    <p className="col-span-3">Actividad</p>
                    <p className="col-span-2">Observación</p>
                </li>
                {props.sessions.length !== 0 ?
                    <>
                        {
                            props.sessions.map((session, idx) => {
                                return (<li key={idx + 1} className="grid grid-cols-6 gap-20 font-medium leading-7 text-gray-700 py-4">
                                    <p className="text-indigo-600 col-span-1">{session.date}</p>
                                    <p className="col-span-3">{session.description !== "" ? session.description : '--'}</p>
                                    <p className="col-span-2">{session.obs !== "" ? session.obs : '--'}</p>
                                </li>)
                            })
                        }
                    </>
                    : <p className="mt-4 font-semibold text-gray-500">Aún no se ha cargado ninguna sesión</p>
                }
            </ul>
        </main>
    )
}

const BattelleHistory = function (props) {
    return (
        <main className=" bg-white max-w-5xl px-4">
            <h1 className="mt-10 text-lg font-semibold leading-5 text-gray-900">Gráficos Battelle Inventario</h1>
            <div className="flex mt-8 space-x-10">
                {props.inventory.length !== 0 ?
                    <>
                        {
                            props.inventory.map((graphic, idx) => {
                                return (
                                    <div className="flex flex-col space-y-4">
                                        <p className="text-indigo-600 leading-5 font-semibold text-center">{graphic.date}</p>
                                        <InventoryGraphic results={graphic} width={240} height={2550} />
                                    </div>
                                )
                            })
                        }
                    </>
                    : <p className="mt-4 font-semibold text-gray-500">No se ha realizado el Battelle Inventario</p>
                }
            </div>
        </main>
    )
}


export default function ActivityHistoryPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [tab, setTab] = useState('Historial de actividades');
    const [loading, setLoading] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [inventory, setInventory] = useState([]);
    const { idPatient } = useParams();
    const [patient, setPatient] = useState([]);

    useEffect(() => {
        api.patients.getOne(idPatient).then((response) => {
            setPatient(response);
        }).catch((error) => console.log(error))
        api.sessions.getMulti(null, null, null, null, { plan__kid: idPatient }).then((response) => {
            setSessions(response);
        }).catch((error) => console.log(error))
        api.inventory.getMulti(null, null, null, null, { kid: idPatient }).then((response) => {
            setInventory(response);
        }).catch((error) => console.log(error))
    }, [])

    const tabs = [
        { name: 'Historial de actividades', current: true },
        { name: 'Gráficos Battelle Inventario', current: false },
    ]

    const pages = [
        { name: 'Niños usuarios', href: '/patients', current: false },
        { name: `${patient.fullName} | ${patient.age} meses | ${status[patient.status]}`, href: `/patients/${idPatient}`, current: false },
        { name: 'Registro de actividades', href: '#', current: true }
    ]

    return (
        <div className="bg-gray-100 min-h-screen h-full">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 space-y-4 pb-4 ml-6 mt-6">
                    <Breadcrumbs pages={pages} />
                    <Tabs fullWidth={true} tabs={tabs} setTab={setTab} />
                    {tab === 'Historial de actividades' &&
                        <History sessions={sessions} />
                    }
                    {tab === 'Gráficos Battelle Inventario' &&
                        <BattelleHistory inventory={inventory} />
                    }
                </div>
            </div>
        </div>
    );
}