import React from "react";
import { Link } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

/*function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}*/

export default function Table(props) {

    return (
        <div className={`flex flex-col`}>
            <div className={`-my-2 ${!props.nonScrolleable && 'overflow-x-auto'} sm:-mx-6 lg:-mx-8`}>
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {props.columns.map((column) => (
                                        <>
                                            <th
                                                key={column.name}
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                {column.displayText}
                                            </th>
                                        </>
                                    ))}
                                    {props.actionText && <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">Edit</span>
                                    </th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.rows.map((row, rowIdx) => (
                                    <tr key={rowIdx} className={rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                        {props.columns.map((column, colIdx) => (
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                                                {row[column.name] ? row[column.name] : '--'}
                                            </td>
                                        ))}
                                        {props.actionText && <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <Link to={`/${props.module}/${row.id}`} key={rowIdx} className="text-indigo-600 hover:text-indigo-800">
                                                {props.actionText}
                                            </Link>
                                        </td>}
                                    </tr>
                                ))}
                            </tbody>
                            {
                                props.pages ? <tfoot>
                                    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                                        <div className="flex-1 flex justify-between sm:hidden">
                                            <a
                                                href="#"
                                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                            >
                                                Previous
                                            </a>
                                            <a
                                                href="#"
                                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                            >
                                                Next
                                            </a>
                                        </div>
                                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                            <div>
                                                <p className="text-sm text-gray-700">
                                                    Mostrando <span className="font-medium">{props.start}</span> to <span className="font-medium">{props.end}</span> of{' '}
                                                    <span className="font-medium">{props.total}</span> resultados
                                                </p>
                                            </div>
                                            <div>
                                                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                                    <a
                                                        href="#"
                                                        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                                    >
                                                        <span className="sr-only">Previous</span>
                                                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                                    </a>
                                                    {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                                                    {props.pages?.map((element) => {
                                                        return (
                                                            <button
                                                                key={element.number}
                                                                type="button"
                                                                aria-current={element.current}
                                                                className={element.current ?
                                                                    "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                                    : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                                }
                                                            >
                                                                {element.number}
                                                            </button>
                                                        )
                                                    })}
                                                    <a
                                                        href="#"
                                                        className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                                    >
                                                        <span className="sr-only">Next</span>
                                                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </tfoot>
                                    : null
                            }
                        </table>
                    </div>
                </div>
            </div>
        </div >
    )

}