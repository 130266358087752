import { React } from 'react';

export default function SelectInput(props) {
    return (
        <div className={props.containerClassName}>
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <select
                value={props.value}
                id={props.name}
                name={props.name}
                disabled={props.disabled}
                onChange={(event) => props.idx != null ? props.handleChange(props.name, event.target.value, props.idx) : props.handleChange(props.name, event.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue={props.defaultValue}
            >
                {props.options}
            </select>
            <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>
        </div>
    )
}