import React, { useEffect, useState } from "react";
import Dropzone from 'react-dropzone';

export default function FileInput(props) {

    const [file, setFile] = useState(props.defaultValue ? [props.defaultValue,] : null)

    return (
        <div className={props.containerClassName}>
            <label htmlFor={props.name} className="block text-sm leading-5 font-medium text-gray-700 mb-2">
                {props.label}
            </label>
            <Dropzone onDrop={acceptedFiles => { setFile(acceptedFiles); props.handleChange(acceptedFiles, props.name) }}>
                {({ getRootProps, open, getInputProps }) => (
                    <div {...getRootProps()}>
                        <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md pointer-events-none">
                            <input {...getInputProps()} />
                            <div className="space-y-1 text-center">
                                {file || props.prevPicture ?
                                    <img alt="not found" className="h-20 overflow-hidden bg-gray-100"
                                        src={props.prevPicture ? props.prevPicture : URL.createObjectURL(file[0])}
                                    />
                                    : <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>}
                                <div className="flex text-sm text-gray-600">
                                    <label
                                        htmlFor={props.name}
                                        className="relative cursor-pointer pointer-events-auto bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                        <span onClick={open}>Subir un archivo</span>

                                    </label>
                                    <p className="pl-1">o arrastrar</p>
                                </div>
                                <p className="text-xs text-gray-500">PNG, JPG hasta 10MB</p>
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>
            <p className="mt-2 text-sm text-red-600" id={`${props.name}-error`}>
                {props.errorMessage}
            </p>
        </div>
    )
}