function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function InlineCheckbox(props) {
    return (
        <div className={classNames("space-y-5", props.containerClassName)}>
            <legend className="sr-only">{props.name}</legend>
            <div className="relative flex items-start">
                <div className="flex items-center h-5">
                    <input
                        id={props.id}
                        onChange={(event) => props.idx != null ? props.handleChange(props.name, event.target.checked, props.idx) : props.handleChange(props.name, event.target.checked)}
                        aria-describedby={`${props.name}-description`}
                        name={props.name}
                        defaultChecked={props.value}
                        disabled={props.disabled}
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                </div>
                <div className="ml-3 text-sm">
                    <label htmlFor={props.name} className="font-medium text-gray-700">
                        {props.label}
                    </label>
                    <span id={`${props.name}-description`} className="text-gray-500">
                        <span className="sr-only">{props.name}</span>{props.description}
                    </span>
                </div>
            </div>
        </div>
    )
}
