import React from "react"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Tabs(props) {

    const changeTab = ((value) => {
        props.tabs[props.tabs.findIndex((tab) => tab.current)].current = false
        props.tabs[props.tabs.findIndex((tab) => tab.name === value)].current = true
        props.setTab(props.tabs[props.tabs.findIndex((tab) => tab.current)].name)
    })

    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    defaultValue={props.tabs.find((tab) => tab.current).name}
                >
                    {props.tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {props.tabs.map((tab) => (
                            <button
                                key={tab.name}
                                className={classNames(
                                    tab.current
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                                    props.fullWidth ? 'w-full' : '',
                                )}
                                onClick={() => changeTab(tab.name)}
                                aria-current={tab.current ? 'page' : undefined}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    )
}