import { ChevronLeftIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import Sidebar from '../../components/Sidebar';
import Textarea from '../../components/Textarea';
import TextInput from '../../components/TextInput';

export default function ConfigurationsPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [vigidit, setVigidit] = useState({
        startVideo: '',
        startMessage: '',
        sources: '',
        disclaimer: '',
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (field, value) =>
        setVigidit((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        api.configurations
            .update(
                'vigidit', 
                {key: 'vigidit', value: JSON.stringify(vigidit)},
                false
            )
            .then((response) => {
                setLoading(false);
                setMessage('Configuraciones guardadas');
                setTimeout(() => setMessage(''), 4000);
            })
            .catch((e) => {
                setLoading(false);
                return Promise.reject(e.detail);
            });
    }

    useEffect(() => {
        api.configurations.getOne('vigidit').then(response => {
            setVigidit(JSON.parse(response.value));
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [])

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar
                current={"Vigidit"}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ? (
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                ) : (
                    <main className="px-8">
                        {
                            message.length > 1 ?
                            <div className='my-4 p-2 rounded-md bg-green-600 font-semibold text-sm text-white'>
                                {message}
                            </div> : ''
                        }
                        <div className="flex gap-x-2 cursor-pointer my-4" onClick={() => navigate(-1)}>
                            <ChevronLeftIcon className="w-4 text-gray-400" />
                            <span className="text-base text-gray-400">Volver</span>
                        </div>
                        <h1 className='text-3xl font-bold my-6'>Ajustes del VIGIDIT</h1>
                        <div className='mt-4'>
                            <TextInput 
                                defaultValue={vigidit.startVideo}
                                handleChange={handleChange}
                                containerClassName="md:w-2/3 w-full"
                                name="startVideo"
                                label="URL del video de portada del VIGIDIT"
                            />
                        </div>
                        <div className='mt-4'>
                            <Textarea
                                defaultValue={vigidit.startMessage}
                                handleChange={handleChange}
                                containerClassName="md:w-2/3 w-full"
                                name="startMessage"
                                label="Mensaje de bienvenida del VIGIDIT"
                                rows="5"
                            />
                        </div>
                        <div className='mt-4'>
                            <Textarea
                                defaultValue={vigidit.sources}
                                handleChange={handleChange}
                                containerClassName="md:w-2/3 w-full"
                                name="sources"
                                label="Fuentes de información"
                                rows="5"
                            />
                        </div>
                        <div className='mt-4'>
                            <Textarea
                                defaultValue={vigidit.disclaimer}
                                handleChange={handleChange}
                                containerClassName="md:w-2/3 w-full"
                                name="disclaimer"
                                label="Notas aclaratorias"
                                rows="6"
                            />
                        </div>
                        <div className='my-4'>
                            <button
                                onClick={handleSubmit}
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                            >
                                Guardar configuraciones
                            </button>
                        </div>
                    </main>
                )}
            </div>
        </div>
    );
}