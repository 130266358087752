import React from "react";
import {
    TrashIcon
} from '@heroicons/react/outline'

export default function FileThumbnail(props) {
    return (
        <button type="button" class="bg-indigo-100 text-gray-600 font-medium py-2 px-4 rounded inline-flex items-center pointer-events-none">
            <span>{props.filename}</span>
            <TrashIcon onClick={() => props.delete(props.idx)} className="ml-6 h-5 w-5 text-indigo-700 pointer-events-auto" aria-hidden="true" />
        </button>
    )
}