import React, { useEffect } from "react";
import { useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import {
    MenuAlt2Icon,
} from '@heroicons/react/outline'
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import Tabs from "../../components/Tabs";
import BattelleItem from "../../components/BattelleItem";
import api from "../../api";

const status = [
    "Pendiente",
    "Admitido",
    "No admitido",
    "Alta médica"
]

const PersonalSocial = [
    { age: '0-5', item: 'TS 1', behavior: 'Muestra conocimiento de sus manos.' },
    { age: '0-5', item: 'TS 2', behavior: 'Muestra deseos de ser cogido en brazos por una persona conocida.' },
    { age: '6-11', item: 'TS 3', behavior: 'Participa en juegos como «cucú» o el «escondite».' },
    { age: '6-11', item: 'TS 4', behavior: 'Responde a su nombre.' },
    { age: '12-17', item: 'TS 5', behavior: 'Inicia contacto social con compañeros.' },
    { age: '12-17', item: 'TS 6', behavior: 'Imita a otro niño.' },
    { age: '18-23', item: 'TS 7', behavior: 'Sigue normas de la vida cotidiana.' },
    { age: '18-23', item: 'TS 8', behavior: 'Juega solo junto a otros compañeros.' },
    { age: '24-35', item: 'TS 9', behavior: 'Conoce su nombre.' },
    { age: '24-35', item: 'TS 10', behavior: 'Utiliza un pronombre o su nombre para referirse a sí mismo.' },
    { age: '36-47', item: 'TS 11', behavior: 'Reconoce las diferencias entre hombre y mujer.' },
    { age: '36-47', item: 'TS 12', behavior: 'Responde al contacto social de adultos conocidos.' },
    { age: '48-59', item: 'TS 13', behavior: 'Describe sus sentimientos.' },
    { age: '48-59', item: 'TS 14', behavior: 'Escoge a sus amigos.' },
    { age: '60-71', item: 'TS 15', behavior: 'Participa en juegos competitivos.' },
    { age: '60-71', item: 'TS 16', behavior: 'Distingue las conductas aceptables de las no aceptables.' },
    { age: '72-83', item: 'TS 17', behavior: 'Actúa como líder en las relaciones con los compañeros.' },
    { age: '72-83', item: 'TS 18', behavior: 'Pide ayuda al adulto cuando lo necesita.' },
    { age: '84-95', item: 'TS 19', behavior: 'Utiliza al adulto para defenderse.' },
    { age: '84-95', item: 'TS 20', behavior: 'Reconoce la responsabilidad de sus errores.' },
]

const Adaptive = [
    { age: '0-5', item: 'TS 21', behavior: 'Come papilla con cuchara.' },
    { age: '0-5', item: 'TS 22', behavior: 'Presta atención a un sonido continuo.' },
    { age: '6-11', item: 'TS 23', behavior: 'Sostiene su biberón.' },
    { age: '6-11', item: 'TS 24', behavior: 'Come trocitos de comida.' },
    { age: '12-17', item: 'TS 25', behavior: 'Comienza a usar la cuchara o el tenedor para comer.' },
    { age: '12-17', item: 'TS 26', behavior: 'Se quita ropas de prenda pequeñas.' },
    { age: '18-23', item: 'TS 27', behavior: 'Distingue los comestible de lo no comestible.' },
    { age: '18-23', item: 'TS 28', behavior: 'Se quita una prenda de ropa.' },
    { age: '24-35', item: 'TS 29', behavior: 'Indica la necesidad de ir al lavado.' },
    { age: '24-35', item: 'TS 30', behavior: 'Obtiene el agua del grifo.' },
    { age: '36-47', item: 'TS 31', behavior: 'Se abrocha uno o dos botones.' },
    { age: '36-47', item: 'TS 32', behavior: 'Duerme sin mojar la cama.' },
    { age: '48-59', item: 'TS 33', behavior: 'Se viste y se desnuda' },
    { age: '48-59', item: 'TS 34', behavior: 'Completa tareas de dos acciones.' },
    { age: '60-71', item: 'TS 35', behavior: 'Va al colegio solo.' },
    { age: '60-71', item: 'TS 36', behavior: 'Contesta preguntas del tipo: «¿Qué harías si...?»' },
    { age: '72-83', item: 'TS 37', behavior: 'Conoce su dirección.' },
    { age: '72-83', item: 'TS 38', behavior: 'Utiliza el teléfono.' },
    { age: '84-95', item: 'TS 39', behavior: 'Manejas pequeñas cantidades de dinero.' },
    { age: '84-95', item: 'TS 40', behavior: 'Realiza tareas domésticas.' },
]

const GrossMotor = [
    { age: '0-5', item: 'TS 41', behavior: 'Se lleva un objeto a la boca.' },
    { age: '6-11', item: 'TS 43', behavior: 'Gatea.' },
    { age: '12-17', item: 'TS 45', behavior: 'Sube escaleras con ayuda.' },
    { age: '18-23', item: 'TS 47', behavior: 'Sube y baja las escaleras sin ayuda, colocando ambos pies en cada escalón.' },
    { age: '24-35', item: 'TS 49', behavior: 'Salta con los pies juntos.' },
    { age: '48-59', item: 'TS 53', behavior: 'Recorre tres metros saltando sobre un pie.' },
    { age: '60-71', item: 'TS 55', behavior: 'Se mantiene sobre un solo pie alternativamente, con los ojos cerrados. ' },
    { age: '72-83', item: 'TS 57', behavior: 'Anda por la línea «punta-tacón»' },
    { age: '84-95', item: 'TS 59', behavior: 'Salta a la cuerda.' },
]

const FineMotor = [
    { age: '0-5', item: 'TS 42', behavior: 'Toca un objeto.' },
    { age: '6-11', item: 'TS 44', behavior: 'Coge un caramelo con varios dedos en oposición al pulgar (presión digital-parcial).' },
    { age: '12-17', item: 'TS 46', behavior: 'Coge un caramelo con los dedos índice y pulgar (pinza superior).' },
    { age: '18-23', item: 'TS 48', behavior: 'Mete anillas en un soporte.' },
    { age: '24-35', item: 'TS 50', behavior: 'Abre una puerta.' },
    { age: '36-47', item: 'TS 51', behavior: 'Corta con tijeras.' },
    { age: '36-47', item: 'TS 52', behavior: 'Dobla dos veces un papel.' },
    { age: '48-59', item: 'TS 54', behavior: 'Copia un triángulo.' },
    { age: '60-71', item: 'TS 56', behavior: 'Copia los números del 1 al 5.' },
    { age: '72-83', item: 'TS 58', behavior: 'Copia palabras con letras mayúsculas y minúsculas.' },
    { age: '84-95', item: 'TS 60', behavior: 'Copia un triángulo inscrito en otro triángulo.' },
]

const ReceptiveCommunication = [
    { age: '0-5', item: 'TS 61', behavior: 'Vuelve la cabeza hacia un sonido.' },
    { age: '6-11', item: 'TS 63', behavior: 'Asocia palabras con acciones u objetos.' },
    { age: '12-23', item: 'TS 65', behavior: 'Sigue órdenes acompañadas de gestos.' },
    { age: '24-35', item: 'TS 67', behavior: 'Comprende los conceptos «dentro, fuera, encima, delante, detrás, hacia».' },
    { age: '36-47', item: 'TS 69', behavior: 'Sigue órdenes verbales que implican dos acciones.' },
    { age: '48-59', item: 'TS 71', behavior: 'Comprende el plural.' },
    { age: '60-71', item: 'TS 73', behavior: 'Comprende el futuro de los verbos ser y estar.' },
    { age: '72-83', item: 'TS 75', behavior: 'Reconoce palabras que no pertenecen a una categoría.' },
    { age: '84-95', item: 'TS 77', behavior: 'Comprende los conceptos: dulce, duro y brillante.' },
]

const ExpressiveCommunication = [
    { age: '0-5', item: 'TS 62', behavior: 'Emite sonidos para expresar sus estados de ánimo.' },
    { age: '6-11', item: 'TS 64', behavior: 'Emite sonidos consonante-vocal.' },
    { age: '12-23', item: 'TS 66', behavior: 'Utiliza diez o más palabras. ' },
    { age: '24-35', item: 'TS 68', behavior: 'Utiliza los pronombres «yo», «tú» y «mí».' },
    { age: '36-47', item: 'TS 70', behavior: 'Utiliza el plural términado en «s».' },
    { age: '48-59', item: 'TS 72', behavior: 'Utiliza frases de 5 ó 6 palabras.' },
    { age: '60-71', item: 'TS 74', behavior: 'Utiliza el comparativo.' },
    { age: '72-83', item: 'TS 76', behavior: 'Habla sobre cosas que pueden suceder.' },
    { age: '84-95', item: 'TS 78', behavior: 'Define palabras.' },
]

const Cognitive = [
    { age: '0-5', item: 'TS 79', behavior: 'Sigue un estímulo visual.' },
    { age: '0-5', item: 'TS 80', behavior: 'Explora objetos.' },
    { age: '6-11', item: 'TS 81', behavior: 'Levanta una taza para conseguir un juguete.' },
    { age: '6-11', item: 'TS 82', behavior: 'Busca un objeto desaparecido.' },
    { age: '12-23', item: 'TS 83', behavior: 'Extiende los brazos para obtener un juguete colocado detrás de una barrera.' },
    { age: '12-23', item: 'TS 84', behavior: 'Se reconoce a sí mismo como causa de acontecimientos.' },
    { age: '24-35', item: 'TS 85', behavior: 'Empareja un círculo, un cuadrado y un triángulo.' },
    { age: '24-35', item: 'TS 86', behavior: 'Repite secuencias de dos dígitos.' },
    { age: '36-47', item: 'TS 87', behavior: 'Identifica los tamaños grande y pequeño.' },
    { age: '36-47', item: 'TS 88', behavior: 'Identifica objetos sencillos por el tacto.' },
    { age: '48-59', item: 'TS 89', behavior: 'Responde a preguntas lógicas sencillas.' },
    { age: '48-59', item: 'TS 90', behavior: 'Completa analogías opuestas.' },
    { age: '60-71', item: 'TS 91', behavior: 'Identifica colores.' },
    { age: '60-71', item: 'TS 92', behavior: 'Identifica los objetos primero y último de una fila.' },
    { age: '72-83', item: 'TS 93', behavior: 'Recuerda hechos de una historia contada.' },
    { age: '72-83', item: 'TS 94', behavior: 'Resuelve sumas y restas sencillas (números del 0 al 5)' },
    { age: '84-95', item: 'TS 95', behavior: 'Resuelve problemas sencillos, presentados oralmente, que incluyen la sustracción.' },
    { age: '84-95', item: 'TS 96', behavior: 'Resuelve multiplicaciones sencillas.' },
]

export default function BattelleScreeningPage() {

    const today = new Date().toISOString().substring(0, 10)
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { idPatient } = useParams()
    const [patient, setPatient] = useState({});
    const [screening, setScreening] = useState({
        kid: idPatient,
        answers: [],
        date: today,
    })
    const [results, setResults] = useState([]);
    const [tab, setTab] = useState('Personal/social')
    const [answers, setAnswers] = useState([])
    const handleAnswer = (item, field, value) => {
        let obj = answers;
        if (obj.filter((answer) => answer.question === item).length !== 0) {
            obj[obj.findIndex((answer) => answer.question === item)][field] = value;
            setAnswers(obj);
        } else {
            let index = obj.push({
                question: item,
                score: 0,
                obs: ''
            });
            obj[index - 1][field] = value;
            setAnswers(obj);
        }
    };

    const [tabs, setTabs] = useState([{ name: 'Personal/social', current: true },
    { name: 'Adaptativa', current: false },
    { name: 'Motora gruesa', current: false },
    { name: 'Motora fina', current: false },
    { name: 'Comunicación receptiva', current: false },
    { name: 'Comunicación expresiva', current: false },
    { name: 'Cognitiva', current: false }
    ])

    useEffect(() => {
        setLoading(true)
        api.patients.getOne(idPatient).then((response) => {
            setPatient(response);
        }).catch((error) => console.log(error))
        api.screening.getMulti(null, null, null, null, { kid: idPatient }).then((response) => {
            if (response.length !== 0) {
                setResults(response[response.length - 1])
            } else {
                setTabs([{ name: 'Personal/social', current: true },
                { name: 'Adaptativa', current: false },
                { name: 'Motora gruesa', current: false },
                { name: 'Motora fina', current: false },
                { name: 'Comunicación receptiva', current: false },
                { name: 'Comunicación expresiva', current: false },
                { name: 'Cognitiva', current: false }
                ])
                setTab('Personal/social')
            }
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        setTabs([{ name: 'Resumen', current: true }])
        setTab('Resumen')
    }, [results])

    useEffect(() => {
        setScreening((prev) => ({ ...prev, answers: answers }))
    }, [answers])

    const handleSubmit = () => {
        setButtonLoading(true)
        api.screening.create(screening).then((response) => {
            setResults(response);
            setButtonLoading(false);
        })
            .catch((e) => {
                setLoading(false);
                setButtonLoading(false);
                return Promise.reject(e.detail);
            });
    }

    const pages = [
        { name: 'Niños usuarios', href: '/patients', current: false },
        { name: `${patient.fullName} | ${patient.age} meses | ${status[patient.status]}`, href: `/patients/${idPatient}`, current: false },
        { name: 'Registro de Battelle Screening', href: '#', current: true }
    ]

    return (
        <div className="bg-gray-100 min-h-screen h-full">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="flex min-h-screen h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                </div> :
                <div className="md:pl-64 flex flex-col flex-1">
                    <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                        <button
                            type="button"
                            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="flex flex-col flex-1 ml-6 mt-6">
                        <div className="flex justify-between">
                            <Breadcrumbs pages={pages} />
                            {results.length !== 0 ?
                                <Link
                                    to={`/patients/${idPatient}`}
                                    type="button"
                                    className="inline-flex mr-10 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    Volver al perfil
                                </Link>
                                : <button onClick={() => handleSubmit()} className="inline-flex mr-10 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                                    {buttonLoading ?
                                        <div className="px-10">
                                            <div className="w-5 h-5 border-b-2 border-white rounded-full animate-spin"></div>
                                        </div>
                                        :
                                        <>Finalizar</>
                                    }
                                </button>}
                        </div>
                        <div className="mt-4 ml-4">
                            <Tabs tabs={tabs} setTab={setTab} />
                        </div>
                    </div>
                    <main className=" bg-white max-w-5xl px-4 ml-10">
                        <h1 className="mt-10 text-lg font-semibold leading-5 text-gray-900">Registro de Battelle Screening</h1>
                        {results.length !== 0 ? <p className="font-medium leading-7 text-gray-700"> Fecha realizado: {results.date}</p> : null}
                        <ul role="list" className="mt-4 divide-y divide-gray-200">
                            {tab !== 'Resumen' ? <li key={1} className="grid grid-cols-5 gap-24 font-bold leading-7 text-gray-700 py-4">
                                <p>Edad</p>
                                <p>Item</p>
                                <p>Conducta</p>
                                <p>Puntuación</p>
                                <p>Obs.</p>
                            </li> :
                                <>
                                    <li key={1} className="grid grid-cols-6 gap-x-20 font-bold leading-7 text-gray-700 py-4">
                                        <p>Área</p>
                                        <p>Puntuación directa</p>
                                        <p>Nivel de criterio</p>
                                        <p>Procedimiento</p>
                                        <p>Superado/No superado</p>
                                        <p>Edad equivalente (meses)</p>
                                    </li>
                                    <li key={2} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Personal/social</p>
                                        <p>{results.personalSocialAreaDirectScore ? results.personalSocialAreaDirectScore : '--'}</p>
                                        <p>{results.personalSocialAreaCriteriaLevel ? results.personalSocialAreaCriteriaLevel : '--'}</p>
                                        <p>{results.personalSocialAreaCriteriaScore ? results.personalSocialAreaCriteriaScore : '--'}</p>
                                        <p>{results.personalSocialAreaDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.personalSocialAreaEquivalentAge ? results.personalSocialAreaEquivalentAge : '--'}</p>
                                    </li>
                                    <li key={3} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Adaptativa</p>
                                        <p>{results.adaptiveAreaDirectScore ? results.adaptiveAreaDirectScore : '--'}</p>
                                        <p>{results.adaptiveAreaCriteriaLevel ? results.adaptiveAreaCriteriaLevel : '--'}</p>
                                        <p>{results.adaptiveAreaCriteriaScore ? results.adaptiveAreaCriteriaScore : '--'}</p>
                                        <p>{results.adaptiveAreaDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.adaptiveAreaEquivalentAge ? results.adaptiveAreaEquivalentAge : '--'}</p>
                                    </li>
                                    <li key={4} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Motora gruesa</p>
                                        <p>{results.thickMotorSubareaDirectScore ? results.thickMotorSubareaDirectScore : '--'}</p>
                                        <p>{results.thickMotorSubareaCriteriaLevel ? results.thickMotorSubareaCriteriaLevel : '--'}</p>
                                        <p>{results.thickMotorSubareaCriteriaScore ? results.thickMotorSubareaCriteriaScore : '--'}</p>
                                        <p>{results.thickMotorSubareaDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.thickMotorSubareaEquivalentAge ? results.thickMotorSubareaEquivalentAge : '--'}</p>
                                    </li>
                                    <li key={5} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Motora fina</p>
                                        <p>{results.thinMotorSubareaDirectScore ? results.thinMotorSubareaDirectScore : '--'}</p>
                                        <p>{results.thinMotorSubareaCriteriaLevel ? results.thinMotorSubareaCriteriaLevel : '--'}</p>
                                        <p>{results.thinMotorSubareaCriteriaScore ? results.thinMotorSubareaCriteriaScore : '--'}</p>
                                        <p>{results.thinMotorSubareaDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.thinMotorSubareaEquivalentAge ? results.thinMotorSubareaEquivalentAge : '--'}</p>
                                    </li>
                                    <li key={6} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Motora</p>
                                        <p>{results.motorAreaDirectScore ? results.motorAreaDirectScore : '--'}</p>
                                        <p>{results.motorAreaCriteriaLevel ? results.motorAreaCriteriaLevel : '--'}</p>
                                        <p>{results.motorAreaCriteriaScore ? results.motorAreaCriteriaScore : '--'}</p>
                                        <p>{results.motorAreaDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.motorAreaEquivalentAge ? results.motorAreaEquivalentAge : '--'}</p>
                                    </li>
                                    <li key={7} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Receptiva</p>
                                        <p>{results.receptiveSubareaDirectScore ? results.receptiveSubareaDirectScore : '--'}</p>
                                        <p>{results.receptiveSubareaCriteriaLevel ? results.receptiveSubareaCriteriaLevel : '--'}</p>
                                        <p>{results.receptiveSubareaCriteriaScore ? results.receptiveSubareaCriteriaScore : '--'}</p>
                                        <p>{results.receptiveSubareaDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.receptiveSubareaEquivalentAge ? results.receptiveSubareaEquivalentAge : '--'}</p>
                                    </li>
                                    <li key={8} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Expresiva</p>
                                        <p>{results.expressiveSubareaDirectScore ? results.expressiveSubareaDirectScore : '--'}</p>
                                        <p>{results.expressiveSubareaCriteriaLevel ? results.expressiveSubareaCriteriaLevel : '--'}</p>
                                        <p>{results.expressiveSubareaCriteriaScore ? results.expressiveSubareaCriteriaScore : '--'}</p>
                                        <p>{results.expressiveSubareaDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.expressiveSubareaEquivalentAge ? results.expressiveSubareaEquivalentAge : '--'}</p>
                                    </li>
                                    <li key={9} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Comunicación</p>
                                        <p>{results.communicationAreaDirectScore ? results.communicationAreaDirectScore : '--'}</p>
                                        <p>{results.communicationAreaCriteriaLevel ? results.communicationAreaCriteriaLevel : '--'}</p>
                                        <p>{results.communicationAreaCriteriaScore ? results.communicationAreaCriteriaScore : '--'}</p>
                                        <p>{results.communicationAreaDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.communicationAreaEquivalentAge ? results.communicationAreaEquivalentAge : '--'}</p>
                                    </li>
                                    <li key={10} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Cognitiva</p>
                                        <p>{results.cognitiveAreaDirectScore ? results.cognitiveAreaDirectScore : '--'}</p>
                                        <p>{results.cognitiveAreaCriteriaLevel ? results.cognitiveAreaCriteriaLevel : '--'}</p>
                                        <p>{results.cognitiveAreaCriteriaScore ? results.cognitiveAreaCriteriaScore : '--'}</p>
                                        <p>{results.cognitiveAreaDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.cognitiveAreaEquivalentAge ? results.cognitiveAreaEquivalentAge : '--'}</p>
                                    </li>
                                    <li key={11} className="grid grid-cols-6 gap-24 font-medium leading-7 text-gray-700 py-4">
                                        <p className="text-indigo-600">Total</p>
                                        <p>{results.directTotalScore ? results.directTotalScore : '--'}</p>
                                        <p>{results.totalCriteriaLevel ? results.totalCriteriaLevel : '--'}</p>
                                        <p>{results.totalCriteriaScore ? results.totalCriteriaScore : '--'}</p>
                                        <p>{results.finalDecision === 1 ? 'Superado' : 'No superado'}</p>
                                        <p>{results.finalEquivalentAge ? results.finalEquivalentAge : '--'}</p>
                                    </li>
                                </>
                            }
                            {tab === 'Personal/social' && PersonalSocial.map(item => {
                                return (
                                    <BattelleItem defaultObservation={answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={handleAnswer} age={item.age} item={item.item} behavior={item.behavior} />
                                )
                            })}
                            {tab === 'Adaptativa' && Adaptive.map(item => {
                                return (
                                    <BattelleItem defaultObservation={answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={handleAnswer} age={item.age} item={item.item} behavior={item.behavior} />
                                )
                            })}
                            {tab === 'Motora gruesa' && GrossMotor.map(item => {
                                return (
                                    <BattelleItem defaultObservation={answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={handleAnswer} age={item.age} item={item.item} behavior={item.behavior} />
                                )
                            })}
                            {tab === 'Motora fina' && FineMotor.map(item => {
                                return (
                                    <BattelleItem defaultObservation={answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={handleAnswer} age={item.age} item={item.item} behavior={item.behavior} />
                                )
                            })}
                            {tab === 'Comunicación receptiva' && ReceptiveCommunication.map(item => {
                                return (
                                    <BattelleItem defaultObservation={answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={handleAnswer} age={item.age} item={item.item} behavior={item.behavior} />
                                )
                            })}
                            {tab === 'Comunicación expresiva' && ExpressiveCommunication.map(item => {
                                return (
                                    <BattelleItem defaultObservation={answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={handleAnswer} age={item.age} item={item.item} behavior={item.behavior} />
                                )
                            })}
                            {tab === 'Cognitiva' && Cognitive.map(item => {
                                return (
                                    <BattelleItem defaultObservation={answers.filter((answer) => answer.question === item.item)[0]?.obs} defaultScore={answers.filter((answer) => answer.question === item.item)[0]?.score} handleChange={handleAnswer} age={item.age} item={item.item} behavior={item.behavior} />
                                )
                            })}
                        </ul>
                    </main>
                </div>
            }
        </div >
    );
}
