import InlineCheckbox from './InlineCheckbox';
import DateInput from './DateInput';
import { LocationMarkerIcon } from '@heroicons/react/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SessionList(props) {
    return (
        <div className={classNames("bg-white shadow overflow-hidden sm:rounded-md", props.containerClassName)}>
            <div className="block">
                <div className="px-4 py-4 sm:px-6">
                    <ul className="divide-y divide-gray-200">
                        <div className="flex items-center pb-4 gap-x-20">
                            <div className='flex gap-x-2'>
                                <p className="text-md font-semibold text-gray-700 truncate">Sesión {props.id}: </p>
                                <p className="text-md font-medium text-gray-700 truncate">{props.date}</p>
                                <p className="text-md font-medium text-gray-700 truncate mr-4">{props.startTime.substring(0, 5)} - {props.endTime.substring(0, 5)}</p>
                            </div>
                            <div className='flex gap-x-2'>
                                <p className="text-md font-semibold text-gray-700 justify-end">Profesional: </p>
                                <p className="text-md font-medium text-gray-700 truncate">{props.doctor}</p>

                            </div>
                        </div>
                        <div className="pt-4 gap-x-4 flex">
                            <p className='text-md font-medium text-gray-700 truncate'>{props.followUp ? 'Hacer seguimiento' : props.prepareReport ? 'Elaborar informe' : props.meetingWithParents ? 'Reunión con los padres' : ""}</p>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    )
}
