import React from "react";
import { useState, useEffect } from 'react'
import {
    MenuAlt2Icon,
} from '@heroicons/react/outline'
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/TextInput";
import Objective from "../../components/Objective";
import SessionList from "../../components/SessionList";
import Tabs from "../../components/Tabs";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";
import Calendar from "../../components/Calendar";
import EventModal from "../../components/EventModal";
import CustomCombobox from "../../components/Combobox";
import Badge from "../../components/Badge";

const personalSocialBehaviors = [
    { subarea: 0, behavior: 'Muestra conocimiento de la gente.' },
    { subarea: 0, behavior: 'Mira la cara del adulto.' },
    { subarea: 0, behavior: 'Sonríe o vocaliza en respuesta a la atención del adulto.' },
    { subarea: 0, behavior: 'Explora las facciones del adulto.' },
    { subarea: 0, behavior: 'Muestra deseos de ser cogido en brazos por una persona conocida' },
    { subarea: 0, behavior: 'Muestra deseos de recibir atención.' },
    { subarea: 0, behavior: 'Participa en juegos como «cucú» o el «el escondite»' },
    { subarea: 0, behavior: 'Distingue las personas conocidas de las no conocidas.' },
    { subarea: 0, behavior: 'Sigue vocalizando cuando se le imita.' },
    { subarea: 0, behavior: 'Reacciona cuando se nombra a un familiar.' },
    { subarea: 0, behavior: 'Responde a las alabanzas, recompensas o promesas de recompensa del adulto.' },
    { subarea: 0, behavior: 'Ayuda en tareas domésticas sencillas.' },
    { subarea: 0, behavior: 'Saluda espóntaneamente a los adultos conocidos.' },
    { subarea: 0, behavior: 'Responde al contacto social de adultos conocidos.' },
    { subarea: 0, behavior: 'Se separa fácilmente de sus padres.' },
    { subarea: 0, behavior: 'Utiliza a los adultos (además de los padres), como recurso.' },
    { subarea: 0, behavior: 'Inicia contactos con adultos conocidos.' },
    { subarea: 0, behavior: 'Pide ayuda al adulto cuando lo necesita.' },
    { subarea: 1, behavior: 'Reacciona con anticipación.' },
    { subarea: 1, behavior: 'Muestra placer en juegos que implican movimientos bruscos.' },
    { subarea: 1, behavior: 'Expresa emociones.' },
    { subarea: 1, behavior: 'Muestra afecto por las personas, animales u objetos personales.' },
    { subarea: 1, behavior: 'Le gusta jugar con otros niños.' },
    { subarea: 1, behavior: 'Le gusta que le lean cuentos.' },
    { subarea: 1, behavior: 'Expresa cariño o simpatía hacia un compañero.' },
    { subarea: 1, behavior: 'Muestra entusiasmo en el trabajo o el juego.' },
    { subarea: 1, behavior: 'Muestra simpatía hacia los demás.' },
    { subarea: 1, behavior: 'Consuela a un compañero.' },
    { subarea: 1, behavior: 'Describe sus sentimientos.' },
    { subarea: 1, behavior: 'Muestra una actitud positiva hacia la escuela.' },
    { subarea: 2, behavior: 'Muestra conocimiento de sus manos.' },
    { subarea: 2, behavior: 'Responde a su nombre.' },
    { subarea: 2, behavior: 'Expresa propiedad o posesión.' },
    { subarea: 2, behavior: 'Se reconoce en el espejo.' },
    { subarea: 2, behavior: 'Se enorgullece de sus éxitos.' },
    { subarea: 2, behavior: 'Conoce su nombre.' },
    { subarea: 2, behavior: 'Utiliza un pronombre o su nombre para referirse a sí mismo.' },
    { subarea: 2, behavior: 'Habla positivamente de sí mismo.' },
    { subarea: 2, behavior: 'Conoce su edad.' },
    { subarea: 2, behavior: 'Atrae la atención de los demás sobre su actividad.' },
    { subarea: 2, behavior: 'Conoce su nombre y apellidos.' },
    { subarea: 2, behavior: 'Se «hace valer» socialmente.' },
    { subarea: 2, behavior: 'Actúa para los demás.' },
    { subarea: 2, behavior: 'Demuestra capacidad para explicar o contar alguna cosa sin demasiada vergüenza.' },
    { subarea: 3, behavior: 'Inicia un contacto social con compañeros.' },
    { subarea: 3, behavior: 'Imita a otro niño.' },
    { subarea: 3, behavior: 'Juega solo junto a otros compañeros.' },
    { subarea: 3, behavior: 'Juega al lado de otro niño.' },
    { subarea: 3, behavior: 'Participa en juegos de grupo.' },
    { subarea: 3, behavior: 'Comparte sus juguetes.' },
    { subarea: 3, behavior: 'Se relaciona con los compañeros.' },
    { subarea: 3, behavior: 'Tiene amigos.' },
    { subarea: 3, behavior: 'Escoge a sus amigos.' },
    { subarea: 3, behavior: 'Participa en el juego.' },
    { subarea: 3, behavior: 'Participa en actividades de grupo.' },
    { subarea: 3, behavior: 'Sabe compartir y esperar su turno.' },
    { subarea: 3, behavior: 'Inicia contactos sociales e interacciones.' },
    { subarea: 3, behavior: 'Participa en juegos competitivos.' },
    { subarea: 3, behavior: 'Utiliza a los compañeros para obtener ayuda.' },
    { subarea: 3, behavior: 'Da ideas a otros niños y aprueba las de los demás.' },
    { subarea: 3, behavior: 'Actúa como líder en las relaciones con los compañeros.' },
    { subarea: 4, behavior: 'Sigue normas de la vida cotidiana.' },
    { subarea: 4, behavior: 'Sigue las reglas dadas por un adulto.' },
    { subarea: 4, behavior: 'Obedece las órdenes del adulto.' },
    { subarea: 4, behavior: 'Obedece las normas y órdenes de la clase.' },
    { subarea: 4, behavior: 'Espera su turno para conseguir la atención del adulto.' },
    { subarea: 4, behavior: 'Busca alternativas para resolver un problema.' },
    { subarea: 4, behavior: 'Hace frente a las burlas y riñas.' },
    { subarea: 4, behavior: 'Participa en situaciones nuevas.' },
    { subarea: 4, behavior: 'Utiliza al adulto para defenderse.' },
    { subarea: 4, behavior: 'Se enfrenta a la agresión de un compañero.' },
    { subarea: 5, behavior: 'Juega representando papeles de adulto.' },
    { subarea: 5, behavior: 'Representa un papel.' },
    { subarea: 5, behavior: 'Sabe si es niño o niña.' },
    { subarea: 5, behavior: 'Reconoce las diferencias entre hombre y mujer.' },
    { subarea: 5, behavior: 'Reconoce expresiones faciales de sentimientos.' },
    { subarea: 5, behavior: 'Juega representando el papel de adulto.' },
    { subarea: 5, behavior: 'Ayuda cuando es necesario.' },
    { subarea: 5, behavior: 'Respeta las cosas de los demás.' },
    { subarea: 5, behavior: 'Pide permiso para utilizar las cosas de otro.' },
    { subarea: 5, behavior: 'Reconoce los sentimientos de los demás.' },
    { subarea: 5, behavior: 'Distingue las conductas aceptables de las no aceptables.' },
    { subarea: 5, behavior: 'Distingue roles presentes y futuros.' },
    { subarea: 5, behavior: 'Demuestra responsabilidad.' },
    { subarea: 5, behavior: 'Reconoce la responsabilidad de sus errores.' },
]

const adaptiveBehaviors = [
    { subarea: 6, behavior: 'Dirige su mirada hacia un foco de luz.' },
    { subarea: 6, behavior: 'Mira un objeto durante cinco segundos.' },
    { subarea: 6, behavior: 'Presta atención a un sonido contínuo.' },
    { subarea: 6, behavior: 'Sigue con la mirada una luz en un arco de 180°.' },
    { subarea: 6, behavior: 'Sigue con la mirada una luz en recorrido vertical.' },
    { subarea: 6, behavior: 'Se entretiene sin solicitar atención.' },
    { subarea: 6, behavior: 'Mira o señala un dibujo.' },
    { subarea: 6, behavior: 'Presta atención.' },
    { subarea: 6, behavior: 'Presta atención estando en grupo.' },
    { subarea: 6, behavior: 'Se concentra en su propia tarea.' },
    { subarea: 7, behavior: 'Reacciona anticipadamente a la comida.' },
    { subarea: 7, behavior: 'Come papilla con cuchara.' },
    { subarea: 7, behavior: 'Come semisólidos.' },
    { subarea: 7, behavior: 'Sostiene su biberón.' },
    { subarea: 7, behavior: 'Bebe en una taza con ayuda.' },
    { subarea: 7, behavior: 'Come trocitos de comida.' },
    { subarea: 7, behavior: 'Comienza a usar la cuchara o el tenedor para comer.' },
    { subarea: 7, behavior: 'Pide comida o bebida con palabras o gestos.' },
    { subarea: 7, behavior: 'Bebe en taza o vaso, sin ayuda.' },
    { subarea: 7, behavior: 'Utiliza la cuchara o el tenedor.' },
    { subarea: 7, behavior: 'Distingue lo comestible de lo no comestible.' },
    { subarea: 7, behavior: 'Obtiene agua del grifo.' },
    { subarea: 7, behavior: 'Se sirve comida.' },
    { subarea: 7, behavior: 'Utiliza el cuchillo.' },
    { subarea: 8, behavior: 'Se quita prendas de ropa pequeñas.' },
    { subarea: 8, behavior: 'Ayuda a vestirse.' },
    { subarea: 8, behavior: 'Se quita una prenda de ropa.' },
    { subarea: 8, behavior: 'Se pone ropa.' },
    { subarea: 8, behavior: 'Se pone el abrigo.' },
    { subarea: 8, behavior: 'Se desabrocha la ropa.' },
    { subarea: 8, behavior: 'Se pone los zapatos.' },
    { subarea: 8, behavior: 'Se abrocha uno o dos botones.' },
    { subarea: 8, behavior: 'Se viste y se desnuda.' },
    { subarea: 8, behavior: 'Elige su ropa adecuadamente.' },
    { subarea: 9, behavior: 'Se mueve independientemente.' },
    { subarea: 9, behavior: 'Evita peligros comunes.' },
    { subarea: 9, behavior: 'Sigue instrucciones dadas al grupo.' },
    { subarea: 9, behavior: 'Completa tareas de dos acciones.' },
    { subarea: 9, behavior: 'Se mueve por su entorno inmediato.' },
    { subarea: 9, behavior: 'Continúa una tarea con supervisión mínima.' },
    { subarea: 9, behavior: 'Contesta preguntas del tipo: «¿Qué harías si...?»' },
    { subarea: 9, behavior: 'Cruza la calle con precaución.' },
    { subarea: 9, behavior: 'Organiza sus propias actividades.' },
    { subarea: 9, behavior: 'Se acuesta sin ayuda.' },
    { subarea: 9, behavior: 'Contesta preguntas del tipo: «¿Qué harías si...?»' },
    { subarea: 9, behavior: 'Va al colegio solo.' },
    { subarea: 9, behavior: 'Compra en una tienda.' },
    { subarea: 9, behavior: 'Maneja pequeñas cantidades de dinero.' },
    { subarea: 9, behavior: 'Conoce su dirección. ' },
    { subarea: 9, behavior: 'Utiliza el teléfono.' },
    { subarea: 9, behavior: 'Realiza tareas domésticas.' },
    { subarea: 9, behavior: 'Utiliza herramientas.' },
    { subarea: 9, behavior: 'Administra su dinero.' },
    { subarea: 10, behavior: 'Indica la necesidad de ir al lavado.' },
    { subarea: 10, behavior: 'Controla esfínteres.' },
    { subarea: 10, behavior: 'Se lava y seca las manos.' },
    { subarea: 10, behavior: 'Duerme sin mojar la cama.' },
    { subarea: 10, behavior: 'Va al lavado con autonomía.' },
    { subarea: 10, behavior: 'Se baña o ducha.' },
]

const motorBehaviors = [
    { subarea: 11, behavior: 'Mantiene erguida la cabeza.' },
    { subarea: 11, behavior: 'Levanta la cabeza.' },
    { subarea: 11, behavior: 'Sentado con apoyo gira la cabeza a ambos lados.' },
    { subarea: 11, behavior: 'Permanece sentado momentáneamente, sin ayuda.' },
    { subarea: 11, behavior: 'Permanece en pie 10 segundos, apoyándose en algo estable.' },
    { subarea: 11, behavior: 'Permanece en pie sin ayuda.' },
    { subarea: 12, behavior: 'Junta las manos en la línea media.' },
    { subarea: 12, behavior: 'Se lleva un objeto a la boca.' },
    { subarea: 12, behavior: 'Se pone de pie apoyándose en un mueble.' },
    { subarea: 12, behavior: 'Se incorpora hasta la posición sentado.' },
    { subarea: 12, behavior: 'Camina llevando un objeto.' },
    { subarea: 12, behavior: 'Se agacha para coger un objeto.' },
    { subarea: 12, behavior: 'Lanza la pelota.' },
    { subarea: 12, behavior: 'Chuta la pelota.' },
    { subarea: 12, behavior: 'Avanza 2 ó 3 pasos siguiendo una línea.' },
    { subarea: 12, behavior: 'Se mantiene sobre un pie.' },
    { subarea: 12, behavior: 'Lanza la pelota para que la coja otra persona.' },
    { subarea: 12, behavior: 'Da una voltereta.' },
    { subarea: 12, behavior: 'Imita posturas con los brazos.' },
    { subarea: 12, behavior: 'Salta sobre un pie.' },
    { subarea: 12, behavior: 'Anda «punta-tacón».' },
    { subarea: 12, behavior: 'Recorre tres metros saltando sobre un pie.' },
    { subarea: 12, behavior: 'Coge una pelota.' },
    { subarea: 12, behavior: 'Se mantiene sobre un solo pie, alternativamente, con los ojos cerrados.' },
    { subarea: 12, behavior: 'Salta hacia adelante con los pies juntos.' },
    { subarea: 12, behavior: 'Se inclina y toca el suelo con las manos.' },
    { subarea: 12, behavior: 'Andar por una línea «punta-tacón».' },
    { subarea: 12, behavior: 'Lanza la pelota a una diana.' },
    { subarea: 12, behavior: 'Salta a la cuerda.' },
    { subarea: 12, behavior: 'Mantiene el equilibrio en cuclillas con los ojos cerrados.' },
    { subarea: 12, behavior: 'Coge la pelota con una mano.' },
    { subarea: 13, behavior: 'Comienza a dar pasos.' },
    { subarea: 13, behavior: 'Gatea.' },
    { subarea: 13, behavior: 'Camina con ayuda.' },
    { subarea: 13, behavior: 'Sube escaleras gateando.' },
    { subarea: 13, behavior: 'Camina sin ayuda.' },
    { subarea: 13, behavior: 'Se levanta sin ayuda.' },
    { subarea: 13, behavior: 'Sube escaleras con ayuda.' },
    { subarea: 13, behavior: 'Baja escaleras con ayuda.' },
    { subarea: 13, behavior: 'Corre tres metros sin caerse.' },
    { subarea: 13, behavior: 'Sube y baja escaleras sin ayuda, colocando ambos pies en cada escalón.' },
    { subarea: 13, behavior: 'Salta con los pies juntos.' },
    { subarea: 13, behavior: 'Baja escaleras alternando los pies.' },
    { subarea: 13, behavior: 'Brinca alternando los pies.' },
    { subarea: 14, behavior: 'Mantiene las manos predominantemente abiertas.' },
    { subarea: 14, behavior: 'Sostiene un objeto con los dedos y la palma de la mano (presión cúbito-palmar).' },
    { subarea: 14, behavior: 'Coge un caramelo con varios dedos en oposición al pulgar (presión digital parcial).' },
    { subarea: 14, behavior: 'Se pasa un objeto de una mano a otra.' },
    { subarea: 14, behavior: 'Abre cajones o armarios.' },
    { subarea: 14, behavior: 'Entrega un juguete.' },
    { subarea: 14, behavior: 'Coge un caramelo con los dedos índice y plugar (pinza superior).' },
    { subarea: 14, behavior: 'Abre una puerta.' },
    { subarea: 14, behavior: 'Ensarta 4 cuentas grandes.' },
    { subarea: 14, behavior: 'Pasa páginas de un libro.' },
    { subarea: 14, behavior: 'Sujeta el papel mientras dibuja.' },
    { subarea: 14, behavior: 'Dobla una hoja de papel por la mitad.' },
    { subarea: 14, behavior: 'Corta con tijeras.' },
    { subarea: 14, behavior: 'Dobla dos veces un papel.' },
    { subarea: 14, behavior: 'Abre un candado con llave.' },
    { subarea: 14, behavior: 'Hace una pelota arrugando papel.' },
    { subarea: 14, behavior: 'Hace un nudo.' },
    { subarea: 14, behavior: 'Se toca con el pulgar las yemas de los dedos de la mano.' },
    { subarea: 15, behavior: 'Toca un objeto.' },
    { subarea: 15, behavior: 'Mete la pastilla en la botella.' },
    { subarea: 15, behavior: 'Construye una torre de 2 bloques.' },
    { subarea: 15, behavior: 'Mete anillas en un soporte.' },
    { subarea: 15, behavior: 'Saca la pastilla de la botella.' },
    { subarea: 15, behavior: 'Copia una linea vertical.' },
    { subarea: 15, behavior: 'Copia un círculo.' },
    { subarea: 15, behavior: 'Copia una cruz.' },
    { subarea: 15, behavior: 'Corta con tijeras siguiendo una línea.' },
    { subarea: 15, behavior: 'Copia las letras V, H y T.' },
    { subarea: 15, behavior: 'Copia un triángulo.' },
    { subarea: 15, behavior: 'Dibuja una persona (incluyendo seis elementos).' },
    { subarea: 15, behavior: 'Copia un cuadrado.' },
    { subarea: 15, behavior: 'Copia palabras sencillas.' },
    { subarea: 15, behavior: 'Copia los números del 1 al 15.' },
    { subarea: 15, behavior: 'Copia palabras con letras mayúsculas y minúsculas.' },
    { subarea: 15, behavior: 'Copia flechas.' },
    { subarea: 15, behavior: 'Copia un rombo.' },
    { subarea: 15, behavior: 'Copia un triángulo inscrito en otro triángulo.' },
    { subarea: 15, behavior: 'Escribe una frase sencilla en letra cursiva.' },
]

const communicationBehaviors = [
    { subarea: 16, behavior: 'Reacciona a un sonido que está fuera de su campo visual.' },
    { subarea: 16, behavior: 'Reacciona a la voz.' },
    { subarea: 16, behavior: 'Vuelve la cabeza hacia un sonido.' },
    { subarea: 16, behavior: 'Reacciona a distintos tonos de voz.' },
    { subarea: 16, behavior: 'Asocia palabras con acciones y objetos.' },
    { subarea: 16, behavior: 'Sigue tres o más órdenes sencillas.' },
    { subarea: 16, behavior: 'Sigue órdenes acompañadas de gestos.' },
    { subarea: 16, behavior: 'Comprende los conceptos «dentro, fuera, encima, delante, detrás, hacia».' },
    { subarea: 16, behavior: 'Comprende formas posesivas sencillas.' },
    { subarea: 16, behavior: 'Comprende los adverbios flojo y fuerte.' },
    { subarea: 16, behavior: 'Sigue órdenes verbales que implican dos acciones.' },
    { subarea: 16, behavior: 'Comprende las expresiones «el más grande», y «el más largo».' },
    { subarea: 16, behavior: 'Responde a preguntas que incluyen las palabras:«qué, quién, dónde y cuándo».' },
    { subarea: 16, behavior: 'Distingue entre palabras reales y otras, fonéticamente similares, que no tienen sentido.' },
    { subarea: 16, behavior: 'Comprende negaciones sencillas.' },
    { subarea: 16, behavior: 'Comprende el plural.' },
    { subarea: 16, behavior: 'Comprende el pasado de los verbos ser y estar.' },
    { subarea: 16, behavior: 'Identifica palabras que riman.' },
    { subarea: 16, behavior: 'Relaciona palabras con imágenes.' },
    { subarea: 16, behavior: 'Recuerda hechos de una historia contada.' },
    { subarea: 16, behavior: 'Comprende el futuro de los verbos ser y estar.' },
    { subarea: 16, behavior: 'Reconoce palabras que no pertenecen a una categoría.' },
    { subarea: 16, behavior: 'Sigue órdenes que implican tres acciones.' },
    { subarea: 16, behavior: 'Identifica el sonido inicial de las palabras.' },
    { subarea: 16, behavior: 'Recuerda hechos de una historia contada.' },
    { subarea: 16, behavior: 'Identifica el sonido inicial de las palabras.' },
    { subarea: 16, behavior: 'Comprende los conceptos: dulce, duro y brillante.' },
    { subarea: 17, behavior: 'Emite sonidos vocálicos.' },
    { subarea: 17, behavior: 'Emite sonidos para expresar su estado de ánimo.' },
    { subarea: 17, behavior: 'Emite sonidos consonante-vocal.' },
    { subarea: 17, behavior: 'Emite cadenas silábicas.' },
    { subarea: 17, behavior: 'Utiliza gestos indicar sus necesidades.' },
    { subarea: 17, behavior: 'Imita sonidos de palabras.' },
    { subarea: 17, behavior: 'Utiliza diez o más palabras.' },
    { subarea: 17, behavior: 'Utiliza diferentes patrones de entonación.' },
    { subarea: 17, behavior: 'Emite sonidos, palabras o gestos asociados a objetos de su entorno.' },
    { subarea: 17, behavior: 'Utiliza los pronombres «yo», «tú» y «mí».' },
    { subarea: 17, behavior: 'Utiliza expresiones de dos palabras.' },
    { subarea: 17, behavior: 'Utiliza frases de tres palabras.' },
    { subarea: 17, behavior: 'Responde «sí» o «no» adecuadamente.' },
    { subarea: 17, behavior: 'Da nombre a su trabajo creativo.' },
    { subarea: 17, behavior: 'Formula preguntas utilizando las palabras: «qué, quién, dónde, por qué y cómo».' },
    { subarea: 17, behavior: 'Utiliza el plural terminado en «s».' },
    { subarea: 17, behavior: 'Habla sobre sus experiencias.' },
    { subarea: 17, behavior: 'Utiliza los artículos determinados e indeterminados (el, la, un, una).' },
    { subarea: 17, behavior: 'Utiliza el pado en verbos regulares.' },
    { subarea: 17, behavior: 'Repite palabras articulándolas correctamente.' },
    { subarea: 17, behavior: 'Utiliza frases de cinco o seis palabras.' },
    { subarea: 17, behavior: 'Se comunica adecuadamente.' },
    { subarea: 17, behavior: 'Interviene en una conversación.' },
    { subarea: 17, behavior: 'Utiliza el plural terminado en «es».' },
    { subarea: 17, behavior: 'Utilzia el pasado en verbos irregualres.' },
    { subarea: 17, behavior: 'Expresa sus sentimientos.' },
    { subarea: 17, behavior: 'Utiliza el comparativo' },
    { subarea: 17, behavior: 'Asocia una palabra con su definición.' },
    { subarea: 17, behavior: 'Utiliza el superlativo.' },
    { subarea: 17, behavior: 'Habla sobre cosas que pueden suceder.' },
    { subarea: 17, behavior: 'Define palabras.' },
    { subarea: 17, behavior: 'Nombra palabras de diversas categorías.' },
]

const cognitiveBehaviors = [
    { subarea: 18, behavior: 'Explora su entorno visualmente.' },
    { subarea: 18, behavior: 'Reacciona ante situaciones nuevas.' },
    { subarea: 18, behavior: 'Explora objetos.' },
    { subarea: 18, behavior: 'Explora o investiga el entorno.' },
    { subarea: 18, behavior: 'Coloca las piezas círculo y cuadrado en el tablero de encajes.' },
    { subarea: 18, behavior: 'Empareja formas geométricas sencillas.' },
    { subarea: 18, behavior: 'Empareja un círculo, un cuadrado y un triángulo.' },
    { subarea: 18, behavior: 'Identifica objetos sencillos por el tacto.' },
    { subarea: 18, behavior: 'Empareja palabras sencillas.' },
    { subarea: 18, behavior: 'Reconoce diferencias visuales entre números, formas geométricas y letras similares.' },
    { subarea: 19, behavior: 'Sigue un estímulo auditivo.' },
    { subarea: 19, behavior: 'Sigue un estímulo visual.' },
    { subarea: 19, behavior: 'Levanta una taza para conseguir un juguete.' },
    { subarea: 19, behavior: 'Busca un objeto desaparecido.' },
    { subarea: 19, behavior: 'Repite secuencias de dos dígitos.' },
    { subarea: 19, behavior: 'Elige la mano que esconde el juguete.' },
    { subarea: 19, behavior: 'Recuerda objetos familiares.' },
    { subarea: 19, behavior: 'Repite secuencias de cuatro dígitos.' },
    { subarea: 19, behavior: 'Recuerda hechos de una historia contada.' },
    { subarea: 19, behavior: 'Repite secuencias de seis dígitos.' },
    { subarea: 20, behavior: 'Tira de una cuerda para obtener un juguete.' },
    { subarea: 20, behavior: 'Extiende los brazos para obtener un juguete colocado detrás de una barrera.' },
    { subarea: 20, behavior: 'Responde a una orden dos veces consecutivas.' },
    { subarea: 20, behavior: 'Identifica quien realiza algunas actividades conocidas.' },
    { subarea: 20, behavior: 'Entrega tres objetos por indicación.' },
    { subarea: 20, behavior: 'Responde a preguntas lógicas sencillas.' },
    { subarea: 20, behavior: 'Completa analogías opuestas.' },
    { subarea: 20, behavior: 'Identifica el mayor de dos números.' },
    { subarea: 20, behavior: 'Selecciona palabras sencillas presentadas visualmente.' },
    { subarea: 20, behavior: 'Identifica las partes incompletas de un dibujo.' },
    { subarea: 20, behavior: 'Reconoce errores en dibujos absurdos.' },
    { subarea: 20, behavior: 'Escribe letras que representan sonidos.' },
    { subarea: 20, behavior: 'Ordena en secuencia historias corrientes.' },
    { subarea: 20, behavior: 'Resueve sumas y restas sencillas (números del 0 al 5).' },
    { subarea: 20, behavior: 'Resuelve problemas sencillos, presentados oralmente, que incluyen la sustracción.' },
    { subarea: 20, behavior: 'Resuelve multiplicaciones sencillas.' },
    { subarea: 21, behavior: 'Se reconoce a sí mismo como causa de acontecimientos.' },
    { subarea: 21, behavior: 'Identifica objetos familiares por su uso.' },
    { subarea: 21, behavior: 'Identifica los tamaños grande y pequeño.' },
    { subarea: 21, behavior: 'Identifica el más largo de dos palos.' },
    { subarea: 21, behavior: 'Clasifica objetos por su forma.' },
    { subarea: 21, behavior: 'Compara tamaños.' },
    { subarea: 21, behavior: 'Identifica texturas (suabe, lisa, rugosa).' },
    { subarea: 21, behavior: 'Identifica actividades presentes y pasadas.' },
    { subarea: 21, behavior: 'Identifica colores.' },
    { subarea: 21, behavior: 'Forma un círculo con cuatro piezas.' },
    { subarea: 21, behavior: 'Clasifica objetos por su función.' },
    { subarea: 21, behavior: 'Ordena cuadrados de menor a mayor.' },
    { subarea: 21, behavior: 'Identifica los objetos primero y último de una fila.' },
    { subarea: 21, behavior: 'Completa un puzzle de seis piezas que representa una persona.' },
    { subarea: 21, behavior: 'Conoce los lados derecho e izquierdo de su cuerpo.' },
    { subarea: 21, behavior: 'Identifica el objeto central de una fila.' },
    { subarea: 21, behavior: 'Dice las horas y las medias horas.' },
    { subarea: 21, behavior: 'Clasifica objetos por su forma y color.' },
    { subarea: 21, behavior: 'Conserva el espacio bidimensional.' },
    { subarea: 21, behavior: 'Conserva la longitud.' },
]

const tabs = [
    { name: 'Calendario', current: true },
    { name: 'Lista', current: false },
]

const colors = [
    "#2886d2",
    "#F0763D",
    "#84C557",
    "#6D28D9",
    "#808080"
]

const status = [
    "Pendiente",
    "Admitido",
    "No admitido",
    "Alta médica"
]

const InterventionPlan = function (props) {

    return (
        <>
            <div className="ml-4">
                <h1 className="font-semibold leading-7 text-gray-700">Plan de intervención</h1>
                <ul className="divide-y space-y-8 divide-gray-200">
                    <li key={0} className="grid grid-cols-7 gap-x-4 items-end font-semibold leading-7 text-indigo-700 py-4">
                        <p className="col-span-2">Área y sub-área</p>
                        <p className="col-span-2">Objetivos</p>
                        <p>Indicador de logro</p>
                        <p className="col-span-2">Observación</p>
                    </li>
                    {props.objectives.map((objective, index) => {
                        return (
                            <Objective objective={objective} index={index} handleChange={props.handleChange} />
                        )
                    })}
                </ul>
                <button onClick={() => props.addObjective()} className="inline-flex items-center mt-10 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Agregar objetivo</button>
            </div>
        </>
    )
}

const CalendarList = function (props) {

    const [tab, setTab] = useState('Calendario');
    const [open, setOpen] = useState(false);
    const [selection, setSelection] = useState({});
    const [sessions, setSessions] = useState(props.request);
    const [professionals, setProfessionals] = useState(props.professionals);
    const [events, setEvents] = useState(props.request.map((session) => {
        let aux = session.followUp ? colors[1] : session.prepareReport ? colors[2] : session.meetingWithParents ? colors[3] : colors[0];
        return ({ title: professionals.filter((doctor) => doctor.id === session.doctor)[0]?.name, start: session.date + 'T' + session.startTime + '-04:00', end: session.date + 'T' + session.endTime + '-04:00', editable: true, color: aux });
    }));
    const [input, setInput] = useState(props.professionals[0]);
    const [searchParams, setSearchParams] = useState([]);

    function containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (JSON.stringify(list[i]) === JSON.stringify(obj)) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        setEvents(props.request.map((session) => {
            let aux = session.followUp ? colors[1] : session.prepareReport ? colors[2] : session.meetingWithParents ? colors[3] : colors[0];
            return ({ title: professionals.filter((doctor) => doctor.id === session.doctor)[0]?.name, start: session.date + 'T' + session.startTime + '-04:00', end: session.date + 'T' + session.endTime + '-04:00', editable: true, color: aux });
        }))
    }, [])

    useEffect(async () => {
        const obj = events.slice();
        for (let i = 0; i < searchParams.length; i++) {
            await api.sessions.getMulti(null, null, null, null, { doctor: searchParams[i].id }).
                then((response) => {
                    response.map((session) => {
                        let event = { title: professionals.filter((doctor) => doctor.id === session.doctor)[0]?.name, start: session.date + 'T' + session.startTime.slice(0, 5) + '-04:00', end: session.date + 'T' + session.endTime.slice(0, 5) + '-04:00', editable: false, color: "#808080" }
                        if (session.plan !== props.plan.id && !containsObject(event, events)) {
                            obj.push(event);
                        }
                    })
                })
        }
        setEvents(obj);
    }, [searchParams])

    const handleEditEvent = (updatedEvent) => {
        let aux = updatedEvent.followUp ? colors[1] : updatedEvent.prepareReport ? colors[2] : updatedEvent.meetingWithParents ? colors[3] : colors[0]
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        updatedEvent.edit.start = new Date(updatedEvent.edit.start - tzoffset).toISOString().slice(0, 16) + '-04:00';
        updatedEvent.edit.end = new Date(updatedEvent.edit.end - tzoffset).toISOString().slice(0, 16) + '-04:00';
        let idx = events.indexOf(events.find((event) => {
            if (event.start === updatedEvent.edit.start && event.end === updatedEvent.edit.end) {
                return true
            } else {
                return false
            }
        }));
        const obj = events.slice();
        obj[idx] = { start: updatedEvent.edit.start, end: updatedEvent.edit.end, title: professionals.filter((doctor) => doctor.id === parseInt(updatedEvent.doctor))[0]?.name, editable: true, color: aux }
        setEvents(obj);
    }

    const editTime = (oldEvent, newEvent) => {
        console.log(oldEvent)
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        let oldStart = new Date(oldEvent.start - tzoffset).toISOString().slice(0, 16) + '-04:00';
        let oldEnd = new Date(oldEvent.end - tzoffset).toISOString().slice(0, 16) + '-04:00';
        let newStart = new Date(newEvent.start - tzoffset).toISOString().slice(0, 16) + '-04:00';
        let newEnd = new Date(newEvent.end - tzoffset).toISOString().slice(0, 16) + '-04:00';
        let idx = events.indexOf(events.find((event) => {
            if (event.start === oldStart && event.end === oldEnd) {
                return true
            } else {
                return false
            }
        }));
        const obj = events.slice();
        obj[idx] = { start: newStart, end: newEnd, title: newEvent.title, editable: true, color: oldEvent.backgroundColor }
        setEvents(obj);
    }

    const handleEditSession = (session) => {
        let idx = sessions.indexOf(sessions.find((event) => {
            if (event.date === session.date && event.startTime === session.startTime && event.endTime === session.endTime) {
                return true
            } else {
                return false
            }
        }));
        const obj = sessions.slice();
        obj[idx] = session;
        setSessions(obj);
    }

    const addSearch = () => {
        if (input) {
            const obj = searchParams.slice();
            if (obj.indexOf(input) === -1) {
                obj.push(input);
                setSearchParams(obj);
            }
        }
    }

    const deleteSearch = (selectedId) => {
        const obj = searchParams.filter((element) => element.id !== selectedId);
        const objEvents = events.filter((element) => ((element.title !== professionals.filter((doctor) => doctor.id === selectedId)[0]?.name) || (element.editable)))
        setSearchParams(obj);
        setEvents(objEvents);
    }

    useEffect(() => {
        props.setRequest(sessions);
    }, [sessions])

    return (
        <>
            <div className="mt-4 ml-4">
                <Tabs fullWidth={true} containerClassName="w-full" tabs={tabs} setTab={setTab} />
                {tab == 'Calendario' &&
                    <div>
                        <EventModal doctor={selection.doctor} plan={props.plan.id} professionals={professionals} open={open} setOpen={setOpen} info={selection} setEvents={setEvents} setSessions={setSessions} handleEditEvent={handleEditEvent} handleEditSession={handleEditSession} />
                        <div className="flex flex-col space-y-4">
                            <div className="flex flex-1 space-x-4 mt-4">
                                <CustomCombobox input={input} setInput={setInput} containerClassName="w-1/3" label="Profesionales" array={professionals} />
                                <button onClick={() => addSearch()} className="mt-6 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
                                    Agregar
                                </button>
                            </div>
                            <div className="flex flex-1 gap-x-4">
                                {searchParams.map((element) => {
                                    return (
                                        <Badge text={element.name} onClick={deleteSearch} selectedId={element.id} />
                                    )
                                })}
                            </div>
                        </div>
                        <div className="mt-4 mr-10">
                            <Calendar setOpen={setOpen} setSelection={setSelection} handleEditSession={handleEditSession} editTime={editTime} events={events} sessions={sessions} />
                        </div>
                    </div>
                }
                {tab == 'Lista' &&
                    sessions.map((session, idx) => {
                        return (<SessionList id={idx + 1} startTime={session.startTime} endTime={session.endTime} followUp={session.followUp} prepareReport={session.prepareReport} meetingWithParents={session.meetingWithParents} date={session.date} doctor={professionals.filter((doctor) => doctor.id === parseInt(session.doctor))[0]?.name} containerClassName="mt-4 w-3/5" />)
                    })
                }
            </div>
        </>
    )
}

export default function InterventionPlanPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const navigate = useNavigate();
    const today = new Date().toISOString().substring(0, 10)
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState([]);
    const [step, setStep] = useState('Plan');
    const { idPatient } = useParams();
    const [patient, setPatient] = useState([]);
    const [professionals, setProfessionals] = useState([]);
    const [plan, setPlan] = useState({
        kid: idPatient,
        date: today,
        items: [],
    });
    const [objectives, setObjectives] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [request, setRequest] = useState([]);
    const handleSubmitCalendar = () => {
        setButtonLoading(true);
        request.forEach((session) => {
            if (session.id) {
                api.sessions.update(session.id, session).then((response) => {
                })
                    .catch((e) => {
                        return Promise.reject(e.detail);
                    })
            } else {
                api.sessions.create(session).then((response) => {
                })
                    .catch((e) => {
                        return Promise.reject(e.detail);
                    })
            }
        }
        )
        setButtonLoading(false);
        navigate(`/patients/${idPatient}`);
    }

    const handleSubmitPlan = () => {
        setButtonLoading(true);
        if (plan.id) {
            api.plan.update(idPatient, plan).then((response) => {
                setPlan(response);
                setButtonLoading(false);
                setStep('Calendario')
            })
                .catch((e) => {
                    setButtonLoading(false);
                    return Promise.reject(e.detail);
                });
        } else {
            api.plan.create(plan).then((response) => {
                setPlan(response);
                setButtonLoading(false);
                setStep('Calendario')
            })
                .catch((e) => {
                    setButtonLoading(false);
                    return Promise.reject(e.detail);
                });
        }
    }

    useEffect(() => {
        setLoading(true)
        api.plan.getOne(idPatient).then((response) => {
            setPlan(response);
            setObjectives(response.items);
            setLoading(false);
        }).catch((error) => {
            if (error.detail === 'No encontrado.') {
                api.inventory.getMulti(null, null, null, null, { kid: idPatient }).then((response) => {
                    setInventory(response[response.length - 1])
                })
            } else {
                console.log(error);
            }
        })
        api.patients.getOne(idPatient).then((response) => {
            setPatient(response);
        }).catch((error) => console.log(error))
        api.sessions.getMulti(null, null, null, null, { plan__kid: idPatient }).then((response) => {
            setRequest(response.map((element) => ({ ...element, startTime: element.startTime.slice(0, 5), endTime: element.endTime.slice(0, 5) })));
        }).catch((error) => console.log(error))
    }, [])

    useEffect(() => {
        api.users.getMulti().then((response) => {
            setProfessionals(response.map(({ firstName, id, lastName, email, profile }) => {
                return ({
                    id: profile.id, name: firstName + ' ' + lastName, email: email
                })
            }));
        }).catch((error) => console.log(error))
    }, [])

    useEffect(() => {
        if (inventory.length !== 0) {
            const obj = objectives.slice();
            let array = inventory.personalSocialAreaWeaknesses.replace('\n', ';').split(';')
            if (array[0] !== "") {
                array.map((element) => {
                    obj.push({
                        area: 0,
                        subarea: personalSocialBehaviors.includes((behavior) => behavior.behavior === element) ? personalSocialBehaviors.filter((behavior) => behavior.behavior === element)[0].subarea : 0,
                        objective: element,
                        obs: '',
                        achievement: 1,
                    })
                })
            }
            array = inventory.adaptiveAreaWeaknesses.replace('\n', ';').split(';')
            if (array[0] !== "") {
                array.map((element) => {
                    obj.push({
                        area: 1,
                        subarea: adaptiveBehaviors.includes((behavior) => behavior.behavior === element) ? adaptiveBehaviors.filter((behavior) => behavior.behavior === element)[0].subarea : 0,
                        objective: element,
                        obs: '',
                        achievement: 1,
                    })
                })
            }
            array = inventory.motorAreaWeaknesses.replace('\n', ';').split(';')
            if (array[0] !== "") {
                array.map((element) => {
                    obj.push({
                        area: 2,
                        subarea: motorBehaviors.includes((behavior) => behavior.behavior === element) ? motorBehaviors.filter((behavior) => behavior.behavior === element)[0].subarea : 0,
                        objective: element,
                        obs: '',
                        achievement: 1,
                    })
                })
            }
            array = inventory.communicationAreaWeaknesses.replace('\n', ';').split(';')
            if (array[0] !== "") {
                array.map((element) => {
                    obj.push({
                        area: 3,
                        subarea: communicationBehaviors.includes((behavior) => behavior.behavior === element) ? communicationBehaviors.filter((behavior) => behavior.behavior === element)[0].subarea : 0,
                        objective: element,
                        obs: '',
                        achievement: 1,
                    })
                })
            }
            array = inventory.cognitiveAreaWeaknesses.replace('\n', ';').split(';')
            if (array[0] !== "") {
                array.map((element) => {
                    obj.push({
                        area: 4,
                        subarea: cognitiveBehaviors.includes((behavior) => behavior.behavior === element) ? cognitiveBehaviors.filter((behavior) => behavior.behavior === element)[0].subarea : 0,
                        objective: element,
                        obs: '',
                        achievement: 1,
                    })
                })
            }
            setObjectives(obj);
            setLoading(false);
        }
    }, [inventory])

    const addObjective = () => {
        setObjectives((prev) => [...prev, {
            area: 0,
            subarea: 0,
            objective: '',
            obs: '',
            achievement: 0,
        }]);
    }

    const handleObjectiveChange = (field, value, idx) => {
        const obj = objectives.slice();
        obj[idx][field] = value;
        setObjectives(obj);
    }


    useEffect(() => {
        setPlan((prev) => ({ ...prev, items: objectives }))
    }, [objectives])


    const pages = [
        { name: 'Niños usuarios', href: '/patients', current: false },
        { name: `${patient.fullName} | ${patient.age} meses | ${status[patient.status]}`, href: `/patients/${idPatient}`, current: false },
        { name: 'Plan de intervención', href: '#', current: true }
    ]

    return (
        <div className="bg-gray-100 min-h-screen h-full">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 space-y-4 pb-4 ml-6 mt-6">
                    <div className="flex justify-between">
                        <Breadcrumbs pages={pages} />
                        {step === 'Plan' &&
                            <button
                                type="button"
                                onClick={() => {
                                    handleSubmitPlan();
                                }}
                                className="inline-flex mr-10 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Guardar y agendar
                            </button>
                        }
                        {step === 'Calendario' &&
                            <button
                                type="button"
                                onClick={() => {
                                    handleSubmitCalendar();
                                }}
                                className="inline-flex mr-10 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Guardar
                            </button>
                        }
                    </div>
                    {loading ?
                        <div className="flex min-h-screen h-full items-center justify-center ">
                            <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                        </div> :
                        <>
                            {step === 'Plan' && <InterventionPlan setStep={setStep} objectives={objectives} addObjective={addObjective} handleChange={handleObjectiveChange} />}
                            {step === 'Calendario' && <CalendarList setStep={setStep} setRequest={setRequest} request={request} plan={plan} professionals={professionals} />}
                        </>}
                </div>
            </div>
        </div>
    );
}