import React, { useEffect } from "react";
import { useState } from 'react'
import { Link } from "react-router-dom";
import {
    BanIcon,
    PlusIcon,
} from '@heroicons/react/outline'
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Table from "../../components/Table";
import api from "../../api";

const columns = [
    { name: 'name', displayText: 'Nombre y Apellido', clickable: true },
    { name: 'govId', displayText: 'Documento' },
    { name: 'age', displayText: 'Edad' },
]

export default function PatientsPage() {

    const [patientsError, setPatientsError] = useState('')
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [patients, setPatients] = useState(false)
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(true)
    let dateToday = new Date()
    dateToday = dateToday.toISOString().split('T')[0]

    const searchPatient = () => {
        setLoading(true)
        setPatientsError('')

        api.patients.getMulti(null, search, null, null, null).then((response) => {
            setPatients(response.map(({ fullName, id, age, govId }) => {
                return ({
                    id,
                    name: fullName,
                    age,
                    govId
                })
            }))
            setLoading(false)
        }).catch((error) => {
            console.log('Error al traer/filtrar pacientes: ', error.response?.data)
            setPatientsError('Hubo un error al traer los filtros, inténtalo más tarde')
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        api.patients.getMulti().then((response) => {
            setPatients(response.map(({ fullName, id, age, govId }) => {
                return ({
                    id: id, name: fullName, age: age + ' meses', govId
                })
            }));
            setLoading(false);
        }).catch((error) => console.log(error))
    }, [])

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                <Header value={search} setSearch={setSearch} search={searchPatient} setSidebarOpen={setSidebarOpen} searchPlaceholder="Buscar por nombre o número de documento." buttonText={patients.length ? "Agregar niño usuario" : null} href="/patients/new" />
                {loading ?
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <>
                        {patientsError && <div className="mt-5 mx-10 text-sm text-red-600">
                            {patientsError}
                        </div>}
                        <main className="flex-1 bg-gray-100">
                            {patients.length ?
                                <div className="px-4 py-4 sm:px-6 md:px-8">
                                    <Table module="patients" actionText="Ver" columns={columns} rows={patients} />
                                </div>
                                :
                                <div className="flex h-full">
                                    <div className="mx-auto my-auto text-center">
                                        <BanIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
                                        <h3 className="mt-2 text-sm font-semibold text-gray-900">No se encontraron resultados</h3>
                                        <p className="mt-1 text-sm font-semibold text-gray-500">Probá con otros datos o registrá un nuevo paciente.</p>
                                        <div className="mt-6">
                                            <Link
                                                to="/patients/new"
                                                type="button"
                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                Nuevo niño usuario
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            }
                        </main>
                    </>
                }
            </div>
        </div >
    );
}