import axios from "axios";
//import QueryString from "qs";

const errorCodes = {
    badRequest: 'badRequest',
    unauthorized: 'unauthorized',
    forbidden: 'forbidden',
    notFound: 'notFound',
    serverError: 'serverError',
    unexpected: 'unexpected',
    invalidCredentials: 'invalidCredentials',
}

class API {
    constructor(collection) {
        this.collectionUrl = `${process.env.REACT_APP_API_BASE_URL}/${collection}`;
    }

    _handleError(error) {
        if (error.response?.status === 400)
            return Promise.reject({ code: errorCodes.badRequest, message: error.response.data })
        if (error.response?.status === 401)
            return Promise.reject(errorCodes.unauthorized)
        if (error.response?.status === 403)
            return Promise.reject(errorCodes.forbidden)
        if (error.response?.status === 404)
            return Promise.reject(errorCodes.notFound)
        if (500 <= error.response?.status <= 599)
            return Promise.reject(errorCodes.serverError)
        return Promise.reject(errorCodes.unexpected)
    }

    async getMulti(ordering = null, search = null, limit = null, offset = null, extra = {}) {
        const params = { limit, offset, ...extra }
        if (ordering)
            params.ordering = ordering;
        if (search && search.length > 0)
            params.search = search;
        try {
            const response = await axios.get(`${this.collectionUrl}/`, { params },);
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async getOne(id) {
        try {
            const response = await axios.get(`${this.collectionUrl}/${id}/`);
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async create(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, data);
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async update(id, data, partial = false) {
        try {
            let response = null;
            if (partial)
                response = await axios.patch(`${this.collectionUrl}/${id}/`, data);
            else
                response = await axios.put(`${this.collectionUrl}/${id}/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete(`${this.collectionUrl}/${id}/`)
            return response
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }
}

class BattelleAPI extends API {

    async getAnswers(ordering = null, search = null, limit = null, offset = null, extra = {}) {
        const params = { limit, offset, ...extra }
        if (ordering)
            params.ordering = ordering;
        if (search && search.length > 0)
            params.search = search;
        try {
            const response = await axios.get(`${this.collectionUrl}/answers/`, { params },);
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }
}

class KidsAPI extends API {

    async sendAdmission(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/admission/`, data);
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }
}

class UserAPI extends API {
    async getToken(username, password) {
        try {
            const response = await axios.post(`${this.collectionUrl}/token/`, { username, password })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async changePassword(newPass) {
        try {
            const response = await axios.post(`${this.collectionUrl}/me/change-password/`, { new: newPass });
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }

    async getMe() {
        try {
            const response = await axios.get(`${this.collectionUrl}/me/`)
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }
}

class FormDataAPI extends API {
    async createData(data) {
        try {
            const response = await axios.post(
                `${this.collectionUrl}/`,
                data,
                {
                    headers: { "content-type": "multipart/form-data" },
                })
            return Promise.resolve(response.data)
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials)
        }
    }

    async updateData(id, data, partial = false) {
        try {
            let response = null;
            if (partial)
                response = await axios.patch(
                    `${this.collectionUrl}/${id}/`,
                    data,
                    {
                        headers: { "content-type": "multipart/form-data" },
                    }
                );
            else
                response = await axios.put(
                    `${this.collectionUrl}/${id}/`,
                    data,
                    {
                        headers: { "content-type": "multipart/form-data" },
                    }
                );
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(e.response.data)
        }
    }
}

class PasswordAPI extends API {
    async changePassword(password, uuid, token) {
        try {
            const response = await axios.post(`${this.collectionUrl}/${uuid}/${token}/`, { password: password })
        } catch (e) {
            return this._handleError(e)
        }
    }
}

class DownloadsAPI extends API {

    async inventory(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/battelle-inventory/`, data, { responseType: 'blob' })
            return response
        } catch (e) {
            return this._handleError(e)
        }
    }

    async admission(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/admission/`, data, { responseType: 'blob' })
            return response
        } catch (e) {
            return this._handleError(e)
        }
    }

    async activities(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/activities/`, data, { responseType: 'blob' })
            return response
        } catch (e) {
            return this._handleError(e)
        }
    }

    async interventionPlan(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/intervention-plan/`, data, { responseType: 'blob' })
            return response
        } catch (e) {
            return this._handleError(e)
        }
    }

    async sessions(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/sessions/`, data, { responseType: 'blob' })
            return response
        } catch (e) {
            return this._handleError(e)
        }
    }

    async socioeconomicSurvey(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/socioeconomic-survey/`, data, { responseType: 'blob' })
            return response
        } catch (e) {
            return this._handleError(e)
        }
    }
}

const api = {
    districts: new API('districts'),
    departments: new API('departments'),
    establishments: new API('establishments'),
    patients: new KidsAPI('kids'),
    screening: new BattelleAPI('battelle-screening'),
    inventory: new BattelleAPI('battelle-inventory'),
    plan: new API('kids/intervention-plans'),
    sessions: new API('sessions'),
    users: new UserAPI('users'),
    documents: new FormDataAPI('kids/documents'),
    categories: new FormDataAPI('vigidit/categories'),
    ageRanges: new API('vigidit/age-ranges'),
    images: new API('vigidit/images'),
    discharge: new API('kids/medical-discharge'),
    downloads: new DownloadsAPI('kids/download'),
    dischargeDocs: new FormDataAPI('kids/medical-discharge/documents'),
    configurations: new API('configurations'),
}

export default api;
export { errorCodes, API, UserAPI };