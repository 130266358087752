import React from "react";

export default function ColorInput(props) {
    return (
        <div className={props.containerClassName}>
            <label
                htmlFor={props.name}
                className="block text-sm leading-5 font-medium text-gray-700"
            >
                {props.label}
            </label>
            <div className="mt-1 shadow-sm border-gray-300 flex items-center bg-white rounded-md overflow-hidden">
                <div className="w-2/12 flex justify-center items-center">
                    <span className="text-base font-normal text-gray-600">
                        HEX
                    </span>
                </div>
                <input
                    type="text"
                    name={props.name}
                    defaultValue={props.defaultValue}
                    onChange={(event) => props.handleChange(props.name, event.target.value)}
                    className={
                        props.className
                            ? props.className
                            : "shadow-sm block w-9/12 sm:text-sm border-white"
                    }
                    placeholder={props.placeholder}
                />
                <input
                    type="color"
                    name={props.name}
                    defaultValue={props.defaultValue}
                    onChange={(event) => props.handleChange(props.name, event.target.value)}
                    className="w-1/12 mr-1 border-white"
                />
            </div>
        </div>
    );
}
