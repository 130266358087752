import React from "react";
import { useState, useEffect } from 'react'
import {
    MenuAlt2Icon,
} from '@heroicons/react/outline'
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import SelectInput from "../../components/SelectInput";
import InlineCheckbox from "../../components/InlineCheckbox";
import Textarea from "../../components/Textarea";
import TextInput from "../../components/TextInput";
import api from "../../api";

const status = [
    "Pendiente",
    "Admitido",
    "No admitido",
    "Alta médica"
]

export default function ActivityRegistryPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [absent, setAbsent] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [sessions, setSessions] = useState([]);
    const { idPatient } = useParams();
    const [patient, setPatient] = useState([]);
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        setLoading(true)
        api.sessions.getMulti(null, null, null, null, { plan__kid: idPatient }).then((response) => {
            setSessions(response);
        }).catch((error) => console.log(error))
        api.users.getMulti().then((response) =>
            setDoctors(response.map(({ firstName, id, lastName, email, profile }) => {
                return ({
                    id: profile.id, name: firstName + ' ' + lastName, email: email
                })
            }))).catch((error) => console.log(error))
        api.patients.getOne(idPatient).then((response) => {
            setPatient(response);
        }).catch((error) => console.log(error))
    }, [])

    const handleChange = (field, value, idx) => {
        const obj = sessions.slice();
        obj[idx][field] = value;
        setSessions(obj);
    };

    const handleSelected = (field, value) => {
        setSelected(parseInt(value));
    }

    const handleSubmit = () => {
        setButtonLoading(true);
        api.sessions.update(sessions[selected]?.id, sessions[selected]).then((response) => {
            setButtonLoading(false);
            navigate(`/patients/${idPatient}`);
        })
            .catch((e) => {
                setButtonLoading(false);
                return Promise.reject(e.detail);
            });
    }

    const pages = [
        { name: 'Niños usuarios', href: '/patients', current: false },
        { name: `${patient.fullName} | ${patient.age} meses | ${status[patient.status]}`, href: `/patients/${idPatient}`, current: false },
        { name: 'Registro de actividades', href: '#', current: true }
    ]

    return (
        <div className="bg-gray-100 min-h-screen h-full">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 space-y-4 pb-4 ml-6 mt-6">
                    <div className="flex justify-between">
                        <Breadcrumbs pages={pages} />
                        <button
                            type="button"
                            onClick={() => handleSubmit()}
                            className="inline-flex mr-10 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Guardar
                        </button>
                    </div>
                    <div>
                        <h1 className="ml-4 mt-4 text-xl leading-6 font-semibold text-gray-900">Registro de actividades diarias</h1>
                        <div className="mt-6 ml-4">
                            <p className="text-md leading-6 font-semibold text-indigo-700">Numero de sesión</p>
                            <div className="flex gap-x-6">
                                <SelectInput handleChange={handleSelected} options={sessions.map((session, idx) => <option key={idx} value={idx} label={session.date + ' | ' + 'Sesión ' + session.sessionNumber + ' | ' + doctors.filter((doctor) => doctor.id === session.doctor)[0]?.name}>{session.date + ' | ' + doctors.filter((doctor) => doctor.id === session.doctor)[0]?.name}</option>)} containerClassName="w-1/3 mt-2" />
                                <InlineCheckbox value={sessions[selected]?.absent} idx={selected} label="Ausente" name="absent" handleChange={handleChange} />
                                {sessions[selected]?.absent && <TextInput idx={selected} defaultValue={sessions[selected]?.absenceReason} name="absenceReason" label="Motivo" handleChange={handleChange} containerClassName="w-1/5 -mt-2" />}
                            </div>
                            <p className="mt-10 text-md leading-6 font-semibold text-gray-900">{doctors.filter((doctor) => doctor.id === sessions[selected]?.doctor)[0]?.name}</p>
                            <div className="grid grid-cols-2 mt-2">
                                <div>
                                    <p className="text-md leading-6 font-semibold text-indigo-700">Actividades</p>
                                    <Textarea idx={selected} defaultValue={sessions[selected]?.description} handleChange={handleChange} name="description" containerClassName="mr-8" rows={10} placeholder="Actividades" />
                                </div>
                                <div>
                                    <p className="text-md leading-6 font-semibold text-indigo-700">Observación</p>
                                    <Textarea idx={selected} defaultValue={sessions[selected]?.obs} handleChange={handleChange} name="obs" containerClassName="mr-8" rows={10} placeholder="Conversaciones con la familia u observaciones en el proceso de intervención" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}