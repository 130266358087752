import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default function Calendar(props) {

    const [events, setEvents] = useState([])

    useEffect(() => {
        setEvents(props.events);
    }, [props.events])

    return (
        <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            locale="es"
            allDaySlot={false}
            height="auto"
            hiddenDays={[0]}
            slotMinTime="07:00:00"
            slotMaxTime="19:00:00"
            selectable={true}
            buttonText={{
                today: 'Hoy',
                month: 'mes',
                week: 'semana',
                day: 'día',
                list: 'Lista'
            }}
            events={events}
            select={(info) => {
                props.setSelection(info);
                props.setOpen(true);
            }}
            eventClick={(info) => {
                if (info.event.startEditable) {
                    props.setSelection({
                        ...props.sessions.filter((session) => {
                            if (session.date === info.event.start.toISOString().substring(0, 10)
                                && session.startTime === info.event.start.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)
                                && session.endTime === info.event.end.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)) {
                                return true;
                            } else {
                                return false;
                            }
                        })[0], edit: { title: info.event.title, start: info.event.start, end: info.event.end, editable: true }
                    });
                    props.setOpen(true);
                }
            }}
            eventDrop={(info) => {
                let session = props.sessions.filter((session) => {
                    if (session.date === info.oldEvent.start.toISOString().substring(0, 10)
                        && session.startTime === info.oldEvent.start.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)
                        && session.endTime === info.oldEvent.end.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)) {
                        return true;
                    } else {
                        return false;
                    }
                })[0];
                session.date = info.event.start.toISOString().substring(0, 10)
                session.startTime = info.event.start.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)
                session.endTime = info.event.end.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)
                props.handleEditSession(session);
                props.editTime(info.oldEvent, info.event);
            }}
            eventResize={(info) => {
                let session = props.sessions.filter((session) => {
                    if (session.date === info.oldEvent.start.toISOString().substring(0, 10)
                        && session.startTime === info.oldEvent.start.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)
                        && session.endTime === info.oldEvent.end.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)) {
                        return true;
                    } else {
                        return false;
                    }
                })[0];
                session.date = info.event.start.toISOString().substring(0, 10)
                session.startTime = info.event.start.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)
                session.endTime = info.event.end.toLocaleString('en-US', { hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).substring(12, 17)
                props.handleEditSession(session);
                props.editTime(info.oldEvent, info.event);
            }}
        />
    )
}