import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import TextInput from "../../../components/TextInput";
import api from "../../../api";
import Sidebar from "../../../components/Sidebar";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Textarea from "../../../components/Textarea";
import NumberInput from "../../../components/NumberInput";

export default function NewAgeRangePage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { rangeId } = useParams();
    const navigate = useNavigate();

    const [ageRange, setAgeRange] = useState({
        message: "",
        videoInSpanish: "",
        videoInGuarani: "",
        ageRange: "",
        ageInMonthsMin: 0,
        ageInMonthsMax: 0,
    });

    const handleChange = (field, value) =>
        setAgeRange((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (rangeId) {
            // UPDATE
            api.ageRanges
                .update(rangeId, ageRange, false)
                .then((response) => {
                    setLoading(false);
                    navigate("/admin/vigidit");
                })
                .catch((e) => {
                    setLoading(false);
                    return Promise.reject(e.detail);
                });
        } else {
            // CREATE
            api.ageRanges
                .create(ageRange)
                .then((response) => {
                    setLoading(false);
                    navigate("/admin/vigidit");
                })
                .catch((e) => {
                    setLoading(false);
                    return Promise.reject(e.detail);
                });
        }
    };

    const getAgeRangeData = () => {
        // if this is a edit page get data of current age range
        if (rangeId) {
            setLoading(true);
            api.ageRanges
                .getOne(rangeId)
                .then((response) => {
                    setAgeRange({
                        message: response.message,
                        videoInSpanish: response.videoInSpanish,
                        videoInGuarani: response.videoInGuarani,
                        ageRange: response.ageRange,
                        ageInMonthsMin: response.ageInMonthsMin,
                        ageInMonthsMax: response.ageInMonthsMax,
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        } else {
            return false;
        }
    };

    const deleteAgeRange = () => {
        setLoading(true);

        if (rangeId) {
            api.ageRanges
                .delete(rangeId)
                .then((response) => {
                    console.log(response);
                    setLoading(false);
                    navigate("/admin/vigidit");
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        } else {
            return false;
        }
    }

    useEffect(() => {
        getAgeRangeData();
    }, []);

    // pages for the breadcrumb component
    const pages = [
        { name: "VIGIDIT", href: "/admin/vigidit", current: false },
        {
            name: rangeId ? "Editar rango de edad" : "Nueva rango de edad",
            href: "#",
            current: true,
        },
    ];

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar
                current={"Vigidit"}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ? (
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                ) : (
                    <main className="px-8">
                        <div className="mt-8">
                            <Breadcrumbs pages={pages} />
                        </div>
                        <section>
                            <div className="flex justify-between items-center py-6">
                                <h1 className="text-2xl font-semibold leading-9 text-gray-900">
                                    {
                                        rangeId ? "Editar rango de edad" : "Agregar rango de edad"
                                    }
                                </h1>
                                {
                                    rangeId ?
                                    <button
                                        onClick={deleteAgeRange}
                                        type="button"
                                        className="inline-flex h-min px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-red-600 hover:bg-red-700"
                                    >
                                        <TrashIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                        Eliminar
                                    </button> : ""
                                }
                            </div>
                            <div className="mt-4">
                                <TextInput
                                    defaultValue={ageRange.ageRange}
                                    handleChange={handleChange}
                                    containerClassName="w-full"
                                    name="ageRange"
                                    label="Nombre del rango de edad *"
                                    placeholder="De X a X meses"
                                />
                            </div>
                            <div className="mt-4 flex flex-col md:flex-row gap-2">
                                <NumberInput
                                    defaultValue={ageRange.ageInMonthsMin}
                                    handleChange={handleChange}
                                    containerClassName="md:w-1/2 w-full"
                                    name="ageInMonthsMin"
                                    label="Mes inicial en números *"
                                    min="0"
                                />
                                <NumberInput
                                    defaultValue={ageRange.ageInMonthsMax}
                                    handleChange={handleChange}
                                    containerClassName="md:w-1/2 w-full"
                                    name="ageInMonthsMax"
                                    label="Mes final en números *"
                                    min="0"
                                />
                            </div>
                            <div className="mt-4 flex flex-col md:flex-row gap-2">
                                <TextInput
                                    defaultValue={ageRange.videoInSpanish}
                                    handleChange={handleChange}
                                    containerClassName="md:w-1/2 w-full"
                                    name="videoInSpanish"
                                    label="Link del video en español *"
                                />
                                <TextInput
                                    defaultValue={ageRange.videoInGuarani}
                                    handleChange={handleChange}
                                    containerClassName="md:w-1/2 w-full"
                                    name="videoInGuarani"
                                    label="Link del video en guarani *"
                                />
                            </div>
                            <div className="mt-4">
                                <Textarea
                                    defaultValue={ageRange.message}
                                    handleChange={handleChange}
                                    name="message"
                                    containerClassName="w-full"
                                    label="Mensaje"
                                    rows="5"
                                />
                            </div>

                            <div className="mt-6">
                                <button
                                    onClick={handleSubmit}
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    <PlusIcon
                                        className="-ml-1 mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    {rangeId ? "Guardar" : "Agregar"}
                                </button>
                            </div>
                        </section>
                    </main>
                )}
            </div>
        </div>
    );
}
