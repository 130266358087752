import React from "react";

export default function Divider(props) {

    return (
        <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-start">
                <span className={props.className ? props.className : "pr-2 bg-gray-100 text-sm text-gray-500"}>{props.title}</span>
            </div>
        </div>
    )

}