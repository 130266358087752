import React, { useEffect } from "react";
import { useState } from 'react'
import { Link, useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import api from "../../api";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/TextInput";
import Textarea from "../../components/Textarea";

const pages = [
    { name: 'Niños usuarios', href: '/patients', current: false },
    { name: 'José Amarilla | 3 años | pendiente', href: `#`, current: false },
    { name: 'Agregar documento', href: `#`, current: true },
]

export default function NewDocumentPage() {

    const { idPatient } = useParams();
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [document, setDocument] = useState({
        title: '',
        description: '',
        kid: idPatient,
        document: ''
    })
    const handleChange = (field, value) => setDocument((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = new FormData;
        Object.keys(document).forEach(key => data.append(key, document[key]));

        api.documents.createData(data).then(response => {
            setLoading(true);
            navigate("/patients/" + idPatient + "/documents");
        }).catch((e) => {
            setLoading(false)
            return Promise.reject(e.detail)
        });
    }

    const handleDocument = event => {
        const fileUploaded = event.target.files[0]
        handleChange('document', fileUploaded);
    };

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar current={'Pacientes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ?
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <>
                        <main className="flex-1 bg-gray-100 pl-5">
                            <div className="py-5">
                                <Breadcrumbs pages={pages} />
                            </div>
                            <form className="p-4 bg-white rounded-md mr-4">
                                <h1 className="font-semibold text-xl">Agregar nuevo documento</h1>
                                <div className="mt-4">
                                    <TextInput defaultValue={document.title} handleChange={handleChange} containerClassName="w-2/3" name="title" label="Título del documento *" />
                                </div>
                                <div className="mt-4">
                                    <Textarea defaultValue={document.description} handleChange={handleChange} label="Añade una descripción (opcional)" name="description" containerClassName="w-2/3" rows={4} />
                                </div>
                                <div className="mt-4">
                                    <input
                                        type="file"
                                        name="document"
                                        accept="application/pdf"
                                        onChange={handleDocument}
                                    />
                                </div>

                                <div className="flex justify-end mt-5 sm:mt-6">
                                    <button
                                        type="submit"
                                        className="inline-flex rounded-md border border-transparent shadow-sm px-6 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                        onClick={handleSubmit}
                                    >
                                        Subir documento
                                    </button>
                                    <Link
                                        type="button"
                                        to={"/patients/" + idPatient + "/documents"}
                                        className="inline-flex ml-2 rounded-md border border-transparent shadow-sm px-6 py-2 bg-indigo-50 text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-20 sm:text-sm"
                                    >
                                        Cancelar
                                    </Link>
                                </div>
                            </form>
                        </main>
                    </>
                }
            </div>
        </div >
    )
}