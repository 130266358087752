import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import TextInput from "../../../components/TextInput";
import ColorInput from "../../../components/ColorInput";
import WYSIWYG from "../../../components/WYSIWYG";
import api from "../../../api";
import Sidebar from "../../../components/Sidebar";
import SelectInput from "../../../components/SelectInput";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Textarea from "../../../components/Textarea";

export default function NewCategoryPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { categoryId } = useParams();
    const navigate = useNavigate();

    const [category, setCategory] = useState({
        name: "",
        content: "",
        color: "",
        ageRange: 1,
        status: 1,
        description: '',
    });
    const [ageRanges, setAgeRanges] = useState([]);
    const [content, setContent] = useState("");
    const [logo, setLogo] = useState("");
    const [oldContent, setOldContent] = useState("");
    const [oldLogo, setOldLogo] = useState("");

    const handleChange = (field, value) =>
        setCategory((prev) => ({ ...prev, [field]: value }));

    const hiddenFileInput = React.useRef(null);
    const uploadFile = (event) => {
        hiddenFileInput.current.click();
    };

    const handleFileChange = async (event, field) => {
        const fileUploaded = event.target.files[0];
        if(field === 'logo') setLogo(fileUploaded);
        handleChange(field, fileUploaded);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = new FormData;
        Object.keys(category).forEach(key => data.append(key, category[key]));

        if(categoryId) { // UPDATE
            api.categories
            .updateData(categoryId, data, false)
            .then((response) => {
                setLoading(false);
                navigate('/admin/vigidit');
            })
            .catch((e) => {
                setLoading(false);
                return Promise.reject(e.detail);
            });
        } else { // CREATE
            api.categories
            .createData(data)
            .then((response) => {
                setLoading(false);
                navigate('/admin/vigidit');
            })
            .catch((e) => {
                setLoading(false);
                return Promise.reject(e.detail);
            });
        }
    };

    const getAgeRanges = () => {
        // get from the API an array of age ranges
        api.ageRanges
            .getMulti()
            .then((response) => {
                setAgeRanges(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    const getCategoryData = () => {
        // if this is a edit page get data of current category
        if(categoryId) {
            api.categories.getOne(categoryId).then(response => {
                setCategory({
                    name: response.name,
                    content: response.content,
                    color: response.color,
                    ageRange:response.ageRange,
                    status:response.status,
                    description: response.description,
                });
                setContent(response.content);
                setOldContent(response.content);
                setOldLogo(response.logo);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
        } else {
            return false;
        }
    }

    const deleteCategory = () => {
        setLoading(true);

        if (categoryId) {
            api.categories
                .delete(categoryId)
                .then((response) => {
                    console.log(response);
                    setLoading(false);
                    navigate("/admin/vigidit");
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        } else {
            return false;
        }
    }

    useEffect(() => {
        getAgeRanges();
        getCategoryData();
    }, [])

    useEffect(() => {
        console.log('updating');
        handleChange('content', content);
    }, [content])

    // pages for the breadcrumb component
    const pages = [
        { name: 'VIGIDIT', href: '/admin/vigidit', current: false },
        { name: categoryId ? "Editar categoría" : "Nueva categoría", href: '#', current: true }
    ]

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar
                current={"Vigidit"}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ? (
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                ) : (
                    <main className="px-8">
                        <div className="mt-8">
                            <Breadcrumbs pages={pages} />
                        </div>
                        <section className="flex-1">
                        <div className="flex justify-between items-center py-6">
                                <h1 className="text-2xl font-semibold leading-9 text-gray-900">
                                    {
                                        categoryId ? "Editar categoría" : "Agregar categoría"
                                    }
                                </h1>
                                {
                                    categoryId ?
                                    <button
                                        onClick={deleteCategory}
                                        type="button"
                                        className="inline-flex h-min px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-red-600 hover:bg-red-700"
                                    >
                                        <TrashIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                        Eliminar
                                    </button> : ""
                                }
                            </div>
                            <div className="mt-4 flex flex-col md:flex-row gap-2">
                                <TextInput
                                    defaultValue={category.name}
                                    handleChange={handleChange}
                                    containerClassName="md:w-1/3 w-full"
                                    name="name"
                                    label="Nombre de la categoría *"
                                />
                                <SelectInput 
                                    handleChange={handleChange}
                                    containerClassName="md:w-1/3 w-full"
                                    name="ageRange"
                                    label="Selecciona el rango de edad *"
                                    options={
                                        ageRanges.map(range => {
                                            return (
                                                <option 
                                                    key={range.id} 
                                                    value={range.id} 
                                                    selected={range.id == category.ageRange}
                                                >
                                                    {range.ageRange}
                                                </option>
                                            )
                                        })
                                    }
                                />
                                <ColorInput
                                    defaultValue={category.color}
                                    handleChange={handleChange}
                                    containerClassName="md:w-1/3 w-full"
                                    name="color"
                                    label="Selecciona un color *"
                                />
                            </div>
                            <div className="mt-4">
                                    <Textarea defaultValue={category.description} handleChange={handleChange} label="Añade una descripción corta" name="description" rows={4} />
                                </div>
                            <div className="mt-4">
                                <WYSIWYG
                                    content={content}
                                    setContent={setContent}
                                    oldContent={oldContent}
                                />
                            </div>
                            <div className="mt-4 flex flex-col md:flex-row gap-2">
                                <div className="w-full md:w-1/2">
                                    <label className="block text-sm leading-5 font-medium text-gray-700 mt-4 mb-2">
                                        Seleccionar logo
                                    </label>
                                    <div className="flex items-center">
                                        {logo || oldLogo ? (
                                            <>
                                                <div className="h-12 w-12 flex justify-center align-center rounded-full overflow-hidden bg-gray-500">
                                                    <img
                                                        alt="not fount"
                                                        className="h-full w-full rounded-full overflow-hidden bg-gray-100"
                                                        src={oldLogo ? oldLogo : URL.createObjectURL(logo)}
                                                    />
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setLogo(null);
                                                        setOldLogo(null);
                                                        handleChange("logo", null);
                                                    }}
                                                    className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50"
                                                >
                                                    Eliminar
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <div className="h-12 w-12 flex justify-center align-center rounded-full overflow-hidden bg-gray-500"></div>
                                                <button
                                                    type="button"
                                                    onClick={uploadFile}
                                                    className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50"
                                                >
                                                    Subir
                                                </button>
                                            </>
                                        )}
                                        <input
                                            onChange={(event) =>
                                                handleFileChange(event, "logo")
                                            }
                                            type="file"
                                            name="logo"
                                            ref={hiddenFileInput}
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2">
                                    <label className="block text-sm leading-5 font-medium text-gray-700 mt-4 mb-4" htmlFor="document">Agregar un documento (opcional)</label>
                                    <input 
                                        type="file"
                                        name="document"
                                        id="document"
                                        onChange={(event) =>
                                            handleFileChange(event, "document")
                                        }
                                    />
                                </div>
                            </div>

                            <div className="mt-6">
                                <button
                                    onClick={handleSubmit}
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    <PlusIcon
                                        className="-ml-1 mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    { categoryId ? "Guardar" : "Agregar" }
                                </button>
                            </div>
                        </section>
                    </main>
                )}
            </div>
        </div>
    );
}
