import React, { useEffect } from "react";
import { useState } from 'react'
import { Link } from "react-router-dom";
import {
    BanIcon,
    PlusIcon,
} from '@heroicons/react/outline'
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Table from "../../components/Table";
import api from "../../api";

const columns = [
    { name: 'name', displayText: 'Nombre', clickable: true },
    { name: 'district', displayText: 'Distrito' },
    { name: 'department', displayText: 'Depto.' },
    //{ name: 'obs', displayText: 'Obs' }
]

export default function EstablishmentsPage() {

    const [establishmentsError, setEstablishmentsError] = useState('')
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(true)
    const [establishments, setEstablishments] = useState([]);
    //let dateToday = new Date()
    //dateToday = dateToday.toISOString().split('T')[0]

    useEffect(() => {
        api.establishments.getMulti().then((response) => {
            setEstablishments(response.map(({ id, department, name, district }) => {
                return ({
                    id,
                    department: department.name,
                    name,
                    district: district.name
                })
            }));
            setLoading(false)
        }).catch((error) => {
            setEstablishmentsError(error)
            setLoading(false)
        })
    }, [])

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar current={'Establecimientos'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                <Header setSidebarOpen={setSidebarOpen} buttonText={establishments.length ? "Agregar establecimiento" : null} href="/establishments/new" />
                {loading ?
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-indigo-900 rounded-full animate-spin"></div>
                    </div>
                    :
                    <>
                        {establishmentsError && <div className="mt-5 mx-10 text-sm text-red-600">
                            {establishmentsError}
                        </div>}
                        <main className="flex-1 bg-gray-100">
                            {establishments.length ?
                                <div className="px-4 py-4 sm:px-6 md:px-8">
                                    <Table module="establishments" actionText="Ver" columns={columns} rows={establishments} />
                                </div>
                                :
                                <div className="flex h-full">
                                    <div className="mx-auto my-auto text-center">
                                        <BanIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
                                        <h3 className="mt-2 text-sm font-semibold text-gray-900">No se encontraron resultados</h3>
                                        <p className="mt-1 text-sm font-semibold text-gray-500">Probá con otros datos o registrá un nuevo establecimiento.</p>
                                        <div className="mt-6">
                                            <Link
                                                to="/establishments/new"
                                                type="button"
                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                                Nuevo establecimiento
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            }
                        </main>
                    </>
                }
            </div>
        </div >
    );
}