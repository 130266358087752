import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { useAuth } from './context/authContext';
import PatientsPage from './pages/patients/PatientsPage';
import EstablishmentsPage from './pages/establishments/EstablishmentsPage';
import NewEstablishmentPage from './pages/establishments/NewEstablishmentPage';
import UsersPage from './pages/users/UsersPage';
import NewUserPage from './pages/users/NewUserPage';
import PatientProfilePage from './pages/patients/PatientProfilePage';
import LoginPage from './pages/LoginPage';
import NewPatientPage from './pages/patients/NewPatientPage';
import BattelleScreeningPage from './pages/batelle/BattelleScreeningPage';
import BattelleInventoryPage from './pages/batelle/BattelleInventoryPage';
import AdmissionPage from './pages/admission/AdmissionPage';
import InterventionPlanPage from './pages/activities/InterventionPlanPage';
import ActivityRegistryPage from './pages/activities/ActivityRegistryPage';
import ActivityHistoryPage from './pages/status/ActivityHistoryPage';
import DischargePage from './pages/status/DischargePage';
import DocumentsPage from './pages/documents/DocumentsPage';
import NewDocumentPage from './pages/documents/NewDocumentPage';
import AdminVigiditPage from './pages/vigidit/admin/AdminVigiditPage';
import NewCategoryPage from './pages/vigidit/admin/NewCategoryPage';
import NewAgeRangePage from './pages/vigidit/admin/NewAgeRangePage';
import ConfigurationsPage from './pages/configurations/ConfigurationsPage';

function App() {
  const auth = useAuth();

  const authenticatedRoutes = (
    <Routes>
      <Route path="/" element={<Navigate to="/patients" />} />
      <Route exact path="/patients" element={<PatientsPage />} />
      <Route exact path="/patients/new" element={<NewPatientPage />} />
      <Route path="/patients/:idPatient" element={<PatientProfilePage />} />
      <Route path="/patients/:idPatient/edit" element={<NewPatientPage />} />
      <Route path="/patients/:idPatient/screening" element={<BattelleScreeningPage />} />
      <Route path="/patients/:idPatient/inventory" element={<BattelleInventoryPage />} />
      <Route path="/patients/:idPatient/documents" element={<DocumentsPage />} />
      <Route path="/patients/:idPatient/new-document" element={<NewDocumentPage />} />
      <Route path="/patients/:idPatient/admission" element={<AdmissionPage />} />
      <Route path="/patients/:idPatient/plan" element={<InterventionPlanPage />} />
      <Route path="/patients/:idPatient/activities" element={<ActivityRegistryPage />} />
      <Route path="/patients/:idPatient/history" element={<ActivityHistoryPage />} />
      <Route path="/patients/:idPatient/discharge" element={<DischargePage />} />
      <Route exact path="/establishments" element={<EstablishmentsPage />} />
      <Route exact path="/establishments/new" element={<NewEstablishmentPage />} />
      <Route path="/establishments/:idEstablishment" element={<NewEstablishmentPage />} />
      <Route exact path="/users" element={<UsersPage />} />
      <Route exact path="/users/new" element={<NewUserPage />} />
      <Route path="/users/:idUser" element={<NewUserPage />} />
      <Route exact path="/admin/vigidit" element={<AdminVigiditPage />} />
      <Route exact path="/admin/vigidit/categories/new" element={<NewCategoryPage />} />
      <Route path="/admin/vigidit/categories/:categoryId" element={<NewCategoryPage />} />
      <Route exact path="/admin/vigidit/age-ranges/new" element={<NewAgeRangePage />} />
      <Route path="/admin/vigidit/age-ranges/:rangeId" element={<NewAgeRangePage />} />
      <Route exact path="/configurations" element={<ConfigurationsPage />} />
    </Routes>
  );

  return (
    <Router>
      {auth.isAuthenticated ? (
        authenticatedRoutes
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
